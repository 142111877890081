
import React, { useCallback, useEffect, useState } from "react";
// @mui
import { Stack, Typography, Link } from "@mui/material";
import ButtonPrimary from "../../../../components/ButtonPrimary";
import * as yup from "yup";
import { useFormik } from "formik";
import { Upload } from "../../../../components/upload";
import { FILE_UPLOAD, UPLOAD_KYB_DOC } from "../../../../ApiUrl";
import { toast } from "react-toastify";
import { allowedMediaTypes } from "../../../../utils/common";
import ButtonSecondary from "../../../../components/ButtonSecondary";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessDocs } from "../../../../redux/actions/kybActions";
import Iconify from "../../../../components/Iconify";

export default function UploadBusinessDocument({ handleBack, handleNext, updateCurrentStepHandler }) {
  const shareholding = useSelector(
    (state) => state.profileReducer?.user?.shareholder.ownership
  );

  const yupObject = {
    business_beneficiary_ownership: yup
      .mixed("Select a document")
      .when("shareholding", {
        is: (shareholding) => shareholding < 90,
        then: yup.mixed("Select a document").required("Document is required"),
      }),
    address_verification: yup
      .mixed("Select a document")
      .required("Document is required"),
    tax_id: yup.mixed("Select a document").required("Document is required"),
    incorporation: yup
      .mixed("Select a document")
      .required("Document is required"),
    good_standing: yup
      .mixed("Select a document")
      .required("Document is required"),
    store_front: yup
      .mixed("Select a document")
      .required("Document is required"),
    store_inside: yup
      .mixed("Select a document")
      .required("Document is required"),
  };

  const validationSchema = yup.object(yupObject);

  // shareholding
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getBusinessDocs(
        (res) => {
          console.log("cdsecwec", res);

          const docs = res.data.documents;

          docs.map((doc) => {
            if (doc.document_type) {
              formik.setFieldValue(doc.document_type, doc.document);
            }
          });
        },
        (err) => console.log("acacecwc", err)
      )
    );
  }, []);

  const uploadClickHandler = async () => {
    setLoading(true);
    const {
      business_beneficiary_ownership,
      address_verification,
      tax_id,
      incorporation,
      good_standing,
      store_front,
      store_inside,
    } = formik.values;
    const documents = [
      {
        document_type: "business_beneficiary_ownership",
        document: business_beneficiary_ownership,
      },
      { document_type: "address_verification", document: address_verification },
      { document_type: "tax_id", document: tax_id },
      { document_type: "incorporation", document: incorporation },
      { document_type: "good_standing", document: good_standing },
      { document_type: "store_front", document: store_front },
      { document_type: "store_inside", document: store_inside },
    ].filter((value) => value.document && value.document instanceof File);

    const additionalHeader = {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
    };

    const apiPromises = documents.map(async ({ document_type, document }) => {
      const formData = new FormData();
      formData.append(`${document_type}`, document);
      try {
        const response = await merchantApi.post(FILE_UPLOAD, formData, {
          headers: {
            ...merchantApi.defaults.headers,
            ...additionalHeader,
          },
        });
        console.log("aasqwxwxq", response);
        return response;
      } catch (err) {
        setLoading(false);
        toast.error(err?.data?.data?.message || "Something went wrong");
        return null;
      }
    });

    const results = await Promise.all(apiPromises);

    const allSuccessful = results.every((result) => result.status === 200);
    console.log("ascaxasx", results);

    if (allSuccessful) {
      setLoading(false);
      const resDocuments = results.map((result, index) => {
        return {
          document: result.data.data.file_id,
          document_type: documents[index].document_type,
        };
      });
      try {
        // const response = await axios.post(SENDER_DOCUMENT, fileData, config);
        if (resDocuments.length > 0) {
          const res = await merchantApi.patch(`${UPLOAD_KYB_DOC}`, {
            documents: resDocuments,
          });
        }
        updateCurrentStepHandler(4);
        toast.success("Document updated successfully");
        handleNext();
        // dispatch(
        //   initiateKyb(
        //     (res) => {
        //       toast.success("Document updated successfully and KYB initiated");
        //       handleNext();
        //       setLoading(false);
        //     },
        //     (err) => {
        //       toast.success("Document update failed, please try again");
        //       setLoading(false);
        //     }
        //   )
        // );
      } catch (error) {
        toast.error(
          error?.data?.data?.message ||
            "Document update failed, please try again"
        );
        setLoading(false);
      }
      return;
    } else {
      toast.error("Image upload failed, please try again.");
    }
  };

  const formik = useFormik({
    initialValues: {
      share_owned: "",
      address_verification: null,
      tax_id: null,
      incorporation: null,
      good_standing: null,
      business_beneficiary_ownership: null,
      store_front: null,
      store_inside: null,
    },
    onSubmit: uploadClickHandler,
    validationSchema,
  });

  console.log(formik.values, "asdasdasx2x3x");

  const handleRemoveBeneficiaryOwnership = () => {
    formik.setFieldValue("business_beneficiary_ownership", null);
  };
  const handleDropBeneficiaryOwnership = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        formik.setFieldValue("business_beneficiary_ownership", newFile);
      }
    },
    [formik]
  );

  const handleRemoveAddressVerification = () => {
    formik.setFieldValue("address_verification", null);
  };
  const handleDropAddressVerification = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        formik.setFieldValue("address_verification", newFile);
      }
    },
    [formik]
  );

  const handleRemoveTaxId = () => {
    formik.setFieldValue("tax_id", null);
  };
  const handleDropTaxId = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        formik.setFieldValue("tax_id", newFile);
      }
    },
    [formik]
  );

  const handleRemoveIncorporation = () => {
    formik.setFieldValue("incorporation", null);
  };
  const handleDropIncorporation = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        formik.setFieldValue("incorporation", newFile);
      }
    },
    [formik]
  );
  const handleRemoveGoodStanding = () => {
    formik.setFieldValue("good_standing", null);
  };
  const handleDropGoodStanding = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        formik.setFieldValue("good_standing", newFile);
      }
    },
    [formik]
  );

  const handleRemoveStoreFront = () => {
    formik.setFieldValue("store_front", null);
  };
  const handleDropStoreFront = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        formik.setFieldValue("store_front", newFile);
      }
    },
    [formik]
  );

  const handleRemoveStoreInside = () => {
    formik.setFieldValue("store_inside", null);
  };
  const handleDropStoreInside = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        formik.setFieldValue("store_inside", newFile);
      }
    },
    [formik]
  );

  return (
    <Stack sx={{ width: "100%" }}>
      <form onSubmit={formik.handleSubmit}>
        {/* {shareholding < 90 && (
          <Stack
            direction={"column"}
            sx={{
              gap: 1,
              flexGrow: 1,
              width: { md: "100%", lg: "50%" },
              mt: 4,
            }}
          >
            <Typography>Business Beneficiary Ownership document</Typography>
            <Upload
              accept={allowedMediaTypes}
              name="business_beneficiary_ownership"
              maxSize={3145728}
              onDrop={handleDropBeneficiaryOwnership}
              onDelete={handleRemoveBeneficiaryOwnership}
              file={formik.values.business_beneficiary_ownership}
              error={
                formik.touched.business_beneficiary_ownership &&
                Boolean(formik.errors.business_beneficiary_ownership)
              }
              helperText={
                formik.touched.business_beneficiary_ownership &&
                formik.errors.business_beneficiary_ownership
              }
            />
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Iconify
                icon={"eva:info-outline"}
                sx={{ height: "16px", width: "16px" }}
              />
              <Typography variant="caption">
                Click{" "}
                <Link
                  variant="caption"
                  rel="noreferrer"
                  href="https://www.google.com/"
                  target="_blank"
                >
                  here
                </Link>{" "}
                to download the sample document
              </Typography>
            </Stack>
          </Stack>
        )} */}

<Stack
            direction={"column"}
            sx={{
              gap: 1,
              flexGrow: 1,
              width: { md: "100%", lg: "50%" },
              mt: 4,
            }}
          >
            <Typography>Business Beneficiary Ownership document</Typography>
            <Upload
              accept={allowedMediaTypes}
              name="business_beneficiary_ownership"
              maxSize={3145728}
              onDrop={handleDropBeneficiaryOwnership}
              onDelete={handleRemoveBeneficiaryOwnership}
              file={formik.values.business_beneficiary_ownership}
              error={
                formik.touched.business_beneficiary_ownership &&
                Boolean(formik.errors.business_beneficiary_ownership)
              }
              helperText={
                formik.touched.business_beneficiary_ownership &&
                formik.errors.business_beneficiary_ownership
              }
            />
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Iconify
                icon={"eva:info-outline"}
                sx={{ height: "16px", width: "16px" }}
              />
              <Typography variant="caption">
                Click{" "}
                <Link
                  variant="caption"
                  rel="noreferrer"
                  href="https://www.google.com/"
                  target="_blank"
                >
                  here
                </Link>{" "}
                to download the sample document
              </Typography>
            </Stack>
          </Stack>

        <Stack
          direction={{ md: "column", lg: "row" }}
          sx={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flex: 1,
            gap: 2,
            mt: 4,
          }}
        >
          <Stack
            direction={"column"}
            sx={{ gap: 1, flexGrow: 1, width: { md: "100%", lg: "50%" } }}
          >
            <Typography>Address verification document</Typography>
            <Upload
              accept={allowedMediaTypes}
              name="address_verification"
              maxSize={3145728}
              onDrop={handleDropAddressVerification}
              onDelete={handleRemoveAddressVerification}
              file={formik.values.address_verification}
              error={
                formik.touched.address_verification &&
                Boolean(formik.errors.address_verification)
              }
              helperText={
                formik.touched.address_verification &&
                formik.errors.address_verification
              }
            />
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Iconify
                icon={"eva:info-outline"}
                sx={{ height: "16px", width: "16px" }}
              />
              <Typography variant="caption">
                Business utility bill (past 3 months)
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"column"}
            sx={{ gap: 1, flexGrow: 1, width: { md: "100%", lg: "50%" } }}
          >
            <Typography>Tax ID document</Typography>
            <Upload
              accept={allowedMediaTypes}
              name="tax_id"
              maxSize={3145728}
              onDrop={handleDropTaxId}
              onDelete={handleRemoveTaxId}
              file={formik.values.tax_id}
              error={formik.touched.tax_id && Boolean(formik.errors.tax_id)}
              helperText={formik.touched.tax_id && formik.errors.tax_id}
            />
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Iconify
                icon={"eva:info-outline"}
                sx={{ height: "16px", width: "16px" }}
              />
              <Typography variant="caption">
                Document that contains business number issued by CRA
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction={{ md: "column", lg: "row" }}
          sx={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flex: 1,
            gap: 2,
            mt: 4,
          }}
        >
          <Stack
            direction={"column"}
            sx={{ gap: 1, flexGrow: 1, width: { md: "100%", lg: "50%" } }}
          >
            <Typography>Incorporation document</Typography>
            <Upload
              accept={allowedMediaTypes}
              name="incorporation"
              maxSize={3145728}
              onDrop={handleDropIncorporation}
              onDelete={handleRemoveIncorporation}
              file={formik.values.incorporation}
              error={
                formik.touched.incorporation &&
                Boolean(formik.errors.incorporation)
              }
              helperText={
                formik.touched.incorporation && formik.errors.incorporation
              }
            />
          </Stack>
          <Stack
            direction={"column"}
            sx={{ gap: 1, flexGrow: 1, width: { md: "100%", lg: "50%" } }}
          >
            <Typography>Good standing certificate</Typography>
            <Upload
              accept={allowedMediaTypes}
              name="good_standing"
              maxSize={3145728}
              onDrop={handleDropGoodStanding}
              onDelete={handleRemoveGoodStanding}
              file={formik.values.good_standing}
              error={
                formik.touched.good_standing &&
                Boolean(formik.errors.good_standing)
              }
              helperText={
                formik.touched.good_standing && formik.errors.good_standing
              }
            />
          </Stack>
        </Stack>

        <Stack
          direction={{ md: "column", lg: "row" }}
          sx={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flex: 1,
            gap: 2,
            mt: 4,
          }}
        >
          <Stack
            direction={"column"}
            sx={{ gap: 1, flexGrow: 1, width: { md: "100%", lg: "50%" } }}
          >
            <Typography>Store Front</Typography>
            <Upload
              accept={allowedMediaTypes}
              name="store_front"
              maxSize={3145728}
              onDrop={handleDropStoreFront}
              onDelete={handleRemoveStoreFront}
              file={formik.values.store_front}
              error={
                formik.touched.store_front &&
                Boolean(formik.errors.store_front)
              }
              helperText={
                formik.touched.store_front && formik.errors.store_front
              }
            />
          </Stack>
          <Stack
            direction={"column"}
            sx={{ gap: 1, flexGrow: 1, width: { md: "100%", lg: "50%" } }}
          >
            <Typography>Store Inside</Typography>
            <Upload
              accept={allowedMediaTypes}
              name="store_inside"
              maxSize={3145728}
              onDrop={handleDropStoreInside}
              onDelete={handleRemoveStoreInside}
              file={formik.values.store_inside}
              error={
                formik.touched.store_inside &&
                Boolean(formik.errors.store_inside)
              }
              helperText={
                formik.touched.store_inside && formik.errors.store_inside
              }
            />
          </Stack>
        </Stack>


        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "2rem",
            gap: 1,
          }}
        >
          <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>

          <ButtonPrimary type={"submit"} loading={loading}>
            Upload & Continue
          </ButtonPrimary>
        </Stack>
      </form>
    </Stack>
  );
}

// ----------------------------------------------------------------------
