import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
// core styles
import './scss/volt.scss';

// vendor styles

import HomePage from './pages/HomePage';
import ScrollToTop from './components/ScrollToTop';
import App from './App';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  // <HashRouter>
  <BrowserRouter>
    <App />
    {/* <ScrollToTop />
    <HomePage /> */}
  </BrowserRouter>,
  document.getElementById('root')
);
serviceWorker.unregister();
