import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datetime/css/react-datetime.css';
import { Provider } from 'react-redux';
import axios from 'axios';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import * as API from './ApiUrl';
import HomePage from './pages/HomePage';
import ScrollToTop from './components/ScrollToTop';
import store from './redux/store';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";


const dotenv = require('dotenv');

dotenv.config();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
  overrides: {
    MuiInputLabel: {
      root: {
        '&$focused': {
          // color: '#FCBD00',
        },
        '&$error': {
          // color: 'red',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          // borderColor: '#FCBD00',
        },
        borderRadius: '8px',
      },
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: true,
    };
  }
  async componentWillMount() {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  render() {
    return (
      <Provider store={store}>
        <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>     
          <ThemeProvider theme={theme}>
            <Elements stripe={stripePromise}>
              <ScrollToTop />
              <HomePage stripe={stripePromise} />
              <ToastContainer />
            </Elements>
          </ThemeProvider>
          </LocalizationProvider>
        </div>
      </Provider>
    );
  }
}

export default App;
