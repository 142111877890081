import axios from 'axios';
import { BASE_URL } from '../../ApiUrl';

//Auth api
export const authApi = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

authApi.interceptors.request.use(
  async function (options) {
    return options;
  },
  function (error) {
    return Promise.reject(error);
  }
);

authApi.interceptors.response.use(
  async function (options) {
    return options;
  },
  function (error) {
    return Promise.reject({ error, data: error.response });
  }
);

//Authorized api
export const merchantApi = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

merchantApi.interceptors.request.use(
  async function (options) {
    options.headers['Authorization'] = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    return options;
  },
  function (error) {
    return Promise.reject(error);
  }
);

merchantApi.interceptors.response.use(
  async function (options) {
    return options;
  },
  function (error) {
    return Promise.reject({ error, data: error.response });
  }
);
