import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Button } from "@themesberg/react-bootstrap";
import * as Rsuite from "rsuite";
import moment from "moment-timezone";
import axios from "axios";
import { toast } from "react-toastify";
import { startCase, toLower } from "lodash";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Popover from "@mui/material/Popover";

import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Chip } from "@mui/material";
import greentick from "../../../assets/img/icons/greentick.svg";
import cross from "../../../assets/img/icons/cross.svg";
import warning from "../../../assets/img/icons/warning.svg";

import * as API from "../../../ApiUrl";
import { send } from "../../../utils/xrpl_functions";
import WithdrawPassphraseDialog from "../../merchantprofile/Components/WithdrawPassphraseDialog";
import { merchantApi } from "../../../redux/api/apiConfig";
import { formatCurrency } from "../../../utils/common";

const xlsx = require("xlsx");

export default function Transactiontable({
  translist,
  paginateTable,
  setpaginateTable,
  handleCallback,
  userRole,
  isSingleWallet,
  resetTransactions = null,
  fetchTranslistLoading,
}) {
  const [passPhraseModal, setPassphraseModal] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [issuerAddress, setIssuerAddress] = useState(null);
  const [allCoins, setAllCoins] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState("");
  const cellStyle = {
    color: "black",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  let token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const getAllCoins = async () => {
    const response = await axios.get(`${API.GET_COINS}`, config);
    setAllCoins(response.data.results);
  };

  const getIssuerAddress = (symbol) => {
    const issuerAddressResponse = allCoins.filter(
      (coin) => coin.symbol === symbol
    )[0].issuer_address;
    setIssuerAddress(issuerAddressResponse);
  };

  useEffect(() => {
    getAllCoins();
  }, [translist]);

  const handleChangePage = (newPage) => {
    setpaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: +event.target.value,
    });
  };

  const handleFinalSubmit = (transactionId, data) => {
    let token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .patch(`${API.TRANSACTION_APPROVE_REJECT}${transactionId}/`, data, config)
      .then((res) => {
        if (res && res.data && res.data.success) {
          toast.success(res.data?.message || "Transaction is approved");
        } else {
          toast.error("Failed to approve the transaction!");
        }
        setLoading(false);
        setPassphraseModal(false);
        if (resetTransactions) resetTransactions(Math.random());
      })
      .catch((err) => {
        toast.error("Failed to approve the transaction!");
        setLoading(false);
        setPassphraseModal(false);
        if (resetTransactions) resetTransactions(Math.random());
      });
  };

  const handlePassphraseSubmit = async (passPhrase) => {
    if (passPhrase) {
      setLoading(true);
      const config = {};
      config.status = approvalStatus;
      if (approvalStatus === "approved") {
        let blob = null;
        try {
          blob = await send(
            transaction?.sender?.xrp_address,
            transaction?.receiver?.xrp_address,
            passPhrase?.passPhrase,
            transaction?.value,
            transaction?.value,
            transaction?.coin?.symbol,
            transaction?.coin?.symbol,
            issuerAddress,
            issuerAddress,
            transaction?.coin.decimal_place
          );
          if (blob) {
            config.blob = blob;
          } else {
            toast.error("Please enter a valid passphrase");
            setLoading(false);
            return;
          }
        } catch (error) {
          toast.error("Please enter a valid passphrase");
          setLoading(false);
          return;
        }
      }
      handleFinalSubmit(transaction.id, config);
      return;
    }
    toast.error("Please enter a valid passphrase");
  };

  const handlePassphraseClose = () => {
    setPassphraseModal(false);
  };

  const handleApprovalStatus = (statusValue, transactionMap) => {
    setTransaction(transactionMap);
    setApprovalStatus(statusValue);
    getIssuerAddress(transactionMap.coin.symbol);
    if (statusValue === "approved") {
      setLoading(false);
      setPassphraseModal(true);
    } else {
      setLoading(true);
      const config = {
        status: statusValue,
      };
      handleFinalSubmit(transactionMap.id, config);
    }
  };

  const createDataRow = (transaction) => {
    return {
      id: transaction.reference_number,
      lastUpdate:
        moment().format("YY") === moment(transaction.modified_date).format("YY")
          ? moment.parseZone(transaction.modified_date).format("MMM Do")
          : moment.parseZone(transaction.modified_date).format("MMM Do YY"),

      sender: startCase(toLower(transaction.sender?.name)),
      receiver: startCase(toLower(transaction?.receiver?.name)),
      store: startCase(toLower(transaction?.store?.name || "N/A")),
      counter: startCase(toLower(transaction?.counter?.name || "N/A")),
      value: formatCurrency(
        transaction?.receive_amount,
        transaction?.receiver_coin?.decimal_place,
        transaction?.receiver_coin?.symbol,
        transaction?.receiver_coin?.symbol_prefix
      ),
      valueInCurrency: formatCurrency(
        transaction?.value_in_currency,
        transaction?.currency?.decimal_place,
        transaction?.currency?.symbol,
        transaction?.currency?.symbol_prefix
      ),
      // currency: transaction.currency.code,
      type: startCase(toLower(transaction.type)),
      status: transaction.status.value,
      action: "Action",
      refund: "Refund",
      details: transaction.blockchain_url,

      transactionData: transaction,
    };
  };

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];

  const createDataColumn = (role, singleWallet) => {
    if (singleWallet) {
      if (role === "administrator") {
        return [
          {
            field: "id",
            headerName: "ID",
            flex: 1,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "lastUpdate",
            headerName: "Last Updated",
            flex: 1,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
          },
          {
            field: "sender",
            headerName: "Sender",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            textOverflow: "ellipsis",
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "receiver",
            headerName: "Receiver",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "store",
            headerName: "Store",
            flex: 1.5,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
          },
          {
            field: "counter",
            headerName: "Counter",
            flex: 1.5,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
          },
          {
            field: "value",
            headerName: "Value",
            flex: 1.5,
            minWidth: 150,
            // maxWidth: 200,
            headerClassName: "header",
            sortable: false,
          },

          {
            field: "valueInCurrency",
            headerName: "Value in Currency",
            flex: 1.5,
            minWidth: 150,
            // maxWidth: 200,
            headerClassName: "header",
          },

          {
            field: "type",
            headerName: "Type",
            flex: 1,
            minWidth: 80,
            // maxWidth: 150,
            headerClassName: "header",
          },
          {
            field: "status",
            headerName: "Status",
            sortable: false,
            flex: 1.5,
            minWidth: 150,
            headerClassName: "header",
            renderCell: (params) => {
              return params.formattedValue === "processed" ? (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#ECFDF3",
                    color: "#027A48",
                  }}
                  avatar={
                    <img
                      src={greentick}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="tick"
                    />
                  }
                  label="Successful"
                  color="success"
                />
              ) : params.formattedValue === "pending_admin_approval" ? (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#FFF7CD",
                    color: "rgb(122, 79, 1)",
                  }}
                  avatar={
                    <img
                      src={warning}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="corss"
                    />
                  }
                  color="warning"
                  label="Pending"
                />
              ) : (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#f8dada",
                    color: "#B42318",
                  }}
                  avatar={
                    <img
                      src={cross}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="corss"
                    />
                  }
                  color="error"
                  label="Failed"
                />
              );
            },
          },
          {
            field: "action",
            headerName: "Action",
            sortable: false,
            flex: 1.5,
            minWidth: 120,
            headerClassName: "header",
            renderCell: (params) => {
              let row = params.row.transactionData;

              return row.status.value === "pending_admin_approval" ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Button
                    style={{ height: "40px" }}
                    onClick={() => {
                      handleApprovalStatus("approved", row);
                    }}
                    size="sm"
                    variant="outline-success"
                    disabled={loading}
                  >
                    Approve
                  </Button>
                  <Button
                    onClick={() => handleApprovalStatus("rejected", row)}
                    size="sm"
                    variant="outline-danger"
                    style={{ marginTop: 5, height: "40px" }}
                    disabled={loading}
                  >
                    Reject
                  </Button>
                </div>
              ) : (
                <Button
                  size="sm"
                  disabled
                  style={{ height: "40px" }}
                  variant={
                    row["approval_status"] === "Approved"
                      ? "outline-success"
                      : "outline-danger"
                  }
                >
                  {row["approval_status"]}
                </Button>
              );
            },
          },
          {
            field: "refund",
            headerName: "Refund",
            sortable: false,
            flex: 2,
            headerClassName: "header",
            minWidth: 200,
            renderCell: (params) => {
              let row = params.row.transactionData;

              return row["type"] === "receipt" ? (
                <Rsuite.Button
                  onClick={() => handleCallback(row)}
                  disabled={row.remaining_refund === 0 ? true : false}
                  appearance="default"
                  className={`w-xs-100 primary_color mt-2 mb-2 font-size-12 text-white font-700 font-fam ${
                    row.remaining_refund === 0 ? "disabled" : ""
                  }`}
                >
                  {row.remaining_refund === 0
                    ? "Amount Refunded"
                    : "Initiate Refund"}
                </Rsuite.Button>
              ) : row["type"] === "refund" ? (
                <Rsuite.Button
                  appearance="default"
                  className="w-xs-100 success_color mt-2 mb-2 text-black font-700 font-fam"
                >
                  {row["status"] !== "processed" ? (
                    <span> Refund Initiated</span>
                  ) : (
                    <span className="text-success">Refund Completed</span>
                  )}
                </Rsuite.Button>
              ) : (
                "N/A"
              );
            },
          },
          {
            field: "details",
            headerName: "Details",
            sortable: false,
            flex: 1.5,
            headerClassName: "header",
            minWidth: 160,
            renderCell: (params) => {
              return (
                <a
                  href={params.formattedValue}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Rsuite.Button
                    appearance="default"
                    className="w-xs-100 success_color mt-2 mb-2 text-black font-700 font-fam"
                  >
                    <span className="text-success">View Details</span>
                  </Rsuite.Button>
                </a>
              );
            },
          },
        ];
      } else {
        return [
          {
            field: "id",
            headerName: "ID",
            flex: 1,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "lastUpdate",
            headerName: "Last Updated",
            flex: 1,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
          },
          {
            field: "sender",
            headerName: "Sender",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            textOverflow: "ellipsis",
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "receiver",
            headerName: "Receiver",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "store",
            headerName: "Store",
            flex: 1.5,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
          },
          {
            field: "counter",
            headerName: "Counter",
            flex: 1.5,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
          },
          {
            field: "value",
            headerName: "Value",
            flex: 1.5,
            minWidth: 150,
            // maxWidth: 200,
            headerClassName: "header",
            sortable: false,
          },

          {
            field: "valueInCurrency",
            headerName: "Value in Currency",
            flex: 1.5,
            minWidth: 150,
            // maxWidth: 200,
            headerClassName: "header",
          },

          {
            field: "type",
            headerName: "Type",
            flex: 1,
            minWidth: 80,
            // maxWidth: 150,
            headerClassName: "header",
          },
          {
            field: "status",
            headerName: "Status",
            sortable: false,
            flex: 1.5,
            minWidth: 150,
            headerClassName: "header",
            renderCell: (params) => {
              return params.formattedValue === "processed" ? (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#ECFDF3",
                    color: "#027A48",
                  }}
                  avatar={
                    <img
                      src={greentick}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="tick"
                    />
                  }
                  label="Successful"
                  color="success"
                />
              ) : params.formattedValue === "pending_admin_approval" ? (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#FFF7CD",
                    color: "rgb(122, 79, 1)",
                  }}
                  avatar={
                    <img
                      src={warning}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="corss"
                    />
                  }
                  color="warning"
                  label="Pending"
                />
              ) : (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#f8dada",
                    color: "#B42318",
                  }}
                  avatar={
                    <img
                      src={cross}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="corss"
                    />
                  }
                  color="error"
                  label="Failed"
                />
              );
            },
          },
          // {
          //   field: 'action',
          //   headerName: 'Action',
          //   sortable: false,
          //   flex: 1.5,
          //   minWidth: 120,
          //   headerClassName: 'header',
          //   renderCell: (params) => {
          //     let row = params.row.transactionData;

          //     return row.status.value === 'pending_admin_approval' ? (
          //       <div style={{ display: 'flex', flexDirection: 'column' }}>
          //         <Button
          //           style={{ height: '40px' }}
          //           onClick={() => {
          //             handleApprovalStatus('approved', row);
          //           }}
          //           size="sm"
          //           variant="outline-success"
          //           disabled={loading}
          //         >
          //           Approve
          //         </Button>
          //         <Button
          //           onClick={() => handleApprovalStatus('rejected', row)}
          //           size="sm"
          //           variant="outline-danger"
          //           style={{ marginTop: 5, height: '40px' }}
          //           disabled={loading}
          //         >
          //           Reject
          //         </Button>
          //       </div>
          //     ) : (
          //       <Button
          //         size="sm"
          //         disabled
          //         style={{ height: '40px' }}
          //         variant={
          //           row['approval_status'] === 'Approved'
          //             ? 'outline-success'
          //             : 'outline-danger'
          //         }
          //       >
          //         {row['approval_status']}
          //       </Button>
          //     );
          //   },
          // },
          {
            field: "refund",
            headerName: "Refund",
            sortable: false,
            flex: 2,
            headerClassName: "header",
            minWidth: 200,
            renderCell: (params) => {
              let row = params.row.transactionData;

              return row["type"] === "receipt" ? (
                <Rsuite.Button
                  onClick={() => handleCallback(row)}
                  disabled={row.remaining_refund === 0 ? true : false}
                  appearance="default"
                  className={`w-xs-100 primary_color mt-2 mb-2 font-size-12 text-white font-700 font-fam ${
                    row.remaining_refund === 0 ? "disabled" : ""
                  }`}
                >
                  {row.remaining_refund === 0
                    ? "Amount Refunded"
                    : "Initiate Refund"}
                </Rsuite.Button>
              ) : row["type"] === "refund" ? (
                <Rsuite.Button
                  appearance="default"
                  className="w-xs-100 success_color mt-2 mb-2 text-black font-700 font-fam"
                >
                  {row["status"] !== "processed" ? (
                    <span> Refund Initiated</span>
                  ) : (
                    <span className="text-success">Refund Completed</span>
                  )}
                </Rsuite.Button>
              ) : (
                "N/A"
              );
            },
          },
          {
            field: "details",
            headerName: "Details",
            sortable: false,
            flex: 1.5,
            headerClassName: "header",
            minWidth: 160,
            renderCell: (params) => {
              return (
                <a
                  href={params.formattedValue}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Rsuite.Button
                    appearance="default"
                    className="w-xs-100 success_color mt-2 mb-2 text-black font-700 font-fam"
                  >
                    <span className="text-success">View Details</span>
                  </Rsuite.Button>
                </a>
              );
            },
          },
        ];
      }
    } else {
      if (role === "administrator") {
        return [
          {
            field: "id",
            headerName: "ID",
            flex: 1,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "lastUpdate",
            headerName: "Last Updated",
            flex: 1,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
          },
          {
            field: "sender",
            headerName: "Sender",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            textOverflow: "ellipsis",
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "receiver",
            headerName: "Receiver",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "store",
            headerName: "Store",
            flex: 1.5,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
          },
          {
            field: "value",
            headerName: "Value",
            flex: 1.5,
            minWidth: 150,
            // maxWidth: 200,
            headerClassName: "header",
            sortable: false,
          },

          {
            field: "valueInCurrency",
            headerName: "Value in Currency",
            flex: 1.5,
            minWidth: 150,
            // maxWidth: 200,
            headerClassName: "header",
          },

          {
            field: "type",
            headerName: "Type",
            flex: 1,
            minWidth: 80,
            // maxWidth: 150,
            headerClassName: "header",
          },
          {
            field: "status",
            headerName: "Status",
            sortable: false,
            flex: 1.5,
            minWidth: 150,
            headerClassName: "header",
            renderCell: (params) => {
              return params.formattedValue === "processed" ? (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#ECFDF3",
                    color: "#027A48",
                  }}
                  avatar={
                    <img
                      src={greentick}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="tick"
                    />
                  }
                  label="Successful"
                  color="success"
                />
              ) : params.formattedValue === "pending_admin_approval" ? (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#FFF7CD",
                    color: "rgb(122, 79, 1)",
                  }}
                  avatar={
                    <img
                      src={warning}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="corss"
                    />
                  }
                  color="warning"
                  label="Pending"
                />
              ) : (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#f8dada",
                    color: "#B42318",
                  }}
                  avatar={
                    <img
                      src={cross}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="corss"
                    />
                  }
                  color="error"
                  label="Failed"
                />
              );
            },
          },
          {
            field: "details",
            headerName: "Details",
            sortable: false,
            flex: 1.5,
            headerClassName: "header",
            minWidth: 160,
            renderCell: (params) => {
              return (
                <a
                  href={params.formattedValue}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Rsuite.Button
                    appearance="default"
                    className="w-xs-100 success_color mt-2 mb-2 text-black font-700 font-fam"
                  >
                    <span className="text-success">View Details</span>
                  </Rsuite.Button>
                </a>
              );
            },
          },
        ];
      } else if (role === "franchisee") {
        return [
          {
            field: "id",
            headerName: "ID",
            flex: 1,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
              return (
                <p
                  style={{
                    color: "black",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {params.formattedValue}
                </p>
              );
            },
          },
          {
            field: "lastUpdate",
            headerName: "Last Updated",
            flex: 1,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
          },
          {
            field: "sender",
            headerName: "Sender",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            textOverflow: "ellipsis",
            headerClassName: "header",
            renderCell: (params) => {
              return (
                <p
                  style={{
                    color: "black",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {params.formattedValue}
                </p>
              );
            },
          },
          {
            field: "receiver",
            headerName: "Receiver",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            headerClassName: "header",
            renderCell: (params) => {
              return (
                <p
                  style={{
                    color: "black",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {params.formattedValue}
                </p>
              );
            },
          },
          {
            field: "store",
            headerName: "Store",
            flex: 1.5,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
          },
          {
            field: "value",
            headerName: "Value",
            flex: 1.5,
            minWidth: 150,
            // maxWidth: 200,
            headerClassName: "header",
            sortable: false,
          },

          {
            field: "valueInCurrency",
            headerName: "Value in Currency",
            flex: 1.5,
            minWidth: 150,
            // maxWidth: 200,
            headerClassName: "header",
          },

          {
            field: "type",
            headerName: "Type",
            flex: 1,
            minWidth: 80,
            // maxWidth: 150,
            headerClassName: "header",
          },
          {
            field: "status",
            headerName: "Status",
            sortable: false,
            flex: 1.5,
            minWidth: 150,
            headerClassName: "header",
            renderCell: (params) => {
              return params.formattedValue === "processed" ? (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#ECFDF3",
                    color: "#027A48",
                  }}
                  avatar={
                    <img
                      src={greentick}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="tick"
                    />
                  }
                  label="Successful"
                  color="success"
                />
              ) : params.formattedValue === "pending_admin_approval" ? (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#FFF7CD",
                    color: "rgb(122, 79, 1)",
                  }}
                  avatar={
                    <img
                      src={warning}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="corss"
                    />
                  }
                  color="warning"
                  label="Pending"
                />
              ) : (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#f8dada",
                    color: "#B42318",
                  }}
                  avatar={
                    <img
                      src={cross}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="corss"
                    />
                  }
                  color="error"
                  label="Failed"
                />
              );
            },
          },
          {
            field: "action",
            headerName: "Action",
            sortable: false,
            flex: 1.5,
            minWidth: 120,
            headerClassName: "header",
            renderCell: (params) => {
              let row = params.row.transactionData;

              return row.status.value === "pending_admin_approval" ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Button
                    style={{ height: "40px" }}
                    onClick={() => {
                      handleApprovalStatus("approved", row);
                    }}
                    size="sm"
                    variant="outline-success"
                    disabled={loading}
                  >
                    Approve
                  </Button>
                  <Button
                    onClick={() => handleApprovalStatus("rejected", row)}
                    size="sm"
                    variant="outline-danger"
                    style={{ marginTop: 5, height: "40px" }}
                    disabled={loading}
                  >
                    Reject
                  </Button>
                </div>
              ) : (
                <Button
                  size="sm"
                  disabled
                  style={{ height: "40px" }}
                  variant={
                    row["approval_status"] === "Approved"
                      ? "outline-success"
                      : "outline-danger"
                  }
                >
                  {row["approval_status"]}
                </Button>
              );
            },
          },
          {
            field: "refund",
            headerName: "Refund",
            sortable: false,
            flex: 2,
            headerClassName: "header",
            minWidth: 200,
            renderCell: (params) => {
              let row = params.row.transactionData;

              return row["type"] === "receipt" ? (
                <Rsuite.Button
                  onClick={() => handleCallback(row)}
                  disabled={row.remaining_refund === 0 ? true : false}
                  appearance="default"
                  className={`w-xs-100 primary_color mt-2 mb-2 font-size-12 text-white font-700 font-fam ${
                    row.remaining_refund === 0 ? "disabled" : ""
                  }`}
                >
                  {row.remaining_refund === 0
                    ? "Amount Refunded"
                    : "Initiate Refund"}
                </Rsuite.Button>
              ) : row["type"] === "refund" ? (
                <Rsuite.Button
                  appearance="default"
                  className="w-xs-100 success_color mt-2 mb-2 text-black font-700 font-fam"
                >
                  {row["status"] !== "processed" ? (
                    <span> Refund Initiated</span>
                  ) : (
                    <span className="text-success">Refund Completed</span>
                  )}
                </Rsuite.Button>
              ) : (
                "N/A"
              );
            },
          },
          {
            field: "details",
            headerName: "Details",
            sortable: false,
            flex: 1.5,
            headerClassName: "header",
            minWidth: 160,
            renderCell: (params) => {
              return (
                <a
                  href={params.formattedValue}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Rsuite.Button
                    appearance="default"
                    className="w-xs-100 success_color mt-2 mb-2 text-black font-700 font-fam"
                  >
                    <span className="text-success">View Details</span>
                  </Rsuite.Button>
                </a>
              );
            },
          },
        ];
      } else {
        return [
          {
            field: "id",
            headerName: "ID",
            flex: 1,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
              return (
                <p
                  style={{
                    color: "black",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {params.formattedValue}
                </p>
              );
            },
          },
          {
            field: "lastUpdate",
            headerName: "Last Updated",
            flex: 1,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
          },
          {
            field: "sender",
            headerName: "Sender",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            textOverflow: "ellipsis",
            headerClassName: "header",
            renderCell: (params) => {
              return (
                <p
                  style={{
                    color: "black",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {params.formattedValue}
                </p>
              );
            },
          },
          {
            field: "receiver",
            headerName: "Receiver",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            headerClassName: "header",
            renderCell: (params) => {
              return (
                <p
                  style={{
                    color: "black",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {params.formattedValue}
                </p>
              );
            },
          },
          {
            field: "store",
            headerName: "Store",
            flex: 1.5,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
          },
          {
            field: "value",
            headerName: "Value",
            flex: 1.5,
            minWidth: 150,
            // maxWidth: 200,
            headerClassName: "header",
            sortable: false,
          },

          {
            field: "valueInCurrency",
            headerName: "Value in Currency",
            flex: 1.5,
            minWidth: 150,
            // maxWidth: 200,
            headerClassName: "header",
          },

          {
            field: "type",
            headerName: "Type",
            flex: 1,
            minWidth: 80,
            // maxWidth: 150,
            headerClassName: "header",
          },
          {
            field: "status",
            headerName: "Status",
            sortable: false,
            flex: 1.5,
            minWidth: 150,
            headerClassName: "header",
            renderCell: (params) => {
              return params.formattedValue === "processed" ? (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#ECFDF3",
                    color: "#027A48",
                  }}
                  avatar={
                    <img
                      src={greentick}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="tick"
                    />
                  }
                  label="Successful"
                  color="success"
                />
              ) : params.formattedValue === "pending_admin_approval" ? (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#FFF7CD",
                    color: "rgb(122, 79, 1)",
                  }}
                  avatar={
                    <img
                      src={warning}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="corss"
                    />
                  }
                  color="warning"
                  label="Pending"
                />
              ) : (
                <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#f8dada",
                    color: "#B42318",
                  }}
                  avatar={
                    <img
                      src={cross}
                      style={{ width: "13px", marginLeft: "10px" }}
                      alt="corss"
                    />
                  }
                  color="error"
                  label="Failed"
                />
              );
            },
          },
          // {
          //   field: 'action',
          //   headerName: 'Action',
          //   sortable: false,
          //   flex: 1.5,
          //   minWidth: 120,
          //   headerClassName: 'header',
          //   renderCell: (params) => {
          //     let row = params.row.transactionData;

          //     return row.status.value === 'pending_admin_approval' ? (
          //       <div style={{ display: 'flex', flexDirection: 'column' }}>
          //         <Button
          //           style={{ height: '40px' }}
          //           onClick={() => {
          //             handleApprovalStatus('approved', row);
          //           }}
          //           size="sm"
          //           variant="outline-success"
          //           disabled={loading}
          //         >
          //           Approve
          //         </Button>
          //         <Button
          //           onClick={() => handleApprovalStatus('rejected', row)}
          //           size="sm"
          //           variant="outline-danger"
          //           style={{ marginTop: 5, height: '40px' }}
          //           disabled={loading}
          //         >
          //           Reject
          //         </Button>
          //       </div>
          //     ) : (
          //       <Button
          //         size="sm"
          //         disabled
          //         style={{ height: '40px' }}
          //         variant={
          //           row['approval_status'] === 'Approved'
          //             ? 'outline-success'
          //             : 'outline-danger'
          //         }
          //       >
          //         {row['approval_status']}
          //       </Button>
          //     );
          //   },
          // },
          {
            field: "refund",
            headerName: "Refund",
            sortable: false,
            flex: 2,
            headerClassName: "header",
            minWidth: 200,
            renderCell: (params) => {
              let row = params.row.transactionData;

              return row["type"] === "receipt" ? (
                <Rsuite.Button
                  onClick={() => handleCallback(row)}
                  disabled={row.remaining_refund === 0 ? true : false}
                  appearance="default"
                  className={`w-xs-100 primary_color mt-2 mb-2 font-size-12 text-white font-700 font-fam ${
                    row.remaining_refund === 0 ? "disabled" : ""
                  }`}
                >
                  {row.remaining_refund === 0
                    ? "Amount Refunded"
                    : "Initiate Refund"}
                </Rsuite.Button>
              ) : row["type"] === "refund" ? (
                <Rsuite.Button
                  appearance="default"
                  className="w-xs-100 success_color mt-2 mb-2 text-black font-700 font-fam"
                >
                  {row["status"] !== "processed" ? (
                    <span> Refund Initiated</span>
                  ) : (
                    <span className="text-success">Refund Completed</span>
                  )}
                </Rsuite.Button>
              ) : (
                "N/A"
              );
            },
          },
          {
            field: "details",
            headerName: "Details",
            sortable: false,
            flex: 1.5,
            headerClassName: "header",
            minWidth: 160,
            renderCell: (params) => {
              return (
                <a
                  href={params.formattedValue}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Rsuite.Button
                    appearance="default"
                    className="w-xs-100 success_color mt-2 mb-2 text-black font-700 font-fam"
                  >
                    <span className="text-success">View Details</span>
                  </Rsuite.Button>
                </a>
              );
            },
          },
        ];
      }
    }
  };

  const columns = createDataColumn(userRole, isSingleWallet);

  const handlePopoverOpen = (event) => {
    const field = event.currentTarget.dataset.field;
    const id = event.currentTarget.parentElement.dataset.id;
    const row = rows.find((r) => r.id == id);
    setValue(row[field]);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <Paper sx={{ width: "100%" }}>
        <Box
          sx={{
            height: 825,
            width: "100%",
            "& .MuiDataGrid-virtualScrollerRenderZone": {
              "& .MuiDataGrid-row": {
                // backgroundColor: 'rgba(235, 235, 235, .7)',
                fontWeight: 500,
                color: "#667085",
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
              },
            },
            "& .header": {
              backgroundColor: "#F4F4F4",
              "& div": {
                "& div": {
                  "& div": {
                    "& div": {
                      fontSize: "14px",
                      fontWeight: 600,
                      fontFamily: "Inter, sans-serif",
                    },
                  },
                },
              },
            },
          }}
        >
          <DataGrid
            sx={{
              borderRadius: "8px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
              width: "100%",
            }}
            // componentsProps={{
            //   cell: {
            //     onMouseEnter: handlePopoverOpen,
            //     onMouseLeave: handlePopoverClose,
            //   },
            // }}
            components={{
              LoadingOverlay: LinearProgress,
            }}
            rows={rows}
            columns={columns}
            rowHeight={102}
            disableColumnFilter={true}
            disableColumnMenu={true}
            pagination
            paginationMode="server"
            rowsPerPageOptions={[10, 20, 50]}
            page={paginateTable.currentPage}
            rowCount={translist.count}
            pageSize={paginateTable.pageSize}
            onPageChange={(newPage) => handleChangePage(newPage)}
            onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
            loading={fetchTranslistLoading}
          />
          {/* <Popover
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1, borderRadius: '10px' }}>{value}</Typography>
          </Popover> */}
        </Box>
      </Paper>
      {passPhraseModal && (
        <WithdrawPassphraseDialog
          passPhraseModal={passPhraseModal}
          onClose={handlePassphraseClose}
          title="Enter Passphrase"
          handlePassphraseSubmit={handlePassphraseSubmit}
          loading={loading}
        />
      )}
    </>
  );
}
