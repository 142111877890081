import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import ButtonPrimary from "../ButtonPrimary";
import ButtonSecondary from "../ButtonSecondary";
import * as yup from "yup";
import { merchantApi } from "../../redux/api/apiConfig";
import { GET_FUND_ADDRESS, DEPOSIT } from "../../ApiUrl";
import { useFormik } from "formik";
import { formatCurrency, textFieldLabelProps } from "../../utils/common";
import InteracDetailsCard from "../cards/InteracDetailsCard";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import QrDetailsCard from "../cards/QrDetailsCard";

const SelectDepositMethod = ({
  handleCloseModal,
  isOpen,
  onClose,
  selectedWallet,
}) => {
  console.log("hgjgjhgjh", selectedWallet);
  const [step, setStep] = useState(1);
  const [selectedDepositType, setSelectedDepositType] = useState(null);
  const [selectedBlockchainNetwork, setSelectedBlockchainNetwork] =
    useState(null);
    const [fetchingAddress, setFetchingAddress] = useState(false)
  const [addressResponse, setAddressResponse] = useState(null);
  const [initialLoading, setInitialLoading] = useState(selectedWallet?.coin?.deposit_types?.length === 1 && selectedWallet?.coin?.supported_deposit_blockchains?.length === 1);

  const deposit_types = selectedWallet?.coin?.deposit_types || [];

  const supported_deposit_blockchains =
    selectedWallet?.coin?.supported_deposit_blockchains || [];

  const isSingleDepositType = deposit_types?.length === 1;
  const isSingleSupportedBlockChain =
    supported_deposit_blockchains?.length === 1;

  useEffect(() => {
    if (selectedWallet?.coin?.deposit_types?.length === 1) {
      handleSelectDepositType(selectedWallet?.coin?.deposit_types[0]);
    }
  }, [selectedWallet]);

  const getDepositAddress = async (wallet, network) => {
    try {
        setFetchingAddress(true)
      const response = await merchantApi.get(`${GET_FUND_ADDRESS}`, {
        params: {
          wallet__coin: wallet?.coin?.id,
          handler: wallet?.coin?.handler,
          blockchain: network?.id,
        },
      });
      console.log("3343434", response);
      setAddressResponse(response?.data?.results[0]);
      setStep("showAddress");
      setInitialLoading(false)
      setFetchingAddress(false)
    } catch (error) {
      toast.error(
        error.response.data.error ||
          "Could not fetch deposit address, try again later"
      );
      setFetchingAddress(false)
    }
  };

  const handleSelectDepositType = (item) => {
    if (item?.key === "interac") {
      setStep("interac");
    } else {
      if (supported_deposit_blockchains?.length === 1) {
        getDepositAddress(selectedWallet, supported_deposit_blockchains[0]);
      } else if (supported_deposit_blockchains?.length === 0) {
        toast.error("No supported blockchain found");
      } else {
        setStep(2);
      }
    }
    setSelectedDepositType(item);
  };

  const handleSelectBlockchainNetwork = (item) => {
    setSelectedBlockchainNetwork(item);
    getDepositAddress(selectedWallet, item);
  };
  const initialData = {
    amount: "",
    description: "",
  };

  const validationSchema = yup.object({
    amount: yup.number("Enter your amount").required("Amount is required"),

    description: yup
      .string("Enter description")
      .min(5, "Too Short")
      .max(300, "Too Long "),
  });

  const onSubmit = async (values) => {
    console.log("hehe", values);
    console.log("formik values", formik.values);
    const requestData = {
      Amount: formik?.values?.amount,
      Description: formik?.values?.description,
    };
    try {
      const response = await merchantApi.post(
        `${DEPOSIT}interac/`,
        requestData
      );
      console.log(response, "sdsfsf");
    } catch (error) {
      console.log("errrrrr", error);
      toast.error(
        error.data.data.message || "Could not create deposit, try again later"
      );
    }
  };

  const formik = useFormik({
    initialValues: initialData,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
    isInitialValid: false,
    validateOnMount: true,
  });

  let content = "";
  let title = "";
  switch (step) {
    case 1: {
      title = "Select Deposit Method";
      content = (
        <>
          {deposit_types.map((item) => (
            <Stack
              className="method-card"
              direction={"row"}
              gap={"1rem"}
              onClick={() => handleSelectDepositType(item)}
              key={item?.id}
            >
              <Stack>
                <img
                  src={item?.logo}
                  alt="deposit type"
                  style={{
                    width: "80px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle1" className="title">
                  {item?.name}
                </Typography>
                <Typography variant="caption" className="desc">
                  {item?.description}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </>
      );

      break;
    }

    case 2: {
      title = "Select Blockchain Network";
      content = (
        <>
          {supported_deposit_blockchains.map((item) => (
            <Stack
              className="method-card"
              direction={"row"}
              gap={"1rem"}
              onClick={() => handleSelectBlockchainNetwork(item)}
              key={item?.id}
            >
              <Stack>
                <img
                  src={item?.logo}
                  alt="deposit type"
                  style={{
                    width: "80px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle1" className="title">
                  {item?.name}
                </Typography>
                <Typography variant="caption" className="desc">
                  {item?.description}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </>
      );

      break;
    }
    case 3: {
      title = `Deposit ${selectedWallet?.coin?.name}`;
      break;
    }

    case "interac": {
      title = (
        <Stack direction={"column"}>
          Add Funds
          <Typography variant="caption">via Interac e-Transfer®</Typography>
        </Stack>
      );
      content = (
        <>
          <Stack spacing={1} direction={"column"} alignItems={"center"}>
            <TextField
              className="w-100 mb-3"
              required
              variant="outlined"
              name="amount"
              id="amount"
              InputLabelProps={textFieldLabelProps}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {selectedWallet?.coin?.symbol}
                  </InputAdornment>
                ),
              }}
              label="Send Request For"
              type="text"
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />
            <InteracDetailsCard
              balance={formatCurrency(
                selectedWallet?.balance,
                selectedWallet?.coin?.decimal_place,
                selectedWallet?.coin?.symbol,
                selectedWallet?.coin?.symbolPrefix
              )}
            />
            <TextField
              className="w-100 mb-3"
              required
              multiline
              rows={4}
              variant="outlined"
              name="description"
              id="description"
              InputLabelProps={textFieldLabelProps}
              label="Description"
              type="text"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
            <Stack direction={"row"} alignItems={"center"}>
              <IconButton>
                <WarningAmberIcon color="error" fontSize="medium" />
              </IconButton>
              <Typography
                variant="caption"
                sx={{ color: "#d32f2f", lineHeight: "110%" }}
              >
                INTERAC e-Transfer requests must be sent from a bank account in
                the same name as your STB account. Funds sent from a bank
                account under any other name will be held pending ID
                verification.
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              style={{ marginTop: "1rem" }}
            >
              <IconButton>
                <AccessTimeIcon color="action" fontSize="medium" />
              </IconButton>
              <Typography variant="caption" style={{ lineHeight: "110%" }}>
                If funds don't go through after an hour of accepting the
                transfer, please call your bank for further inquiries before
                contacting support.
              </Typography>
            </Stack>
          </Stack>
        </>
      );
      break;
    }
    case "showAddress": {
      title = (
        <Stack direction={"column"}>
          {`Deposit ${addressResponse?.wallet?.coin?.name}`}
          <Typography variant="caption">
            via {addressResponse?.blockchain?.name} network
          </Typography>
        </Stack>
      );
      content = (
        <>
          <Stack spacing={1} direction={"column"} alignItems={"center"}>
            <Stack>
              <QRCode
                size={180}
                value={addressResponse?.address}
                style={{
                  padding: "0.5rem",
                  backgroundColor: "#F0F0F0",
                  borderRadius: "8px",
                  marginBottom: "1rem",
                }}
              />
            </Stack>
            <QrDetailsCard address={addressResponse} />
          </Stack>
        </>
      );
      break;
    }
    default: {
      content = "";
    }
  }

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={"xs"}
      onClose={handleCloseModal}
      scroll={"paper"}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "" }}>{title}</DialogTitle>
      {/* <Typography variant="subtitle1"></Typography> */}
      <DialogContent sx={{ paddingTop: "5px !important" }}>
        <Stack spacing={2} direction={"column"} alignItems={"center"}>
          {initialLoading ? 'Loading...' : content}
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          paddingX: "24px",
          paddingBottom: "15px",
        }}
      >
        <ButtonSecondary
        loading={fetchingAddress}
          onClick={
            step !== 1
              ? () => {
                  if (step === "interac") {
                    if (isSingleDepositType) {
                      handleCloseModal();
                    } else {
                      setStep(1);
                    }
                  } else if (step === "showAddress") {
                    if (isSingleSupportedBlockChain) {
                      if (isSingleDepositType) {
                        handleCloseModal();
                      } else {
                        setStep(1);
                      }
                    } else {
                      setStep(2);
                    }
                  } else if(step === 2){
                    if(isSingleDepositType){
                      handleCloseModal();
                    }else{
                      setStep(1);
                    }
                  } 
                  else{
                    setStep((cur) => cur - 1);
                  }
                }
              : handleCloseModal
          }
        >
          {step === 1 ? "Close" : "Back"}
        </ButtonSecondary>
        {step === "interac" || step === "showAddress" ? (
          <ButtonPrimary
            onClick={() => {
              if (step === "interac") {
                onSubmit();
              } else {
                handleCloseModal();
              }
            }}
            type={"submit"}
          >
            {step === "interac" ? "Send Fund Request" : "Done"}
          </ButtonPrimary>
        ) : (
          ""
        )}
      </DialogActions>
    </Dialog>
  );
};

function mapStateToProps({ authReducer }) {
  const { userPayString } = authReducer;
  return {
    userPayString,
  };
}

export default connect(mapStateToProps, null)(SelectDepositMethod);
