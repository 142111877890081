import React from 'react';
import { Button } from 'rsuite';

const ButtonSecondary = ({ children, style = {}, ...other }) => {
  return (
    <Button appearance='subtle' className='btn white-btn' style={style} {...other}>
      {children}
    </Button>
  );
};

export default ButtonSecondary;