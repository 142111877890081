import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'rsuite';
import { FormCheck } from '@themesberg/react-bootstrap';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { toast } from 'react-toastify';

import { merchantApi } from '../../redux/api/apiConfig';
import * as API from '../../ApiUrl';
import copy_white from '../../assets/img/icons/copy_white.svg';
import { setSingleWallet } from '../../redux/actions/auth';
import { getBusinessDetails } from '../../redux/actions/businessApi';

const MerchantWalletKey = (props) => {
  console.log('props', props);
  const { open, handleClose, isMerchant, selectedWalletType,setSingleWallet, getBusinessDetails } = props;

  const [isLoading, setisLoading] = useState(false);
  const [passPhraseKey, setPassPhraseKey] = useState(null);
  const secretKeyRef = useRef(null);
  const [isPassphraseAvailable, setisPassphraseAvailable] = useState(false);
  const [isAgreed, setisAgreed] = useState(false);
  const [isUpdateLoading, setisUpdateLoading] = useState(false);
  const data = JSON.parse(localStorage.getItem('accountDetail'));
  console.log(data);
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setisPassphraseAvailable(true);
      setPassPhraseKey(data.mnemonic);
    }
  }, []);

  const onContinueClicked = async () => {
    setisUpdateLoading(true)
    await merchantApi
      .patch(API.CONFIG, {
        store_wise_wallet: selectedWalletType === 'franchisee',
      })
      .then((response) => {
        if (selectedWalletType !== 'franchisee') {
          merchantApi
          .post(API.GET_WALLET, { address: data.address })
          .then((res) => {
            toast.success('Wallets created successfully')
            setisUpdateLoading(false);
            setSingleWallet();
            getBusinessDetails();
          })
          .catch((err) => {
            toast.error('Wallet creation failed.')
            setisUpdateLoading(false)
          }); 
        } else {
          handleClose()
          setisUpdateLoading(false)
        }
      })
      .catch((error) => {
        toast.error('Wallet creation failed.')
        setisUpdateLoading(false)
      });
    handleClose()
  }

  // const getMerchantDetail = () => {
  //   setisLoading(true);
  //   merchantApi
  //     .get(API.ADD_MERCHANT_API)
  //     .then((response) => {
  //       setMerchantDetail(response.data.data);
  //       if (
  //         response.data.data.status != "trust_line_set" &&
  //         response.data.data.is_passphrase_available
  //       ) {
  //         getScretKey();
  //       } else {
  //         setisPassphraseAvailable(false);
  //         setisLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       setisLoading(false);
  //     });
  // };

  // const getScretKey = () => {
  //   setisLoading(true);
  //   merchantApi
  //     .get(
  //       API.GET_PASS_PHRASE,
  //       storeData
  //         ? {
  //             params: {
  //               store_id: storeData.id,
  //             },
  //           }
  //         : null
  //     )
  //     .then((response) => {
  //       const { data } = response;
  //       setPassPhraseKey(data.data);
  //       setisPassphraseAvailable(true);
  //       setisLoading(false);
  //     })
  //     .catch((error) => {
  //       setisLoading(false);
  //     });
  // };

  // const deleteSecretKey = () => {
  //   setisUpdateLoading(true);
  //   merchantApi
  //     .delete(
  //       API.GET_PASS_PHRASE,
  //       storeData
  //         ? {
  //             params: {
  //               store_id: storeData.id,
  //             },
  //           }
  //         : null
  //     )
  //     .then((response) => {
  //       const { data } = response;
  //       setisUpdateLoading(false);
  //       handleClose();
  //     })
  //     .catch((error) => {
  //       setisUpdateLoading(false);
  //     });
  // };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(passPhraseKey);
    toast.success('PassPhrase copied successfully!');
  };

  const handleChangeAcceptBox = (event) => {
    setisAgreed(event.target.checked);
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={true}
      // onClose={handleClose}
      // onBackdropClick="false"
      className="wallet-configure"
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          width: '560px',
          minHeight: '456px',
          maxHeight: '80vh',
        },
      }}
    >
      {isLoading ? (
        <DialogContent className="mt-3 merchant-wallet">
          <p>Loading...</p>
        </DialogContent>
      ) : (
        <>
          <DialogTitle className="text-center ">
            <p className="dialog-title">
              {isPassphraseAvailable ? '  Welcome to Spend The Bits!' : 'Alert'}
            </p>
          </DialogTitle>
          <DialogContent className="mt-3 merchant-wallet">
            {isPassphraseAvailable ? (
              <div>
                <p className="instructions">
                  <div className="instruction flex-row">
                    <span className="circle">1</span>
                    <span>
                      Please note down your secret key in a safe place. This key
                      can be used to access/transfer all your funds to anybody.
                    </span>
                  </div>
                  <br />
                  <br />
                  <div className="instruction flex-row">
                    <span className="circle">2</span>
                    <span>
                      Please keep this hand written in a safe place and DO NOT
                      share this with anybody you do not trust with your funds.
                    </span>
                  </div>
                  <br /> <br />
                  <div className="instruction flex-row">
                    <span className="circle">3</span>
                    <span>
                      Please note that no one, including Spend The Bits
                      officials can help to recover your funds if you lose this
                      key.
                    </span>
                  </div>
                </p>
                <div className="merchant-wallet-key">
                  <p ref={secretKeyRef} className="secretkey">
                    {passPhraseKey}
                  </p>
                  <Button
                    className="primary_color text-white copy-btn"
                    onClick={copyToClipboard}
                  >
                    <img src={copy_white} alt="copy" />
                    Copy
                  </Button>
                </div>
                <FormCheck
                  type="checkbox"
                  id="acceptTerms"
                  className=" d-flex mt-4 mb-2 alignn-items-center"
                >
                  <FormCheck.Input
                    className="me-2"
                    id="acceptTerms"
                    name="acceptTerms"
                    onChange={handleChangeAcceptBox}
                  />
                  <FormCheck.Label>
                    <p>I understand this and I have safely noted my key</p>
                  </FormCheck.Label>
                </FormCheck>
              </div>
            ) : (
              <div className="merchant-wallet-key">
                <p>Passphrase not generated yet, please retry again</p>
              </div>
            )}
          </DialogContent>
          <hr className="hr" />
          <DialogActions className="footer-content">
            <Button
              className={`action-button primary_color text-white w-100 ${
                isPassphraseAvailable
                  ? isUpdateLoading || !isAgreed
                    ? 'disabled'
                    : null
                  : null
              }`}
              disabled={
                isPassphraseAvailable ? isUpdateLoading || !isAgreed : false
              }
              loading={isUpdateLoading}
              onClick={onContinueClicked}
            >
              {!isUpdateLoading
                ? isPassphraseAvailable
                  ? 'Continue'
                  : 'Retry'
                : 'loading...'}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSingleWallet: () => dispatch(setSingleWallet()),
    getBusinessDetails: () => dispatch(getBusinessDetails())
  };
};

export default connect(null, mapDispatchToProps)(MerchantWalletKey);

// export default MerchantWalletKey;
