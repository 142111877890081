import React from "react";
import { styled } from "@material-ui/core/styles";
import { Typography, Stack, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../utils/common";

const MyPaper = styled(Paper)({
  borderRadius: 8,
  backgroundColor: "#F0F0F0",
  padding: "1rem",
});

const InteracDetailsCard = ({ balance,
  stbFee,
  handlerFee,
  amount,
  wallet, sx }) => {
  const userData = useSelector((store) => store.profileReducer.user);

  const styledInfo = (data) => {
    return (
      <Typography
        variant="subtitle2"
        sx={{
          wordBreak: { sm: "break-all", md: "initial" },
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {data}
      </Typography>
    );
  };

  const styledTitle = (data) => {
    return (
      <Typography sx={{ color: "#4a4a4a" }} variant="caption">
        {data}
      </Typography>
    );
  };
  return (
    <MyPaper
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "1rem",
        marginBottom: "1rem !important",
        ...sx,
      }}
      elevation={0}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack>{styledTitle("Available Balance")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo(balance)}</Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack>{styledTitle("Name")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>
          {styledInfo(`${userData?.first_name} ${userData?.last_name}`)}
        </Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack>{styledTitle("Email Address")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo(userData?.email)}</Stack>
      </Stack>{" "}
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack>{styledTitle("Transfer Time")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo("2 hours")}</Stack>
      </Stack>
      {/* <Stack direction={"row"} justifyContent={"space-between"} spacing={4}>
        <Stack>{styledTitle("Amount")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo(formatCurrency(amount, wallet?.coin?.decimal_place, wallet?.coin?.symbol, wallet?.coin?.symbol_prefix))}</Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"} spacing={4}>
        <Stack>{styledTitle("STB Fee")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo(formatCurrency(stbFee, wallet?.coin?.decimal_place, wallet?.coin?.symbol, wallet?.coin?.symbol_prefix) )}</Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"} spacing={4}>
        <Stack>{styledTitle("Handler Fee")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo(formatCurrency(handlerFee, wallet?.coin?.decimal_place, wallet?.coin?.symbol, wallet?.coin?.symbol_prefix) )}</Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"} spacing={4}>
        <Stack>{styledTitle("Total")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>
          {styledInfo(formatCurrency( Number(amount) + Number(stbFee) + Number(handlerFee), wallet?.coin?.decimal_place, wallet?.coin?.symbol, wallet?.coin?.symbol_prefix) )}
        </Stack>
      </Stack> */}
    </MyPaper>
  );
};

export default InteracDetailsCard;
