import React from 'react'
import { Col, Row, Container, Form, InputGroup } from '@themesberg/react-bootstrap';
import {Button} from 'rsuite'
import { FiEdit } from "react-icons/fi";
// import { DatePicker } from 'rsuite';
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
// import Items from '../components/Tables';
import { InvoiceCreate } from "../components/Tables";



const AddInvoice = () => {
    const [expirydate, setExpirydate] = React.useState("");
    return (
        <div>
        <Container className='font-fam'>
            <h5 className="mb-4" > <span className="font-fam">Invoice</span> 
            <span className="light_color font-100 ms-3">#32562</span>  </h5> 

            <Form noValidate  >
            
                <Col xl={12} sm={12} xs={12}>
                <div className="form-floating mb-2 ">
                <textarea  className="form-control radi light_bg font-fam h-100p " value="Active" placeholder="Active" ></textarea>
                <label for="floatingUser">Enter A Breif Description or Summary</label>
                 </div>
                </Col>

                <Row className=" mt-4">
                <Col xl={4} sm={4} xs={12}>
                <div className="form-floating mb-2 ">
                <input type="email"  className="form-control radi light_bg font-fam " value="alexpro@email.com" placeholder="alexpro@email.com" />
                <label for="floatingUser">Billing to</label>
                 </div>
                </Col>


                <Col xl={8} sm={8} xs={12}>
                <div className="form-floating mb-2 ">
                <input type="text"  className="form-control radi light_bg font-fam " value="4132 Walt Nuzum Farm Road, Rochester, New York" placeholder="Active" />
                <label for="floatingUser">Billing Address</label>
                 </div>
                </Col>
                </Row>

                <Col xl={12} sm={12} xs={12} className="mt-2">
                    <Button apperance="subtle" className="color-blue font-fam"><FiEdit size="1.1em" /> Edit Customer</Button>
                </Col>

                <Row className=" mt-4 align-items-center">
                <Col xl={4} sm={4} xs={12}>
                
                <label for="issuedate" className='font-fam'>Issue Date</label>

                <Form.Group className="mb-3 font-fam">
                <Datetime
                timeFormat={false}
                closeOnSelect={false}
                onChange={setExpirydate}
                renderInput={(props, openCalendar) => (
                <InputGroup>
                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                <Form.Control
                required
                type="text"
                value={new Date() ? moment(new Date()).format("DD/MM/YYYY") : ""}
                placeholder="Select Date"
                onFocus={openCalendar}
                onChange={() => { }} />
                </InputGroup>
                )} />
                </Form.Group>
               
                <label for="expirydate" className='font-fam'>Expiry Date</label>

                <Form.Group className="mb-3 font-fam">
                <Datetime
                timeFormat={false}
                closeOnSelect={false}
                onChange={setExpirydate}
                renderInput={(props, openCalendar) => (
                <InputGroup>
                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                <Form.Control
                required
                type="text"
                value={expirydate ? moment(expirydate).format("DD/MM/YYYY") : ""}
                placeholder="Select Date"
                onFocus={openCalendar}
                onChange={() => { }} />
                </InputGroup>
                )} />
                </Form.Group>

                </Col>

                <Col xl={8} sm={8} xs={12}>
                <div className="form-floating mb-2 ">
                <textarea  className="form-control radi light_bg font-fam h-100p " placeholder="Active"  ></textarea>
                <label for="floatingUser">Shipping Address</label>
                 </div>
                 <div className="form-floating mb-2 ">
                 <Form.Check label="Same as Billing Address" className='font-fam' id="checkbox1" htmlFor="checkbox1" />

                 </div>
                </Col>
                </Row>

                <Col xl={12} sm={12} xs={12}>
                   
                    <InvoiceCreate />
                </Col>



            </Form>
            </Container>
        </div>
    )
}

export default AddInvoice
