import React, { useState } from "react";
import Paper from "@mui/material/Paper";

import * as Rsuite from "rsuite";
import moment from "moment-timezone";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Popover from "@mui/material/Popover";

import { startCase, toLower } from "lodash";

import { formatCurrency } from "../../../utils/common";

import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Chip } from "@mui/material";
import greentick from "../../../assets/img/icons/greentick.svg";
import cross from "../../../assets/img/icons/cross.svg";
import warning from "../../../assets/img/icons/warning.svg";

const colors = {
  Successful: "#027A48",
  "Pending Admin Approval": "#003bbbdc",
  "Transaction Time Expired": "rgb(124,15,48)",
  Rejected: "rgb(124,15,48)",
  "application submitted": "#FCBD00",
};

const bgColors = {
  Successful: "#ECFDF3",
  "Pending Admin Approval": "#afe2ffdc",
  "Transaction Time Expired": "#fca8c1",
  Rejected: "rgb(124,15,48)",
  "application submitted": "#effca8",
};

const cellStyle = {
  color: "black",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export default function Transactiontable({
  translist,
  paginateTable,
  setpaginateTable,
  loading,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState("");

  const handleChangePage = (newPage) => {
    setpaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (newSize) => {
    setRowsPerPage(newSize);
    setPage(0);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  };

  // Datagrid Components

  // console.log(translist);

  const createDataRow = (transaction) => ({
    id: transaction?.reference_number,
    lastUpdate:
      moment().format("YY") === moment(transaction?.modified_date).format("YY")
        ? moment(transaction?.modified_date).format("MMM Do")
        : moment(transaction?.modified_date).format("MMM Do YY"),

    sender: startCase(toLower(transaction?.sender?.name)),
    receiver: startCase(toLower(transaction?.receiver?.name)),
    value: formatCurrency(
      transaction?.receive_amount,
      transaction?.receiver_coin?.decimal_place,
      transaction?.receiver_coin?.symbol,
      transaction?.receiver_coin?.symbol_prefix
    ),
    store: startCase(transaction?.store?.name || "N/A"),
    counter: transaction?.counter?.name,
    valueInCurrency: formatCurrency(
      transaction?.value_in_currency,
      transaction?.currency?.decimal_place,
      transaction?.currency?.symbol,
      transaction?.currency?.symbol_prefix
    ),
    // currency: transaction.currency.code,
    type: startCase(toLower(transaction.type)),
    status:
      transaction?.status?.value === "processed"
        ? "Processed"
        : transaction?.status?.value === "pending_admin_approval"
        ? "Pending"
        : "Failed",
    details: transaction?.blockchain_url,
  });

  const rows =
    translist?.results?.length > 0
      ? translist?.results?.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
    {
      field: "lastUpdate",
      headerName: "Last Updated",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
    },
    {
      field: "sender",
      headerName: "Sender",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
    {
      field: "receiver",
      headerName: "Receiver",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
    },

    {
      field: "valueInCurrency",
      headerName: "Value in Currency",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
    },
    {
      field: "store",
      headerName: "Store",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "counter",
      headerName: "Counter",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 120,
      // maxWidth: 150,
      headerClassName: "header",
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1.5,
      minWidth: 150,
      // maxWidth: 250,
      headerClassName: "header",
      renderCell: (params) => {
        return params.formattedValue === "Processed" ? (
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#ECFDF3",
              color: "#027A48",
            }}
            avatar={
              <img
                src={greentick}
                style={{ width: "13px", marginLeft: "10px" }}
                alt="tick"
              />
            }
            label="Successful"
            color="success"
          />
        ) : params.formattedValue === "Pending" ? (
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#FFF7CD",
              color: "rgb(122, 79, 1)",
            }}
            avatar={
              <img
                src={warning}
                style={{ width: "13px", marginLeft: "10px" }}
                alt="corss"
              />
            }
            color="warning"
            label="Pending"
          />
        ) : (
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#f8dada",
              color: "#B42318",
            }}
            avatar={
              <img
                src={cross}
                style={{ width: "13px", marginLeft: "10px" }}
                alt="corss"
              />
            }
            color="error"
            label="Failed"
          />
        );
      },
    },
    {
      field: "details",
      headerName: "Details",
      sortable: false,
      flex: 1.5,
      headerClassName: "header",
      minWidth: 160,
      renderCell: (params) => {
        return (
          <a
            href={params.formattedValue}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Rsuite.Button
              appearance="default"
              className="w-xs-100 success_color mt-2 mb-2 text-black font-700 font-fam"
              style={{ padding: "4px 8px" }}
            >
              <span className="text-success">View Details</span>
            </Rsuite.Button>
          </a>
        );
      },
    },
  ];

  const getChip = (value) => {
    return (
      <Chip
        style={{
          fontSize: "13px",
          fontWeight: "600",
          backgroundColor: bgColors[`${value}`],
          color: colors[`${value}`],
        }}
        avatar={
          <img
            src={
              value === "Successful"
                ? greentick
                : value === "Pending Admin Approval"
                ? warning
                : cross
            }
            style={{ width: "13px", marginLeft: "10px" }}
            alt="corss"
          />
        }
        // color="error"
        label={value}
      />
    );
  };

  const handlePopoverOpen = (event) => {
    const field = event.currentTarget.dataset.field;
    const id = event.currentTarget.parentElement.dataset.id;
    const row = rows.find((r) => r.id == id);
    // console.log(field, row, row[field]);
    setValue(row[field]);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <Paper sx={{ width: "100%" }}>
        <Box
          sx={{
            height: 528,
            width: 1,
            "& .MuiDataGrid-virtualScrollerRenderZone": {
              "& .MuiDataGrid-row": {
                // backgroundColor: 'rgba(235, 235, 235, .7)',
                fontWeight: 500,
                color: "#667085",
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
              },
            },
            "& .header": {
              backgroundColor: "#F4F4F4",
              "& div": {
                "& div": {
                  "& div": {
                    "& div": {
                      fontSize: "14px",
                      fontWeight: 600,
                      fontFamily: "Inter, sans-serif",
                    },
                  },
                },
              },
            },
          }}
        >
          <DataGrid
            sx={{
              borderRadius: "8px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
            }}
            // componentsProps={{
            //   cell: {
            //     onMouseEnter: handlePopoverOpen,
            //     onMouseLeave: handlePopoverClose,
            //   },
            // }}
            components={{
              LoadingOverlay: LinearProgress,
              // Footer
            }}
            rows={rows}
            columns={columns}
            loading={loading}
            rowHeight={40}
            disableColumnFilter={true}
            disableColumnMenu={true}
            pagination
            paginationMode="server"
            rowsPerPageOptions={[10, 20, 50]}
            page={paginateTable.currentPage}
            rowCount={translist.count}
            pageSize={paginateTable.pageSize}
            onPageChange={(newPage) => handleChangePage(newPage)}
            onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          />
          {/* <Popover
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1, borderRadius: '10px' }}>{value}</Typography>
          </Popover> */}
        </Box>
      </Paper>
    </>
  );
}
