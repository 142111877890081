// Import the the ethers shims (**BEFORE** ethers)
import "@ethersproject/shims";
import { ethers } from "ethers";

const TokenABI = [
  {
    inputs: [
      {
        internalType: "string",
        name: "_name",
        type: "string",
      },
      {
        internalType: "string",
        name: "_symbol",
        type: "string",
      },
      {
        internalType: "uint8",
        name: "_decimals",
        type: "uint8",
      },
      {
        internalType: "uint256",
        name: "_initialSupply",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_from",
        type: "address",
      },
      {
        internalType: "address",
        name: "_to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];
const provider = new ethers.providers.JsonRpcProvider(
  "https://coston-api.flare.network/ext/C/rpc"
);

export const createWalletFromPrivateKey = (privateKey, onSuccess, onError) => {
  try {
    const wallet = new ethers.Wallet(privateKey);
    console.log(
      "CREATED WALLET USING ETHERS",
      wallet,
      "add",
      wallet.address,
      "key",
      wallet.privateKey
    );
    onSuccess(wallet);
  } catch (error) {
    onError(error);
  }
};

export const getAccountFromPrivateKey = (privateKey, onSuccess, onError) => {
  try {
    const wallet = new ethers.Wallet(privateKey);
    console.log(
      "FETCHED WALLET USING ETHERS",
      wallet,
      "add",
      wallet.address,
      "key",
      wallet.privateKey
    );
    onSuccess(wallet);
  } catch (error) {
    onError(error);
  }
};

export const createWallet = (onSuccess, onError) => {
  try {
    const wallet = ethers.Wallet.createRandom();
    console.log("CREATED WALLET USING ETHERS", wallet);
    onSuccess(wallet);
  } catch (error) {
    onError(error);
  }
};

export const handleTransfer = async (
  wallet_address,
  privateKey,
  recipientAddress,
  amount,
  ContractAddress,
  onSuccess,
  onError
) => {
  try {
    const loadedWallet = new ethers.Wallet(privateKey, provider);
    if (loadedWallet?.address !== wallet_address) {
      onError(new Error("Invalid Passphrase"));
      return false;
    }
    const contract = new ethers.Contract(
      ContractAddress,
      TokenABI,
      loadedWallet
    );
    const weiAmount = ethers.utils.parseEther(amount.toString());
    const nonce = await provider.getTransactionCount(
      loadedWallet.address,
      "latest"
    );
    console.log('jcnkdjncksdcsdc', contract)
    const unsignedTx = await contract.populateTransaction.transfer(
      recipientAddress,
      weiAmount
    );

    const tx = {
      ...unsignedTx,
      from: loadedWallet.address,
      nonce: nonce,
      gasLimit: 210000,
      value: 0,
      chainId: await provider.getNetwork().then((network) => network.chainId),
      gasPrice: await provider.getFeeData().then((data) => data.gasPrice),
    };
    const signedTx = await loadedWallet.signTransaction(tx);
    console.log("SignedTransaction", signedTx);
    onSuccess(signedTx);
  } catch (error) {
    onError(error);
  }
};

export const confirmPassphrase = (address, privateKey, onSuccess, onError) => {
  try {
    const wallet = new ethers.Wallet(privateKey);
    console.log("ADDRESS HAI RE", wallet.address, "", address);
    if (wallet.address === address) {
      onSuccess(wallet);
    } else {
      onError(new Error("Invalid Passphrase"));
    }
  } catch (error) {
    onError(error);
    return false;
  }
};
