import React from "react";
import { styled } from "@material-ui/core/styles";
import { Typography, Stack, Paper } from "@mui/material";
import { useSelector } from "react-redux";

const MyPaper = styled(Paper)({
  borderRadius: 8,
  backgroundColor: "#F0F0F0",
  padding: "1rem",
});

const QrDetailsCard = ({ address, sx }) => {
  const userData = useSelector((store) => store.profileReducer.user);

  const styledInfo = (data) => {
    return (
      <Typography
        variant="subtitle2"
        sx={{
          wordBreak:"break-all",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {data}
      </Typography>
    );
  };

  const styledTitle = (data) => {
    return (
      <Typography sx={{ color: "#4a4a4a" }} variant="caption">
        {data}
      </Typography>
    );
  };
  return (
    <MyPaper
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "1rem",
        marginBottom: "1rem !important",
        ...sx,
      }}
      elevation={0}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack>{styledTitle("Coin")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo(address?.wallet?.coin?.name)}</Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack>{styledTitle("Network")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>
          {styledInfo(address?.blockchain?.name)}
        </Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"} gap={'4rem'}>
        <Stack>{styledTitle("Address")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo(address?.address)}</Stack>
      </Stack>{" "}
      {/* <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack>{styledTitle("Transfer Time")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo("2 hours")}</Stack>
      </Stack> */}
    </MyPaper>
  );
};

export default QrDetailsCard;
