import React from 'react';
import {
    Radio,
    FormControlLabel,
  } from "@mui/material";
import Colors from '../assets/colors';

const CustomRadio = ({ value, label }) => {
  return (
    <FormControlLabel
    value={value}
    control={
      <Radio
        sx={{
          color: Colors.primary,
          "&.Mui-checked": {
            color: Colors.primary,
          },
        }}
      />
    }
    label={label}
  />
  );
};

export default CustomRadio;