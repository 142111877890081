import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, Table } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { capitalize } from "lodash";
import { Chip } from "@mui/material";
import { Button } from "rsuite";
import edit from "../../../assets/img/icons/edit.svg";

const TicketList = ({ tickets, setPageChange, paginationdetails }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState("");

  const history = useHistory();

  console.log(paginationdetails);

  const handlepagechange = (newPage) => {
    console.log(newPage);
    setPageChange({
      ...paginationdetails,
      currentPage: newPage,
    });
  };

  const handleChangeRowsPerPage = (newSize) => {
    setPageChange({
      ...paginationdetails,
      currentPage: 0,
      pageSize: newSize,
    });
  };

  const createDataRow = (ticket) => ({
    id: ticket.id,
    priority: ticket.priority.name,
    subject: ticket.subject,
    category: capitalize(ticket.category.name),
    status: ticket.status,
    action: "View Details",
  });

  const rows =
    tickets?.results?.length > 0
      ? tickets.results.map((ticket) => createDataRow(ticket))
      : [];

  const columns = [
    {
      field: "subject",
      headerName: "Subject",
      flex: 4,
      minWidth: 120,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <p
            style={{
              color: "black",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.formattedValue}
          </p>
        );
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
      minWidth: 120,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={{ color: "black" }}>{params.formattedValue}</p>;
      },
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: 120,
      headerClassName: "header",
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1,
      minWidth: 150,
      headerClassName: "header",
      renderCell: (params) => {
        return params.formattedValue === "open" ? (
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#ECFDF3",
              color: "#027A48",
            }}
            label="Open"
            color="success"
          />
        ) : (
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#f8dada",
              color: "#B42318",
            }}
            color="error"
            label="Closed"
          />
        );
      },
    },
    {
      flex: 1,
      field: "action",
      headerName: "Action",
      sortable: false,
      minWidth: 120,
      // maxWidth: 150,
      headerClassName: "header",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log(params);
          history.push("/support/ticketreply/" + params.id);
        };

        return (
          <Button
            onClick={onClick}
            style={{ background: "none" }}
            className="d-flex align-items-center gap-2"
          >
            <img src={edit} alt="edit" />
            <span style={{ color: "black" }}>Edit</span>
          </Button>
        );
      },
    },
  ];

  const handlePopoverOpen = (event) => {
    const field = event.currentTarget.dataset.field;
    const id = event.currentTarget.parentElement.dataset.id;
    const row = rows.find((r) => r.id == id);
    // console.log(field, row, row[field]);
    setValue(row[field]);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Paper className="mb-6" sx={{ width: "100%" }}>
        <Box
          sx={{
            height: 615,
            width: 1,
            "& .MuiDataGrid-virtualScrollerRenderZone": {
              "& .MuiDataGrid-row": {
                // backgroundColor: 'rgba(235, 235, 235, .7)',
                fontWeight: 500,
                color: "#667085",
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
              },
            },
            "& .header": {
              backgroundColor: "#F4F4F4",
              "& div": {
                "& div": {
                  "& div": {
                    "& div": {
                      fontSize: "14px",
                      fontWeight: 600,
                      fontFamily: "Inter, sans-serif",
                    },
                  },
                },
              },
            },
          }}
        >
          <DataGrid
            sx={{
              borderRadius: "8px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
            }}
            // componentsProps={{
            //   cell: {
            //     onMouseEnter: handlePopoverOpen,
            //     onMouseLeave: handlePopoverClose,
            //   },
            // }}
            components={{
              LoadingOverlay: LinearProgress,
            }}
            rows={rows}
            columns={columns}
            // loading={loading}
            rowHeight={72}
            disableColumnFilter={true}
            disableColumnMenu={true}
            pagination
            paginationMode="server"
            rowsPerPageOptions={[10, 20, 50]}
            page={paginationdetails.currentPage}
            rowCount={tickets.count}
            pageSize={paginationdetails.pageSize}
            onPageChange={(newPage) => handlepagechange(newPage)}
            onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          />
          {/* <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1, borderRadius: '10px' }}>{value}</Typography>
        </Popover> */}
        </Box>
      </Paper>
    </>
  );
};

export default TicketList;
