import React, { useState, useEffect } from 'react';
import { Button, ButtonToolbar } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container } from '@themesberg/react-bootstrap';
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as API from '../../ApiUrl';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import back from '../../assets/img/icons/back.svg';

import { CircularProgress } from '@material-ui/core';
// import { set } from "date-fns";
const validationSchema = yup.object({
  // is_active: yup.bool()., "Select user mode is required"),
});

const UserApproval = (props) => {
  const [loading, setloading] = useState(false);
  const params = useParams();

  const history = useHistory();
  const [users, setUsers] = useState({});
  const [changeStatus, setChangeStatus] = useState('');
  const [changeValue, setChangeValue] = useState({});
  const [error, setError] = useState(null);

  const onSubmit = async (values) => {
    const { is_active } = values;

    const data = { ...changeValue };

    setloading(true);
    let token = localStorage.getItem('token');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.patch(
        `${API.USER_LIST}${users.id}/`,
        data,
        config
      );
      // localStorage.setItem("user-info", JSON.stringify(res.data));
      // localStorage.setItem("token", res.data.token);

      formik.resetForm();
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: 'success',
        title: 'User Update successfull',
      });
      history.push('/administration/systemUser');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Something went wrong');
      setError(err.response.data.error);
    }
    setloading(false);
  };

  const getUsers = async () => {
    try {
      let token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${API.USER_LIST}` + params.id + '/',
        config
      );
      setUsers(await response.data.data);
      setChangeStatus((await response.data.data.is_active) ? 'True' : 'False');
    } catch (error) {}
  };

  useEffect(() => {
    getUsers();
  }, []);

  const formik = useFormik({
    initialValues: users,

    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  const handleChange = (e) => {
    setUsers({ ...users, [e.target.name]: e.target.value });
    setChangeValue({ ...changeValue, [e.target.name]: e.target.value });
    setChangeStatus(e.target.value);
  };

  return (
    <div className="page-wrapper mt-3 font-fam bg-white">
      <Col className="ps-4">
        <span onClick={() => history.goBack()} className="back mb-4">
          <img src={back} alt="back" />
          <span>Back</span>
        </span>
      </Col>

      <div className="container-fluid d-flex align-items-center flex-column pb-4">
        <h2 className="text-center m-0 font-fam">Change System User Status</h2>
        <p className="text-center font-12 m-0">
          Please Notice this action can't be undone after 48hrs
        </p>

        <Col xl={7}>
          <div class="form-floating mb-4 mt-4">
            <input
              type="text"
              class="form-control h-55 font-100"
              id="floatingUser"
              value={users.first_name}
              placeholder="Name "
            />
            <label for="floatingUser">First Name</label>
          </div>

          <div class="form-floating mb-4 mt-4">
            <input
              type="text"
              class="form-control h-55 font-100"
              id="floatingUser"
              value={users.last_name}
              placeholder="Name "
            />
            <label for="floatingUser">Last Name</label>
          </div>
          {users.user_role === 'Cashier' ? (
            <div class="form-floating mb-4 mt-4">
              <input
                type="text"
                class="form-control h-55 font-100"
                id="floatingUser"
                value={users.counter.name || 'Master'}
                placeholder="Counter"
              />
              <label for="floatingUser">Counter</label>
            </div>
          ) : null}
          <div class="form-floating mb-4 mt-4">
            <input
              type="email"
              class="form-control h-55 font-100"
              id="floatingUser"
              value={users.email}
              placeholder="Email "
            />
            <label for="floatingUser">Email</label>
          </div>
          <div class="form-floating mb-4 mt-4">
            <input
              type="text"
              class="form-control h-55 font-100"
              id="floatingUser"
              value={users.phone_number}
              placeholder="Phone "
            />
            <label for="floatingUser">Phone</label>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div class="form-floating mb-4">
              {/* <div>{formik.values.is_active ? "raq" : "fals"}</div> */}
              <select
                id="is_active"
                name="is_active"
                value={changeStatus}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                className="form-control h-55 font-fam font-100"
                style={{ paddingTop: '7px' }}
              >
                <option value={'True'}>Active</option>
                <option value={'False'}>Suspended</option>
              </select>
              <span className="text-red">
                {formik.touched.is_active && Boolean(formik.errors.is_active)}
              </span>
              <span className="text-red">
                {formik.touched.is_active && formik.errors.is_active}
              </span>
            </div>

            <ButtonToolbar className="d-flex justify-content-center ">
              <Button
                style={{ minWidth: 'fit-content' }}
                appearance="primary"
                type="submit"
                disabled={!formik.isValid}
                className="font-fam primary_color w-50"
              >
                {loading && <CircularProgress size={14} />}
                {!loading && 'Change User Status'}
              </Button>
              <ToastContainer />
            </ButtonToolbar>
          </form>
        </Col>
      </div>
    </div>
  );
};

export default UserApproval;
