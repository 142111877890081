
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openModal } from '../redux/actions/modalActions';

const useNavigationPrompt = (when, message) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const unblock = useSelector((state) => state.modalReducer.isModalOpen);

  useEffect(() => {
    if (!when) return;

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = message;
    };

    const handleBlockNavigation = (nextLocation) => {
      if (!unblock) {
        dispatch(openModal(nextLocation.pathname));
        return false;
      }
      return true;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    const unblockHistory = history.block((nextLocation) => handleBlockNavigation(nextLocation));

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockHistory();
    };
  }, [when, message, dispatch, history, unblock]);
};

export default useNavigationPrompt;
