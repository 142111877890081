import React from 'react'
import { Modal, ButtonToolbar, Button,  } from 'rsuite';
import { BsPlus } from "react-icons/bs";
import AddCustomer from '../pages/AddCustomer';



        class Modals extends React.Component {
            constructor(props) {
              super(props);
              this.state = {
                backdrop: true,
                show: false
              };
              this.close = this.close.bind(this);
              this.open = this.open.bind(this);
            
            }
            close() {
              this.setState({ show: false });
            }
            open() {
              this.setState({ show: true });
            }
            render() {
              const { backdrop, show } = this.state;
              return (
                <div className="modal-container">
                  
                  <ButtonToolbar>
                    <Button appearance="default" className="pull-right font-12 load text-white" onClick={this.open}> <BsPlus size="1.2em" />Add Customer
                    </Button>
                  </ButtonToolbar>
          
                  <Modal backdrop={backdrop} show={show} onHide={this.close}>
                    <Modal.Header>
                      <Modal.Title className="font-fam">Add Customer</Modal.Title>
                    </Modal.Header>
                    <hr />
                    <Modal.Body>
                     <AddCustomer />
                    </Modal.Body>
                  </Modal>

                 

                
                </div>
              );
            }
          }
 
export default Modals
