import React, { useState } from 'react';
import { CircularProgress, FormHelperText } from '@material-ui/core';
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';
import TextField from '@material-ui/core/TextField';

import * as API from '../../ApiUrl';

import stblogo from '../../assets/img/icons/stblogo.svg';
import mail from '../../assets/img/icons/mail.svg';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@mui/material';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import { textFieldLabelProps } from '../../utils/common';

const queryString = require('query-string');
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

const validationSchema = yup.object({
  oldpassword: yup
    .string('Enter your old password')
    .min(8, 'Old password must be 8 character')
    .required('Old Password is required'),

  password: yup
    .string('Enter your password')
    .matches(
      passwordRegex,
      'Please enter at least 8 characters, 1 lowercase, 1 uppercase, 1  special character & 1 number'
    )
    .min(8, 'Password should be of minimum 8 characters length')
    .required('New Password is required'),

  confirmpassword: yup
    .string('Enter your password')
    .oneOf([yup.ref('password')], 'Passwords do not match')
    .required('Confirm Password is Required'),
});

export default () => {
  const history = useHistory();
  const parsed = queryString.parse(history.location.search);

  const [error, setError] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const [showTempPassword, setShowTempPassword] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    console.log('values-------', values);
    const data = {
      old_password: values.oldpassword,
      new_password: values.password,
      token: parsed.token,
    };
    console.log('data-------', data);
    try {
      const response = await axios.post(API.RESET_PASSWORD, data);
      console.log('pass change', response);

      // const Toast = Swal.mixin({
      //   toast: true,
      //   position: 'center',
      //   showConfirmButton: false,
      //   timer: 3000,
      //   timerProgressBar: true,
      //   onOpen: (toast) => {
      //     toast.addEventListener('mouseenter', Swal.stopTimer);
      //     toast.addEventListener('mouseleave', Swal.resumeTimer);
      //   },
      // });
      toast.success(
        'Congratulations, your password has been successfully updated'
      );

      // Toast.fire({
      //   icon: 'success',
      //   title: 'Password Change successfully',
      // });
      history.replace('/signin');
    } catch (err) {
      // toast.err
      toast.error(err?.response?.data?.message || 'Something went wrong');
      setError(err?.response?.data?.message);
    }
    setLoading(false);
  };
  const formik = useFormik({
    initialValues: {
      oldpassword: parsed.tmp_pwd,
      password: '',
      confirmpassword: '',
    },
    validateOnBlur: true,
    // validateOnChange: false,
    onSubmit,
    validationSchema: validationSchema,
    isInitialValid: false,
    validateOnMount:true
  });
  return (
    <main>
      <section className="primary_color vh-100 position-relative">
        <img
          src={stblogo}
          className="position-absolute top-5 start-50 translate-middle"
          alt="logo"
        />

        <a
          className="socials twitter"
          href="https://twitter.com/Spend_The_Bits"
          target="_blank"
          rel="noopener noreferrer"
        >
          @Spend_The_Bits
        </a>
        <a
          className="socials mail"
          href="mailto:help@spendthebits.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={mail} alt="mail" />
          help@spendthebits.com
        </a>
        <Container>
          <Dialog
            open={true}
            disableEscapeKeyDown={true}
            fullWidth
            className="wallet-configure"
            BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0)' } }}
            PaperProps={{
              style: {
                boxShadow: 'none',
                borderRadius: '8px',
                width: '480px',
                minHeight: '428px',
                maxHeight: '80vh',
              },
            }}
          >
            <DialogTitle className="text-center">
              <p className="dialog-title fw-bold">Change User Password </p>
            </DialogTitle>
            <DialogContent>
              <Form onSubmit={formik.handleSubmit}>
                <FormControl
                  sx={{ m: 1 }}
                  variant="outlined"
                  className="w-100 pb-0 mb-4"
                >
                  <TextField
                    variant="outlined"
                    className="w-100 mt-3"
                    id="oldpassword"
                    type={showTempPassword ? 'text' : 'password'}
                    label="Temporary Password"
                    value={formik.values.oldpassword}
                    onChange={formik.handleChange}
                    InputLabelProps={textFieldLabelProps}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowTempPassword((prevState) => !prevState);
                            }}
                            edge="end"
                          >
                            {showTempPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>

                <FormControl
                  sx={{ m: 1 }}
                  variant="outlined"
                  className="w-100 pb-0 mb-4"
                >
                  <TextField
                    // onChange={(e) => handleChange(e)}
                    variant="outlined"
                    className="w-100"
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={textFieldLabelProps}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPassword((prevState) => !prevState);
                            }}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </FormControl>

                <FormControl
                  sx={{ m: 1 }}
                  variant="outlined"
                  className="w-100 pb-0 mb-2"
                >
                  <TextField
                    // onChange={(e) => handleChange(e)}
                    variant="outlined"
                    className="w-100 mb-3"
                    id="confirmpassword"
                    label="Confirm Password"
                    type={showRepeatPassword ? 'text' : 'password'}
                    value={formik.values.confirmpassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={textFieldLabelProps}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowRepeatPassword((prevState) => !prevState);
                            }}
                            edge="end"
                          >
                            {showRepeatPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={
                      formik.touched.confirmpassword &&
                      Boolean(formik.errors.confirmpassword)
                    }
                    helperText={
                      formik.touched.confirmpassword &&
                      formik.errors.confirmpassword
                    }
                  />
                </FormControl>

                {error && (
                  <div className=" mb-2  text-danger">
                    <span className="fw-normal">{error}</span>
                  </div>
                )}

                <Button
                  variant="primary"
                  onClick={() => {
                    setIsButtonLoading(true);
                    setTimeout(() => {
                      setIsButtonLoading(false);
                    }, 2000);
                  }}
                  isLoading={isButtonLoading}
                  disabled={!formik.isValid}
                  className="mt-4 w-100"
                  type="submit"
                >
                  {loading && <CircularProgress size={14} />}
                  {!loading && 'Change Password'}
                </Button>

                <ToastContainer />
              </Form>
            </DialogContent>
          </Dialog>
        </Container>
      </section>
    </main>
  );
};
