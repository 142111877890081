import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar } from "rsuite";
import { useHistory } from "react-router-dom";
import { Row, Col, Container } from "@themesberg/react-bootstrap";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress, Box } from "@material-ui/core";
import _ from "lodash";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MenuItem } from "@material-ui/core";

import * as API from "../../ApiUrl";
import { useSelector } from "react-redux";
import FormImpl from "@themesberg/react-bootstrap/lib/esm/Form";
import { textFieldLabelProps } from "../../utils/common";

const numricRegex = /(?=.*[0-9])/;
const validationSchema = yup.object({
  first_name: yup
    .string("Enter your first name")
    .min(2, "Please enter valid name")
    .required("First Name is required!"),

  last_name: yup
    .string("Enter your last name")
    .min(2, "Please enter valid name")
    .required("Last Name is required!"),

  email: yup
    .string("Enter your email")
    .email("Please enter valid email")
    .required("Email is required!"),

  phone_number: yup
    .string("Enter your phonenumber")
    .matches(numricRegex, "Only Number Required")
    .min(10, "Enter Valid Number")
    .max(12, "Phone Number Too Long ! Not Valid")
    .required("Phone Number is required"),

  dob: yup.string("Enter date of birth").required("Date of birth is required"),
  user_role: yup.string("Select user").required("Userlevel is required"),
  store: yup.string("Select store").required("Userstore is required"),
  counter: yup.string("Select counter").required("Counter is required"),
  document_type: yup
    .string("Select document type")
    .required("Document type is required"),
  document_number: yup
    .string("Select document number")
    .required("Document number is required"),
});

const rolesassign = [
  {
    value: "manager",
    name: "Manager",
  },
  {
    value: "administrator",
    name: "Administrator",
  },
  {
    value: "sub_merchant",
    name: "Sub Merchant",
  },
];

const AddSystemUserModal = ({ onClose, isOpen, onUserAdd }) => {
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState();
  const [userRole, setUserRole] = useState([]);
  const [userStore, setUserStore] = useState("");
  const [userCounters, setUserCounters] = useState("");
  const [showStores, setShowStores] = useState(false);
  const [showCounters, setShowCounters] = useState(false);
  const [docTypes, setDocTypes] = useState([]);
  const [countryList, setCountryList] = useState(
    JSON.parse(localStorage.getItem("country-list")) || []
  );

  const curRole = useSelector((store) => store.authReducer.userRole);
  const isSingleWallet = useSelector(
    (store) => store.authReducer.isSingleWallet
  );
  let token = localStorage.getItem("token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const onSubmit = async (values) => {
    const { confirmpassword, ...data } = values;
    data.phone_number = phoneNumber;
    data.country = selectedCountry.id;
    if (data.user_role === "administrator") {
      delete data["store"];
      delete data["counter"];
    } else if (data.user_role === "franchisee") {
      delete data["counter"];
    } else if (data.user_role === "manager") {
      delete data["counter"];
    }
    setloading(true);

    try {
      const response = await axios.post(`${API.ADD_USER}`, data, config);
      toast.success("User created successfully");
      onUserAdd();
      formik.resetForm();
      onClose();

      history.push("/administration/systemUser");
    } catch (err) {
      setloading(false);
      toast.error(err?.response?.data?.message[0] || "Something went wrong");
    }
    setloading(false);
  };

  const onChangeStore = async (e) => {
    setloading(true);
    const response = await axios.get(
      `${API.COUNTER}?store=${e.target.value}`,
      config
    );
    if (response?.data?.results) {
      setUserCounters(response.data.results);
    }
    setloading(false);
  };

  const getUserStores = async () => {
    setloading(true);
    const response = await axios.get(`${API.STORE_LIST}`, config);
    if (response?.data?.results) {
      setUserStore(response.data.results);
    }
    setloading(false);
  };

  const onChangeRole = (e) => {
    if (e.target.value === "franchisee") {
      setUserStore([]);
      setUserCounters([]);
      formik.handleChange({ target: { name: "store", value: "" } });
      formik.handleChange({ target: { name: "counter", value: "counter" } });
      setShowStores(true);
      setShowCounters(false);
      getUserStores();
    }
    // else if (e.target.value === 'administrator') {
    //   setUserStore([]);
    //   setUserCounters([]);
    //   // Dummy values to pass validation
    //   formik.values.store = 'store';
    //   formik.values.counter = 'counter';
    //   setShowStores(false);
    //   setShowCounters(false);
    // }
    else if (e.target.value === "manager") {
      setUserStore([]);
      setUserCounters([]);
      formik.handleChange({ target: { name: "store", value: "" } });
      formik.handleChange({ target: { name: "counter", value: "counter" } });
      setShowStores(true);
      setShowCounters(false);
      getUserStores();
    } else {
      setUserStore([]);
      setUserCounters([]);
      formik.handleChange({ target: { name: "store", value: "" } });
      formik.handleChange({ target: { name: "counter", value: "" } });
      setShowStores(true);
      setShowCounters(true);
      getUserStores();
    }
  };

  const getUserRole = () => {
    axios.get(`${API.USER_ROLE}`, config).then((res) => {
      let roles;
      if (!isSingleWallet) {
        if (curRole === "administrator") {
          roles = res.data.data.filter((role) => role.value === "franchisee");
        } else if (curRole === "franchisee") {
          roles = res.data.data.filter(
            (role) => role.value !== "administrator"
          );
        } else if (curRole === "manager") {
          roles = [{ name: "Cashier", value: "cashier" }];
        } else {
          roles = [];
        }
      } else {
        if (curRole === "administrator") {
          roles = res.data.data;
        } else if (curRole === "manager") {
          roles = [{ name: "Cashier", value: "cashier" }];
        } else {
          roles = [];
        }
      }
      setUserRole(roles);
    });
  };

  const getDocTypes = async () => {
    const response = await axios.get(`${API.GET_DOC_TYPES}`, config);
    setDocTypes(response.data.data.doc_types);
  };

  useEffect(() => {
    getUserRole();
    getDocTypes();
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      user_role: "",
      store: "",
      counter: "",
      dob: "",
      document_type: "",
      document_number: "",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit,
    isInitialValid: false,
    validateOnMount: true,
    handleChange: onChangeRole,
  });

  return (
    <Dialog
      open={isOpen}
      maxWidth={"sm"}
      onClose={onClose}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
          width: "628px",
          minHeight: "550px",
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle className="text-center">
        <p className="dialog-title">Create System User Account</p>
        <p className="">
          Please make sure all the required field is carefully filled with valid
          information.
        </p>
      </DialogTitle>
      <hr className="hr" />
      <form onSubmit={() => {}}>
        <DialogContent className="flex-col">
          <Row>
            <Col xl={6} xs={12}>
              <TextField
                // onchange={(e) => handleChange(e)}
                // autoFocus
                required
                className="w-100 mb-4"
                variant="outlined"
                // value={data.name}
                name="first_name"
                id="first_name"
                label="First Name"
                InputLabelProps={textFieldLabelProps}
                type="text"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                // onChange={(e) => handleChange(e)}
                required
                className="w-100 mb-4"
                variant="outlined"
                name="last_name"
                id="last_name"
                label="Last Name"
                // placeholder="last_name"
                InputLabelProps={textFieldLabelProps}
                type="text"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                // onChange={(e) => handleChange(e)}
                required
                className="w-100 mb-4"
                variant="outlined"
                name="email"
                id="email"
                label="Email"
                // placeholder="email"
                InputLabelProps={textFieldLabelProps}
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Col>
            <Col xl={6} xs={12} className="mb-4">
              <PhoneInput
                onlyCountries={_.map(countryList, (country) =>
                  country.alpha2code.toLowerCase()
                )}
                value={formik.values.phone_number}
                country="ca"
                inputProps={{
                  name: "Phone Number",
                  required: true,
                }}
                disableCountryGuess={true}
                // disableInitialCountryGuess={true}
                isValid={(value, country) => {
                  setSelectedCountry(
                    _.find(countryList, {
                      alpha2code: country.iso2.toUpperCase(),
                    })
                  );
                  setPhoneNumber(value.replace(country.dialCode, ""));
                  return true;
                }}
                inputStyle={{
                  height: 55,
                  borderRadius: 5,
                  width: "100%",
                }}
                autoFormat={false}
                id="phone_number"
                name="phone_number"
                countryCodeEditable={false}
                className=" bg-white signup-phoneinput"
                onChange={(phone) => {
                  // formik.handleChange expects a event, but PhoneInput is just giving the value, therefore made an event and sent to handleChange
                  let e = {
                    target: {
                      name: "phone_number",
                      value: phone,
                    },
                  };
                  formik.handleChange(e);
                }}
                error={
                  formik.touched.phone_number &&
                  Boolean(formik.errors.phone_number)
                }
                helperText={
                  formik.touched.phone_number && formik.errors.phone_number
                }
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                fullWidth
                type={"date"}
                variant="outlined"
                className="mb-4 bg-white"
                id="dob"
                name="dob"
                InputLabelProps={textFieldLabelProps}
                label="Date of birth"
                value={formik.values.dob}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.dob && Boolean(formik.errors.dob)}
                helperText={formik.touched.dob && formik.errors.dob}
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                select
                fullWidth
                variant="outlined"
                className="mb-4 bg-white"
                id="document_type"
                name="document_type"
                InputLabelProps={textFieldLabelProps}
                label="Document Type"
                value={formik.values.document_type}
                onChange={(event) => {
                  formik.handleChange(event);
                }}
                onBlur={(event) => {
                  formik.handleChange(event);
                }}
                error={
                  formik.touched.document_type &&
                  Boolean(formik.errors.document_type)
                }
                helperText={
                  formik.touched.document_type && formik.errors.document_type
                }
              >
                {docTypes.map((doctype) => (
                  <MenuItem value={doctype.value}>{doctype.name}</MenuItem>
                ))}
              </TextField>
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                // onChange={(e) => handleChange(e)}
                required
                className="w-100 mb-4"
                variant="outlined"
                name="document_number"
                id="document_number"
                label="Document Number"
                // placeholder="document_number"
                InputLabelProps={textFieldLabelProps}
                type="text"
                value={formik.values.document_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.document_number &&
                  Boolean(formik.errors.document_number)
                }
                helperText={
                  formik.touched.document_number &&
                  formik.errors.document_number
                }
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                select
                required
                className="w-100 mb-4"
                variant="outlined"
                // onChange={(e) => handleChange(e)}
                name="user_role"
                id="user_role"
                // value={data.address}

                InputLabelProps={textFieldLabelProps}
                label="User Role"
                type="text"
                value={formik.values.user_role}
                onChange={(e) => {
                  onChangeRole(e);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.user_role && Boolean(formik.errors.user_role)
                }
                helperText={formik.touched.user_role && formik.errors.user_role}
              >
                {userRole.map((role, i) => (
                  <MenuItem value={role.value}>{role.name}</MenuItem>
                ))}
              </TextField>
            </Col>
            {!!showStores && (
              <Col xl={12}>
                <TextField
                  select
                  required
                  className="w-100 mb-4"
                  variant="outlined"
                  // onChange={(e) => handleChange(e)}
                  name="store"
                  id="store"
                  value={formik.values.store}
                  InputLabelProps={textFieldLabelProps}
                  label="Store"
                  type="text"
                  onChange={(e) => {
                    onChangeStore(e);
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.store && Boolean(formik.errors.store)}
                  helperText={formik.touched.store && formik.errors.store}
                >
                  {userStore.map((store, i) => (
                    <MenuItem value={store.id}>{store.name}</MenuItem>
                  ))}
                </TextField>
              </Col>
            )}
            {!!showCounters && (
              <Col xl={12}>
                <TextField
                  select
                  required
                  className="w-100 mb-4"
                  variant="outlined"
                  // onChange={(e) => handleChange(e)}
                  name="counter"
                  id="counter"
                  value={formik.values.counter}
                  InputLabelProps={textFieldLabelProps}
                  label="Counter"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.counter && Boolean(formik.errors.counter)
                  }
                  helperText={formik.touched.counter && formik.errors.counter}
                >
                  {userCounters.map((counter, i) => (
                    <MenuItem value={counter.id}>{counter.name}</MenuItem>
                  ))}
                </TextField>
              </Col>
            )}
          </Row>
        </DialogContent>
        <hr className="hr" />
        <DialogActions className="footer-content ">
          <Button
            className="btn white-btn action-button basis-30"
            onClick={onClose}
            appearance="subtle"
          >
            Cancel
          </Button>
          <Button
            className={`btn action-button primary_color text-white basis-70 ${
              !formik.isValid ? "disabled" : null
            }`}
            appearance="primary"
            loading={loading}
            onClick={formik.handleSubmit}
            disabled={!formik.isValid || loading}
          >
            {!loading ? "Create User" : "loading..."}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddSystemUserModal;
