import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { setSingleWallet } from "../../redux/actions/auth";
import { getBusinessDetails } from "../../redux/actions/businessApi";
import { colors, handleBackgroundClick } from "../../utils/common";
import ButtonPrimary from "../../components/ButtonPrimary";
import { useRecoveryWords } from "../../hooks/useRecoveryWords";
import { Chip, Grid, Stack, Typography, Button } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { toast } from 'react-toastify';
import { generatePrivateKey } from "../../utils/hdKey";
import { createWalletFromPrivateKey } from "../../utils/flare";

const CreateWalletModal = (props) => {
  console.log("props", props);
  const { open, handleClose, setWalletDetails } = props;

  const [showPhrase, setShowPhrase] = useState(true);
  const [isLoading, setIsLoading] = useState(false)

  const onClose = (event, reason) => {
    handleBackgroundClick(reason, handleClose, false);
  };

  const { randomWords, generateWords, generateSeed } = useRecoveryWords();

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(randomWords);
    toast.success('PassPhrase copied successfully!');
  };

  const onContinue =async () => {
    // sending last word from the random words list as the the salt, as the salt cannot be empty
    console.log('yooo', randomWords, randomWords[11])
    setIsLoading(true);
   await onCreateWallet(randomWords[11])
  };


  const onCreateWallet  = async (password) => {

    try {
      setIsLoading(true);
      console.log('passsp',password)
      const seed = await generateSeed(password);
      console.log('seed', seed)

      if (seed !== undefined) {
        const key = await generatePrivateKey(seed);

        console.log(seed, key)

        createWalletFromPrivateKey(key,  
        wallet => {
          // setAccountAction(wallet);
          console.log(wallet)
          setIsLoading(false);
          setWalletDetails(wallet);
          onClose();
        },
        error => {
          toast.error(error.message);
          setIsLoading(false);
        });
      }
    } catch (error) {
      console.log('onCreateWallet#error', error.message);
    }
  };


  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <>
        <DialogTitle>Secret Recovery Phrase</DialogTitle>
        <DialogContent>
          <Stack sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ lineHeight: "1 !important" }}>
              Write down this 12-word Secret Recovery Phrase and save it in a
              place that you trust and only you can access.
            </Typography>
          </Stack>
          <Grid container spacing={2}>
            {randomWords.map((word, index) => (
              <Grid item xs={6} sm={4} key={index}>
                <Stack
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 1,
                  }}
                >
                  {index + 1}.{" "}
                  <Chip wid key={index} label={showPhrase ? word : '****'} sx={{ flexGrow: 1 }} />
                </Stack>
              </Grid>
            ))}
          </Grid>
          <Stack sx={{flexDirection:'row', justifyContent:'space-between', mt: 2}}>
            <Button
              component="label"
              sx={{ color: colors.primary, textTransform:'capitalize' }}
              startIcon={showPhrase ? <VisibilityOffIcon /> : <VisibilityIcon/>}
              onClick={() => setShowPhrase(!showPhrase)}
            >
             {showPhrase ? "Hide Seed Phrase" : "Show Seed Phrase"}
            </Button>
            <Button
              component="label"
              sx={{ color: colors.primary, textTransform:'capitalize' }}
              startIcon={<ContentCopyIcon />}
              onClick={copyToClipboard}
            >
              Copy to clipboard
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            paddingX: "24px",
            paddingBottom: "15px",
            gap: 1,
          }}
        >
          <ButtonPrimary loading={isLoading} onClick={onContinue}>Continue</ButtonPrimary>
        </DialogActions>
      </>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSingleWallet: () => dispatch(setSingleWallet()),
    getBusinessDetails: () => dispatch(getBusinessDetails()),
  };
};

export default connect(null, mapDispatchToProps)(CreateWalletModal);

// export default CreateWalletModal;
