import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { toast } from "react-toastify";
import { connect, useSelector } from "react-redux";
import ButtonPrimary from "../../../components/ButtonPrimary";
import ButtonSecondary from "../../../components/ButtonSecondary";
import * as yup from "yup";
import { merchantApi } from "../../../redux/api/apiConfig";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  GET_MERCHENT_WALLET_LIST,
  RESEND_REFUND_OTP,
  TRANS_STORE,
  TRANSACTION_VERIFY_WITHDRAW_ADDRESS,
  VERIFY_OTP,
  WITHDRAW,
} from "../../../ApiUrl";
import { useFormik } from "formik";
import {
  formatCurrency,
  statusColors,
  textFieldLabelProps,
} from "../../../utils/common";
import OtpInput from "react-otp-input";
import SyncAltRoundedIcon from "@mui/icons-material/SyncAltRounded";
import { handleTransfer } from "../../../utils/flare";
import isEmpty from "lodash/isEmpty";

const WithdrawModal = ({
  handleCloseModal,
  isOpen,
  onClose,
  isSingleWallet,
  userRole,
  userCurrency,
}) => {
  const [createWithdrawLoading, setCreateWithdrawLoading] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [showWalletList, setShowWalletList] = useState([]);
  const [storeOption, setStoreOption] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedWallet, setSelectedWallet] = useState("");
  const [withdrawMethodSearchTerm, setWithdrawMethodSearchTerm] = useState("");
  const [selectedWithdrawType, setSelectedWithdrawType] = useState("");
  const [selectedBlockchain, setSelectedBlockchain] = useState(null);
  const [selectedDepositType, setSelectedDepositType] = useState(null);
  const [showWallet, setShowWallet] = useState(isSingleWallet ? true : false);
  const [storeId, setStoreID] = useState("");
  const [otp, setOtp] = useState("");
  const [coinSearchTerm, setCoinSearchTerm] = useState("");
  const [blockchainSearchTerm, setBlockchainSearchTerm] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [otpTimer, setOtpTimer] = useState(15);
  const [loading, setloading] = useState(false);
  const [resendOtpLoading, setResendOtpLoading] = useState(false);
  const [coin, setCoin] = useState(null);

  const [showPassphrase, setShowPassphrase] = useState(false);
  const [walletFetchingLoading, setWalletFetchingLoading] = useState(true);

  const [step, setStep] = useState(isSingleWallet ? 2 : 1);
  const [startTimer, setStartTimer] = useState(false);

  const { wallet_address } = useSelector((state) => state.profileReducer.user);

  useEffect(() => {
    getWalletMenu();
    getStoreList();
  }, []);



  useEffect(() => {
    let timer;
    if (startTimer && otpTimer !== 0) {
      timer = setInterval(() => {
        setOtpTimer(otpTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [otpTimer, startTimer]);

  const resendOTP = async () => {
    try {
      setResendOtpLoading(true);
      const response = await merchantApi.get(
        `${RESEND_REFUND_OTP}${transactionId}`
      );
      setResendOtpLoading(false);
      toast.success(response.data.message);
    } catch (error) {
      setResendOtpLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const getWalletMenu = async () => {
    setWalletFetchingLoading(true);
    try {
      const response = await merchantApi.get(
        `${GET_MERCHENT_WALLET_LIST}?limit=100`
      );
      setWalletFetchingLoading(false);
      if (response.data) {
        console.log(response.data);
        setWalletList(response.data.results);
        setShowWalletList(response.data.results);
      }
    } catch (error) {}
  };

  const getStoreList = async () => {
    try {
      const response = await merchantApi.get(`${TRANS_STORE}`);
      setStoreOption(response.data.data);
      // To delay until wallet list is fetched
      // setTimeout(() => {
      // }, 2500);
    } catch (error) {}
  };

  const handleStorechange = (e) => {
    // If user changes store after previously selecting a store and wallet, we need to reset the wallet and coin
    setSelectedWallet("");
    setCoin(null);
    setStoreID(e.target.value);
    setSelectedStore(e.target.value);
    const selectedStoreWallets = showWalletList.filter(
      (item) => item.store.store_id === e.target.value
    );
    if (selectedStoreWallets.length > 0) {
      setWalletList(selectedStoreWallets);
    } else {
      setWalletList([]);
    }
    setShowWallet(true);
  };

  const interacValidationSchema = yup.object({
    amount: yup.number("Enter your amount").required("Amount is required"),
    toAddress: yup
      .string("Enter destination address")
      .required("Address is required"),
    description: yup
      .string("Enter description")
      .min(5, "Too Short")
      .max(300, "Too Long "),
  });

  const blockchainValidationSchema = yup.object({
    currencyAmount: yup
      .number("Enter your amount")
      .required("Amount is required")
      .moreThan(0, "Amount must be greater than 0"),
    localCurrencyAmount: yup
      .number("Enter your amount")
      .required("Amount is required")
      .moreThan(0, "Amount must be greater than 0"),
    toAddress: yup
      .string("Enter destination address")
      .required("Address is required"),
  });

  const passphraseValidationSchema = yup.object({
    passphrase: yup
      .string("Enter your passphrase")
      .required("Passphrase is required"),
  });

  const blockchainFormik = useFormik({
    initialValues: {
      currencyAmount: "",
      localCurrencyAmount: "",
      toAddress: "",
    },
    validateOnBlur: true,
    validationSchema: blockchainValidationSchema,
    isInitialValid: false,
    validateOnMount: true,
  });

  const passphraseFormik = useFormik({
    initialValues: {
      passphrase: "",
    },
    validateOnBlur: true,
    validationSchema: passphraseValidationSchema,
    isInitialValid: false,
    validateOnMount: true,
  });

  const handleSelectWallet = (wallet) => {
    setSelectedWallet(wallet);
    if (wallet?.coin?.is_withdraw_available) {
      if (wallet?.coin?.withdraw_types?.length === 0) {
        toast.error("Withdraw methods not available");
        return;
      }
      if (wallet?.coin?.withdraw_types?.length === 1) {
        setSelectedWithdrawType(wallet?.coin?.withdraw_types[0]);
        setStep("selectBlockchain");
        return;
      }
      setStep("selectWithdrawType");
    } else {
      toast.error(
        wallet?.coin?.withdraw_error_message ?? "Withdraw not available"
      );
    }
  };

  const handleSelectWithdrawMethod = (method) => {
    console.log("obcu2ebc92eiowcwec", selectedWallet);
    setSelectedWithdrawType(method);
    if (isEmpty(selectedWallet?.coin?.supported_deposit_blockchains)) {
      toast.error("No supported withdraw blockchains available");
      return;
    }
    if (selectedWallet?.coin?.supported_deposit_blockchains.length === 1) {
      setSelectedBlockchain(
        selectedWallet?.coin?.supported_deposit_blockchains[0]
      );
      setStep("blockchain");
      return;
    }
    setStep("selectBlockchain");
  };

  const handleSelectBlockchain = (blockchain) => {
    setSelectedBlockchain(blockchain);
    // if (wallet?.coin?.is_withdraw_available) {
    setStep("blockchain");
    // } else {
    //   toast.error(
    //     wallet?.coin?.withdraw_error_message ?? "Withdraw not available"
    //   );
    // }
  };

  const blockchainSubmit = async (values) => {
    let verifiedAddress = null;
    if (
      Number(blockchainFormik.values.currencyAmount) >
      Number(selectedWallet?.balance)
    ) {
      toast.error("Insufficient balance");
      return;
    }

    try {
      setVerificationLoading(true);
      const response = await merchantApi.post(
        `${TRANSACTION_VERIFY_WITHDRAW_ADDRESS}?limit=100`,
        {
          coin: selectedWallet?.coin?.id,
          address: blockchainFormik?.values?.toAddress,
          blockchain: selectedBlockchain.id,
        }
      );
      verifiedAddress = response.data.data.destination;
      setVerificationLoading(false);
      setDestinationAddress(verifiedAddress);
      toast.success("Address verified!");
    } catch (err) {
      console.log(err);
      setVerificationLoading(false);
      toast.error(err?.data?.data?.message || "Invalid Destination Address");
      return;
    }
    const requestData = {
      amount: Number(blockchainFormik.values.currencyAmount),
      coin: selectedWallet?.coin?.id,
      withdraw_address: blockchainFormik.values.toAddress,
    };
    if (userRole === "administrator") {
      setStep("passphrase");

      // Confirm this else part
    } else {
      try {
        const response = await merchantApi.post(
          `${WITHDRAW}${selectedDepositType?.key}/`,
          requestData
        );
        toast.success("Withdraw request sent!");
      } catch (error) {
        toast.error(
          error.data.data.message || "Request failed, try again later"
        );
      }
    }
  };

  const toggleShowPassphrase = () => {
    setShowPassphrase(!showPassphrase);
  };

  const blockchainPassphraseSubmit = async () => {
    setCreateWithdrawLoading(true);
    handleTransfer(
      wallet_address,
      passphraseFormik.values.passphrase,
      destinationAddress,
      blockchainFormik.values.currencyAmount,
      selectedWallet?.coin?.issuer_address,
      (response) => {
        const requestData = {
          type: "withdraw",
          amount: Number(blockchainFormik.values.currencyAmount),
          withdraw_address: blockchainFormik.values.toAddress,
          coin: selectedWallet?.coin?.id,
          blockchain: selectedBlockchain.id,
          stb_fee: 0,
          handler_fee: 0,
          blob: response,
        };
        console.log("req_data", requestData);
        createWithdraw(requestData);
        setStartTimer(true);
      },
      (error) => {
        setCreateWithdrawLoading(false);
        console.log(error);
        toast.error("Request failed, please check your passphrase and try again");
      }
    );
  };

  const createWithdraw = async (data) => {
    setCreateWithdrawLoading(true);
    try {
      const response = await merchantApi.post(
        `${WITHDRAW}${selectedWithdrawType?.key}/`,
        data
      );

      toast.success(response?.data?.message || "Request created successfully");
      setStep("otp");
      // handleCloseModal();
      setCreateWithdrawLoading(false);
      setTransactionId(response?.data?.data?.id);
      console.log("created res", response);
    } catch (error) {
      setCreateWithdrawLoading(false);
      toast.error(error.data.data.message || "Request failed, try again later");
    }
  };

  const onOtpSubmit = async () => {
    if (otp.length < 6) {
      toast.error("Please enter a valid OTP");
      return;
    }

    try {
      setCreateWithdrawLoading(true);
      const response = await merchantApi.post(
        `${VERIFY_OTP}${transactionId}/`,
        { otp }
      );
      toast.success(response?.data?.message || "OTP verified successfully");
      handleCloseModal();
      setCreateWithdrawLoading(false);
      setTransactionId(response?.data?.data?.id);
      console.log("created res", response);
    } catch (error) {
      setCreateWithdrawLoading(false);
      toast.error(error.data.data.message || "Request failed, try again later");
    }
  };

  const setEnteredAmountConvert = (amount, isLocalCurrency) => {
    // getFees(amount);
    if (isLocalCurrency) {
      const newValue =
        parseFloat(amount) / parseFloat(selectedWallet?.coin?.rate);
      if (isNaN(newValue)) {
        blockchainFormik.setFieldValue("localCurrencyAmount", 0);
        blockchainFormik.setFieldValue("currencyAmount", 0);
        // setCurrencyAmount(0);
        // setLocalCurrencyAmount(0);
      } else {
        console.log(newValue, amount);
        blockchainFormik.setFieldValue("localCurrencyAmount", amount);
        blockchainFormik.setFieldValue(
          "currencyAmount",
          newValue.toFixed(selectedWallet?.coin?.decimal_place)
        );
        // setLocalCurrencyAmount(`${amount}`);
        // setCurrencyAmount(newValue.toFixed(8));
      }
    } else {
      const newValue =
        parseFloat(amount) * parseFloat(selectedWallet?.coin?.rate);
      if (isNaN(newValue)) {
        blockchainFormik.setFieldValue("localCurrencyAmount", 0);
        blockchainFormik.setFieldValue("currencyAmount", 0);
      } else {
        blockchainFormik.setFieldValue("currencyAmount", amount);
        blockchainFormik.setFieldValue(
          "localCurrencyAmount",
          newValue.toFixed(selectedWallet?.coin?.decimal_place)
        );
      }
    }
  };

  const onBlockchainBackPress = () => {
    blockchainFormik.resetForm();

    if (selectedWallet?.coin?.supported_deposit_blockchains?.length === 1) {
      onSelectBlockchainBackPress();
      return;
    } else {
      setStep("selectBlockchain");
    }
  };
  const onSelectBlockchainBackPress = () => {
    if (selectedWallet?.coin?.withdraw_types.length === 1) {
      onSelectWithdrawMethodBackPress();
    } else {
      setStep("selectWithdrawType");
    }
  };

  const onSelectWithdrawMethodBackPress = () => {
    setStep(2);
  };

  let content = "";
  let title = "";
  console.log("step", step);
  switch (step) {
    case 1: {
      title = "Select Store";
      content = (
        <>
          <DialogContent sx={{ paddingTop: "5px !important" }}>
            <Stack spacing={2} direction={"column"} alignItems={"center"}>
              <TextField
                disabled={walletFetchingLoading}
                select
                fullWidth
                variant="outlined"
                className=" bg-white"
                id="store"
                name="store"
                InputLabelProps={textFieldLabelProps}
                label="Store"
                value={selectedStore}
                onChange={(event) => {
                  handleStorechange(event);
                }}
                onBlur={(event) => {
                  //   interacFormik.handleChange(event);
                }}
              >
                {storeOption.map((a, i) => (
                  <MenuItem value={a.store_id}>{a.name}</MenuItem>
                ))}
              </TextField>
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonSecondary>Close</ButtonSecondary>

            <ButtonPrimary
              onClick={() => {
                if (step === "interac") {
                  // interacSubmit();
                } else {
                  handleCloseModal();
                }
              }}
            >
              Next
            </ButtonPrimary>
          </DialogActions>
        </>
      );

      break;
    }

    case 2: {
      title = "Select Coin";
      content = (
        <>
          <DialogContent sx={{ position: "relative" }}>
            <Stack
              style={{
                paddingTop: "5px",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              }}
            >
              <TextField
                className="w-100 mb-3"
                required
                variant="outlined"
                name="search_coin"
                id="search_coin"
                InputLabelProps={textFieldLabelProps}
                label="Search Coin"
                type="text"
                value={coinSearchTerm}
                onChange={(e) => {
                  setCoinSearchTerm(e.target.value);
                }}
              />
            </Stack>

            <Stack
              direction={"row"}
              justifyContent={"center"}
              flexWrap={"wrap"}
              gap={1.5}
              paddingTop={"10px"}
            >
              {!walletFetchingLoading
                ? walletList
                    ?.filter((wallet) => {
                      console.log(wallet);
                      if (coinSearchTerm.trim() === "") {
                        return wallet;
                      }
                      return (
                        wallet?.coin?.symbol
                          ?.toLowerCase()
                          .includes(coinSearchTerm?.trim()?.toLowerCase()) ||
                        wallet?.coin?.name
                          ?.toLowerCase()
                          .includes(coinSearchTerm?.trim()?.toLowerCase())
                      );
                    })
                    .map((item) => (
                      <Stack
                        className="method-card"
                        direction={"row"}
                        gap={"1rem"}
                        onClick={() => handleSelectWallet(item)}
                        key={item?.id}
                      >
                        <Stack>
                          <img
                            src={item?.coin?.logo}
                            alt="deposit type"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "contain",
                            }}
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          direction={"row"}
                          width={"100%"}
                        >
                          <Stack justifyContent={"space-evenly"}>
                            <Typography variant="subtitle1" className="title">
                              {item?.coin?.name}
                            </Typography>
                            <Typography
                              variant="caption"
                              className="desc"
                              // color={statusColors?.colors?.complete}
                            >
                              {`1 ${
                                item?.coin?.symbol
                              } = ${item?.coin?.rate.toFixed(2)} ${
                                item?.currency?.code
                              }`}
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography
                              variant="subtitle2"
                              className="title"
                              color={statusColors?.colors?.complete}
                            >
                              {formatCurrency(
                                item?.balance,
                                item?.coin?.decimal_place,
                                item?.coin?.symbol,
                                item?.coin?.symbol_prefix
                              )}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    ))
                : new Array(5).fill(0).map((item, index) => {
                    return (
                      <Skeleton variant="rounded" width={"100%"} height={80} />
                    );
                  })}
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonSecondary
              onClick={() => {
                if (isSingleWallet) {
                  handleCloseModal();
                } else {
                  setStep((step) => step - 1);
                }
              }}
            >
              Back
            </ButtonSecondary>
          </DialogActions>
        </>
      );
      break;
    }
    case "selectWithdrawType": {
      title = "Select Withdraw Method";
      content = (
        <>
          <DialogContent sx={{ position: "relative" }}>
            <Stack
              style={{
                paddingTop: "5px",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              }}
            >
              <TextField
                className="w-100 mb-3"
                required
                variant="outlined"
                name="search_method"
                id="search_method"
                InputLabelProps={textFieldLabelProps}
                label="Search Withdraw Method"
                type="text"
                value={withdrawMethodSearchTerm}
                onChange={(e) => {
                  setWithdrawMethodSearchTerm(e.target.value);
                }}
              />
            </Stack>

            <Stack
              direction={"row"}
              justifyContent={"center"}
              flexWrap={"wrap"}
              gap={1.5}
              paddingTop={"10px"}
            >
              {!walletFetchingLoading
                ? selectedWallet?.coin?.withdraw_types
                    ?.filter((method) => {
                      console.log(method);
                      if (withdrawMethodSearchTerm.trim() === "") {
                        return method;
                      }
                      return method?.name
                        ?.toLowerCase()
                        .includes(
                          withdrawMethodSearchTerm?.trim()?.toLowerCase()
                        );
                    })
                    .map((item) => (
                      <Stack
                        className="method-card"
                        direction={"row"}
                        gap={"1rem"}
                        onClick={() => handleSelectWithdrawMethod(item)}
                        key={item?.id}
                      >
                        <Stack>
                          <img
                            src={item?.logo}
                            alt="deposit type"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "contain",
                            }}
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          direction={"row"}
                          width={"100%"}
                        >
                          <Stack justifyContent={"space-evenly"}>
                            <Typography variant="subtitle1" className="title">
                              {item?.name}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    ))
                : new Array(5).fill(0).map((item, index) => {
                    return (
                      <Skeleton variant="rounded" width={"100%"} height={80} />
                    );
                  })}
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonSecondary
              onClick={() => {
                // if (isSingleWallet) {
                //   handleCloseModal();
                // } else {
                onSelectWithdrawMethodBackPress();

                // }
              }}
            >
              Back
            </ButtonSecondary>
          </DialogActions>
        </>
      );
      break;
    }
    case "selectBlockchain": {
      title = "Select Blockchain";
      content = (
        <>
          <DialogContent sx={{ position: "relative" }}>
            <Stack
              style={{
                paddingTop: "5px",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              }}
            >
              <TextField
                className="w-100 mb-3"
                required
                variant="outlined"
                name="search_blockchai"
                id="search_blockchai"
                InputLabelProps={textFieldLabelProps}
                label="Search Blockchain"
                type="text"
                value={blockchainSearchTerm}
                onChange={(e) => {
                  setBlockchainSearchTerm(e.target.value);
                }}
              />
            </Stack>

            <Stack
              direction={"row"}
              justifyContent={"center"}
              flexWrap={"wrap"}
              gap={1.5}
              paddingTop={"10px"}
            >
              {!walletFetchingLoading
                ? selectedWallet?.coin?.supported_deposit_blockchains
                    ?.filter((blockchain) => {
                      console.log("pvineoirnewc", blockchain);
                      if (blockchainSearchTerm.trim() === "") {
                        return blockchain;
                      }
                      return (
                        blockchain?.name
                          ?.toLowerCase()
                          .includes(
                            blockchainSearchTerm?.trim()?.toLowerCase()
                          ) ||
                        blockchain?.test_symbol
                          ?.toLowerCase()
                          .includes(blockchainSearchTerm?.trim()?.toLowerCase())
                      );
                    })
                    .map((item) => (
                      <Stack
                        className="method-card"
                        direction={"row"}
                        gap={"1rem"}
                        onClick={() => handleSelectBlockchain(item)}
                        key={item?.id}
                      >
                        <Stack>
                          <img
                            src={item?.logo}
                            alt="deposit type"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "contain",
                            }}
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          direction={"row"}
                          width={"100%"}
                        >
                          <Stack justifyContent={"space-evenly"}>
                            <Typography variant="subtitle1" className="title">
                              {item?.name}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    ))
                : new Array(5).fill(0).map((item, index) => {
                    return (
                      <Skeleton variant="rounded" width={"100%"} height={80} />
                    );
                  })}
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonSecondary
              onClick={() => {
                onSelectBlockchainBackPress();
              }}
            >
              Back
            </ButtonSecondary>
          </DialogActions>
        </>
      );
      break;
    }
    case "blockchain": {
      title = <Stack direction={"column"}>Withdraw Funds</Stack>;
      content = (
        <>
          <DialogContent sx={{ paddingTop: "5px !important" }}>
            <Stack spacing={2} direction={"column"} alignItems={"center"}>
              <TextField
                className="w-100 mb-3"
                required
                variant="outlined"
                name="amount"
                id="amount"
                InputLabelProps={textFieldLabelProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {selectedWallet?.coin?.symbol}
                    </InputAdornment>
                  ),
                }}
                label="Withdraw Amount"
                type="text"
                value={blockchainFormik.values.currencyAmount}
                onChange={(e) => {
                  setEnteredAmountConvert(e.target.value, false);
                  blockchainFormik.handleChange(e);
                }}
                onBlur={blockchainFormik.handleBlur}
                error={
                  blockchainFormik.touched.currencyAmount &&
                  Boolean(blockchainFormik.errors.currencyAmount)
                }
                helperText={
                  blockchainFormik.touched.currencyAmount &&
                  blockchainFormik.errors.currencyAmount
                }
              />
              <IconButton
                sx={{
                  transform: "rotate(90deg)",
                  marginBottom: "1rem !important",
                }}
              >
                <SyncAltRoundedIcon fontSize="large" />
              </IconButton>
              <TextField
                className="w-100 mb-3"
                required
                variant="outlined"
                name="amount"
                id="amount"
                InputLabelProps={textFieldLabelProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {selectedWallet?.currency?.code}
                    </InputAdornment>
                  ),
                }}
                label="Withdraw Amount"
                type="text"
                value={blockchainFormik.values.localCurrencyAmount}
                onChange={(e) => {
                  setEnteredAmountConvert(e.target.value, true);
                  blockchainFormik.handleChange(e);
                }}
                onBlur={blockchainFormik.handleBlur}
                error={
                  blockchainFormik.touched.localCurrencyAmount &&
                  Boolean(blockchainFormik.errors.localCurrencyAmount)
                }
                helperText={
                  blockchainFormik.touched.localCurrencyAmount &&
                  blockchainFormik.errors.localCurrencyAmount
                }
              />
              <TextField
                className="w-100 mb-3"
                required
                variant="outlined"
                name="toAddress"
                id="toAddress"
                InputLabelProps={textFieldLabelProps}
                label="Destination Address"
                type="text"
                value={blockchainFormik.values.toAddress}
                onChange={blockchainFormik.handleChange}
                onBlur={blockchainFormik.handleBlur}
                error={
                  blockchainFormik.touched.toAddress &&
                  Boolean(blockchainFormik.errors.toAddress)
                }
                helperText={
                  blockchainFormik.touched.toAddress &&
                  blockchainFormik.errors.toAddress
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonSecondary
              disabled={verificationLoading}
              onClick={() => {
                onBlockchainBackPress();
              }}
            >
              Back
            </ButtonSecondary>
            <ButtonPrimary
              loading={verificationLoading}
              onClick={blockchainSubmit}
            >
              Withdraw
            </ButtonPrimary>
          </DialogActions>
        </>
      );
      break;
    }
    case "passphrase": {
      title = <Stack direction={"column"}>Enter Passphrase</Stack>;
      content = (
        <>
          <DialogContent sx={{ paddingTop: "5px !important" }}>
            <Stack spacing={2} direction={"column"} alignItems={"center"}>
              <TextField
                className="w-100 mb-3"
                style={{ width: "100%" }}
                required
                variant="outlined"
                name="passphrase"
                id="passphrase"
                type={showPassphrase ? "text" : "password"}
                InputLabelProps={textFieldLabelProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={toggleShowPassphrase}
                    >
                      {showPassphrase ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
                label="Passphrase"
                value={passphraseFormik.values.passphrase}
                onChange={passphraseFormik.handleChange}
                onBlur={passphraseFormik.handleBlur}
                error={
                  passphraseFormik.touched.passphrase &&
                  Boolean(passphraseFormik.errors.passphrase)
                }
                helperText={
                  passphraseFormik.touched.passphrase &&
                  passphraseFormik.errors.passphrase
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonSecondary
              disabled={createWithdrawLoading}
              onClick={() => {
                setStep("blockchain");
              }}
            >
              Back
            </ButtonSecondary>
            <ButtonPrimary
              loading={createWithdrawLoading}
              onClick={() => {
                blockchainPassphraseSubmit();
              }}
            >
              Request Withdraw
            </ButtonPrimary>
          </DialogActions>
        </>
      );
      break;
    }
    case "otp": {
      title = <Stack direction={"column"}>Enter OTP</Stack>;
      content = (
        <>
          <DialogContent sx={{ paddingTop: "5px !important" }}>
            <Stack spacing={2} direction={"column"} alignItems={"center"}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                containerStyle={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                inputStyle={{ width: "50px", height: "50px" }}
                numInputs={6}
                renderSeparator={() => <span> - </span>}
                renderInput={(props) => <input {...props} />}
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonSecondary
              disabled={createWithdrawLoading}
              onClick={() => {
                setStep("blockchain");
              }}
            >
              Back
            </ButtonSecondary>
            <ButtonPrimary
              loading={createWithdrawLoading || loading}
              disabled={createWithdrawLoading || loading || resendOtpLoading}
              onClick={() => {
                onOtpSubmit();
              }}
            >
              Verify
            </ButtonPrimary>
          </DialogActions>
          <DialogActions className="resend-otp-timer">
            {otpTimer > 0 ? <p>Resend OTP in {otpTimer}s</p> : null}
            <button
              disabled={otpTimer > 0 || resendOtpLoading}
              onClick={resendOTP}
              className="resend-otp-btn"
            >
              Resend OTP
            </button>
          </DialogActions>
        </>
      );
      break;
    }
    default: {
      content = "";
    }
  }

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={"xs"}
      onClose={handleCloseModal}
      scroll={"paper"}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "" }}>{title}</DialogTitle>
      {/* <Typography variant="subtitle1"></Typography> */}
      {content}
    </Dialog>
  );
};

function mapStateToProps({ authReducer }) {
  const { userPayString } = authReducer;
  return {
    userPayString,
  };
}

export default connect(mapStateToProps, null)(WithdrawModal);
