import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "rsuite";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Chip } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Popover from "@mui/material/Popover";

import { startCase } from "lodash";

import edit from "../../../../../assets/img/icons/edit.svg";
import greentick from "../../../../../assets/img/icons/greentick.svg";
import cross from "../../../../../assets/img/icons/cross.svg";

import { merchantApi } from "../../../../../redux/api/apiConfig";
import {
  RESENT_ACCOUNT_ACTIVATION_EMAIL,
  USER_LIST,
} from "../../../../../ApiUrl";

const UserTables = ({
  users: propUsers = { count: 0, results: [] },
  pageChange,
  userStores,
  fetchUsersLoading,
}) => {
  const [users, setUsers] = useState({});
  const [message, setMessage] = useState("No data to show.");
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState("");
  const tempRole = useSelector((state) => state.authReducer.userRole);
  const userProfile = useSelector((state) => state.profileReducer.user);
  // const userStores = useSelector((state) => state.storeReducer.storelist);
  // const getAllUsers = async () => {
  //   return merchantApi.get(`${USER_LIST}?limit=${propUsers.count}`);
  // };

  const history = useHistory();

  useEffect(() => {
    if (propUsers?.results?.length > 0) {
      // getAllUsers().then((res) => {
      let resUsers = propUsers;

      let filteredArray = resUsers.results;

      if (pageChange.userrole !== "") {
        filteredArray = filteredArray.filter(
          (user) => user.user_role === pageChange.userrole
        );
      }

      if (pageChange.search !== "") {
        filteredArray = filteredArray.filter(
          (user) =>
            user.first_name
              .toLowerCase()
              .includes(pageChange.search.toLowerCase()) ||
            user.last_name
              .toLowerCase()
              .includes(pageChange.search.toLowerCase()) ||
            user.email
              .toLowerCase()
              .includes(pageChange.search.toLowerCase()) ||
            user.phone_number
              .toString()
              .includes(pageChange.search.toLowerCase()) ||
            user.user_role
              .toString()
              .toLowerCase()
              .includes(pageChange.search.toLowerCase())
        );
      }

      if (filteredArray.length === 0) {
        setMessage("No items match the filters.");
      } else {
        setMessage("No data to show.");
      }

      resUsers = { ...resUsers, results: filteredArray };

      if (tempRole === "manager") {
        // To show users of only that store where the current user is manager.
        // Assuming that one manager can be only assigned one store, therefore selecting first store from array, array has only one store
        let curStore = userStores[0];
        let storeUsers = resUsers?.results?.filter(
          (user) => user.store === curStore.id
        );

        setUsers({
          ...resUsers,
          count: 1 + storeUsers?.length,
          results:
            pageChange.userrole === "manager"
              ? [userProfile].concat(storeUsers)
              : [].concat(storeUsers),
        });
      } else {
        setUsers({ ...resUsers });
      }
      // });
    }
  }, [propUsers.count, pageChange]);

  const resendEmail = (id) => {
    merchantApi
      .get(`${RESENT_ACCOUNT_ACTIVATION_EMAIL}${id}/`)
      .then((response) => {
        const { data } = response;
        toast.success(data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message || "Something went wrong");
      });
  };

  // DataGrid components

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {message}
      </Stack>
    );
  };

  const createDataRow = (user) => {
    return {
      id: user.id,
      name: startCase(user.first_name + " " + user.last_name),
      email: user.email,
      userLevel: user.user_role,
      userStore: startCase(
        userStores.filter((store) => store.id === user.store)[0]?.name ?? "N/A"
      ),
      userCounter: user?.counter?.name,
      phoneNumber: user.phone_number,
      status: user.status,
      action: "Edit User",
      emailStatus: user.email_verified ? "Verified" : "Verify email",
    };
  };

  const rows =
    users?.results?.length > 0
      ? users.results.map((user) => createDataRow(user))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 80,
      // maxWidth: 80,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <p style={{ color: "black" }}>{params.formattedValue}</p>;
      },
      // align: 'center',
    },
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      flex: 1,
      minWidth: 140,
      // maxWidth: 250,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={{ color: "black" }}>{params.formattedValue}</p>;
      },
      // align: 'center',
      // headerAlign: 'center',
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      type: "email",
      flex: 2,
      minWidth: 170,
      headerClassName: "header",
      // align: 'center',
    },
    {
      field: "userLevel",
      headerName: "User Level",
      sortable: false,
      flex: 1,
      minWidth: 120,
      // maxWidth: 140,
      headerClassName: "header",
      // align: 'center',
    },
    {
      field: "userStore",
      headerName: "User Store",
      flex: 1,
      minWidth: 120,
      // maxWidth: 120,
      headerClassName: "header",
      // align: 'center',
    },
    {
      field: "userCounter",
      headerName: "User Counter",
      flex: 1,
      minWidth: 120,
      // maxWidth: 120,
      headerClassName: "header",
      // align: 'center',
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      sortable: false,
      flex: 1,
      minWidth: 140,
      // maxWidth: 200,
      headerClassName: "header",
      // align: 'center',
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1,
      minWidth: 140,
      // maxWidth: 150,
      headerClassName: "header",
      renderCell: (params) => {
        return params.formattedValue === "Active" ? (
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#ECFDF3",
              color: "#027A48",
            }}
            avatar={
              <img
                src={greentick}
                style={{ width: "13px", marginLeft: "10px" }}
                alt="tick"
              />
            }
            label="Active"
            color="success"
          />
        ) : (
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#f8dada",
              color: "#B42318",
            }}
            avatar={
              <img
                src={cross}
                style={{ width: "13px", marginLeft: "10px" }}
                alt="tick"
              />
            }
            color="error"
            label="Suspended"
          />
        );
      },
    },
    {
      flex: 1,
      field: "action",
      headerName: "Action",
      sortable: false,
      minWidth: 120,
      // maxWidth: 150,
      headerClassName: "header",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          history.push("/administration/userApproval/" + params.id);
        };

        return (
          <Button
            onClick={onClick}
            style={{ background: "none" }}
            className="d-flex align-items-center gap-2"
          >
            <img src={edit} alt="edit" />
            <span style={{ color: "black" }}>Edit</span>
          </Button>
        );
      },
    },
    {
      flex: 1,
      field: "emailStatus",
      headerName: "Email Status",
      minWidth: 140,
      // maxWidth: 150,
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          resendEmail(params.id);
        };

        return params.formattedValue === "Verified" ? (
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#ECFDF3",
              color: "#027A48",
            }}
            avatar={
              <img
                src={greentick}
                style={{ width: "13px", marginLeft: "10px" }}
                alt="tick"
              />
            }
            label="Verified"
            color="success"
            variant="filled"
          />
        ) : (
          <Button
            onClick={onClick}
            style={{ background: "none" }}
            className="d-flex align-items-center gap-2"
          >
            <p style={{ color: "black" }}>Verify Again</p>
          </Button>
        );
      },
    },
  ];

  const handlePopoverOpen = (event) => {
    const field = event.currentTarget.dataset.field;
    const id = event.currentTarget.parentElement.dataset.id;
    const row = rows.find((r) => r.id == id);
    setValue(row[field]);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div>
      <Box
        sx={{
          height: 615,
          width: 1,
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          sx={{
            borderRadius: "8px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          }}
          rows={rows}
          columns={columns}
          componentsProps={{
            cell: {
              onMouseEnter: handlePopoverOpen,
              onMouseLeave: handlePopoverClose,
            },
          }}
          components={{ NoRowsOverlay, LoadingOverlay: LinearProgress }}
          loading={fetchUsersLoading}
          rowHeight={72}
          pageSize={7}
          // rowsPerPageOptions={[5]}
          disableColumnFilter={true}
          disableColumnMenu={true}
          // checkboxSelection
        />
        {/* <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1, borderRadius: '10px' }}>{value}</Typography>
        </Popover> */}
      </Box>
    </div>
  );
};

export default UserTables;
