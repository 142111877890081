import React, { useState, useEffect, useRef } from "react";
import { Form, InputGroup, Col } from "@themesberg/react-bootstrap";
import { Loader, Button, DateRangePicker } from "rsuite";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import moment from "moment-timezone";
import axios from "axios";
import excelimg from "../../../assets/img/excel.webp";
import { toast } from "react-toastify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import "rsuite/dist/styles/rsuite-default.css";

import * as API from "../../../ApiUrl";
import TransactiontableReports from "./TransactiontableReports";
import { merchantApi } from "../../../redux/api/apiConfig";
import reload from "../../../assets/img/icons/reload.svg";
import searchIcon from "../../../assets/img/icons/searchIcon.svg";
import dropDown from "../../../assets/img/icons/dropDown.svg";
import { useLocation, useHistory } from "react-router-dom";
import {
  format,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from "date-fns";

const reportTimePeriods = [
  {
    name: "Time Period",
    from: "",
    to: "",
  },
  {
    name: "This Week",
    from: format(startOfWeek(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
  {
    name: "This Month",
    from: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
  {
    name: "This Quarter",
    from: format(startOfQuarter(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
  {
    name: "This Year",
    from: format(startOfYear(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
];

function Transactions() {
  const [statusOptions, setStatusOptions] = React.useState([
    { name: "Select Status", value: "" },
  ]);
  const [storeOption, setStoreOption] = React.useState([
    { name: "Select Store", id: "" },
  ]);
  const [CoinOption, setCoinOption] = React.useState([
    { name: "Select Coin", id: "" },
  ]);
  const [counterOption, setCounterOption] = React.useState([
    { name: "Select Counter", id: "" },
  ]);
  const [statusTitle, setStatusTitle] = React.useState("Select Status");
  const [translist, setTranslist] = useState([]);
  const [loading, setloading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [coinTitle, setcoinTitle] = useState("Select Coin");
  const [storeTitle, setStoreTitle] = useState("Select Store");
  const [counterTitle, setCounterTitle] = useState("Select Counter");
  const [tabValue, setTabValue] = useState("");
  const [reportTimePeriodTitle, setReportTimePeriodTitle] =
    useState("Time Period");
  const [totals, setTotals] = useState(null);

  const [paginateTable, setpaginateTable] = useState({
    currentPage: 0,
    pageSize: 10,
    search: "",
    type: "",
    coin: "",
    store: "",
    status: "",
    fromDate: "",
    toDate: "",
    counter: "",
  });

  const [calenderValue, setCalenderValue] = useState([]);

  const location = useLocation();
  const history = useHistory();

  let token = localStorage.getItem("token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    if (location?.state?.tab) {
      setTabValue(location.state.tab);
      console.log(location.state.tab);
      setpaginateTable({
        ...paginateTable,
        currentPage: 0,
        type: location.state.tab,
      });
    }
  }, []);

  const getCoinType = async (controller) => {
    try {
      const response = await axios.get(`${API.COIN}`, {
        // signal: controller.signal,
      });
      setCoinOption([...CoinOption, ...response.data.data]);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const getCounterType = async () => {
    try {
      const response = await axios.get(`${API.COUNTER}`, config);
      setCounterOption([...counterOption, ...response.data.results]);
    } catch (error) {}
  };

  const getStoreList = async () => {
    try {
      const response = await axios.get(`${API.TRANS_STORE}`, config);
      setStoreOption([...storeOption, ...response.data.data]);
    } catch (error) {}
  };

  const getStatusOption = async () => {
    try {
      const response = await axios.get(`${API.TRANS_STATUS}`, config);
      setStatusOptions([...statusOptions, ...response.data.data]);
    } catch (error) {}
  };

  const getTrans = async (signal) => {
    setloading(true);
    try {
      const response = await axios.get(`${API.TRANSACTION_LIST}`, {
        params: {
          offset: paginateTable.currentPage * paginateTable.pageSize,
          limit: paginateTable.pageSize,
          search: paginateTable.search,
          type: paginateTable.type,
          coin: paginateTable.coin,
          store: paginateTable.store,
          status: paginateTable.status,
          modified_date_after: paginateTable.fromDate,
          modified_date_before: paginateTable.toDate,
          counter: paginateTable?.counter,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        signal,
      });

      if (response.data) {
        setTotals(response.data.total);
        setTranslist(response.data);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCoinType();
    getStoreList();
    getStatusOption();
    getCounterType();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getTrans(signal);
    return () => {
      controller.abort();
    };
  }, [
    paginateTable.currentPage,
    paginateTable.pageSize,
    paginateTable.search,
    paginateTable.type,
    paginateTable.coin,
    paginateTable.store,
    paginateTable.status,
    paginateTable.fromDate,
    paginateTable.toDate,
    paginateTable?.counter,
  ]);

  const handleCoinchange = (e) => {
    let l = CoinOption.filter((a) => a.symbol === e);

    console.log(l[0].id);
    setcoinTitle(l[0].name);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      coin: l[0].id,
    });
  };

  const handleStorechange = (e) => {
    let l = storeOption.filter((a) => a.id === e);
    setStoreTitle(l[0].name);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      store: l[0].id,
    });
  };

  const handleCounterChange = (e) => {
    let l = counterOption.filter((a) => a.id === e);
    setCounterTitle(l[0].name);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      counter: l[0].id,
    });
  };

  const handleStatusChange = (value) => {
    let l = statusOptions.filter((a) => a.value === value);

    setStatusTitle(l[0].name);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      status: value,
    });
  };

  const handleSearch = (value) => {
    // e.preventDefault();
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      search: value,
    });
  };

  const handleClearFilters = () => {
    setStatusTitle("Status");
    setStoreTitle("Store");
    setcoinTitle("Coin");
    setCounterTitle("Counter");
    setpaginateTable({
      currentPage: 0,
      pageSize: 7,
      search: "",
      type: "",
      coin: "",
      store: "",
      status: "",
      fromDate: "",
      toDate: "",
      counter: "",
    });
    setCalenderValue([]);
    setReportTimePeriodTitle("Time Period");
  };

  const reportTimePeriodChangeHandler = (from, to, name) => {
    // const periodData = timePeriodOptions.filter(
    //   (option) => option.value === period
    // );
    console.log(from, to);
    // console.log(new Date(from))
    setCalenderValue(
      name === "Time Period" ? [] : [new Date(from), new Date(to)]
    );
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      fromDate: from,
      toDate: to,
    });
    setReportTimePeriodTitle(name);
  };

  const handleDateFilter = (value) => {
    if (value.length > 1) {
      setpaginateTable({
        ...paginateTable,
        currentPage: 0,
        fromDate: format(value[0], "yyyy-MM-dd"),
        toDate: format(value[1], "yyyy-MM-dd"),
      });
    } else {
      setpaginateTable({
        ...paginateTable,
        currentPage: 0,
        fromDate: "",
        toDate: "",
      });
    }
  };

  const handleExportExcel = async () => {
    setExportLoading(true);
    try {
      const response = await axios.get(API.EXPORT_EXCEL, {
        params: {
          search: paginateTable.search,
          type: paginateTable.type,
          coin: paginateTable.coin,
          store: paginateTable.store,
          status: paginateTable.status,
          modified_date_after: paginateTable.fromDate,
          modified_date_before: paginateTable.toDate,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      window.location.href = response.data.data;
      setExportLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong");
      setExportLoading(false);
    }
  };

  const handleTabChange = (e, value) => {
    setTabValue(value);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      type: value,
    });
  };

  return (
    <>
      <div className="page-wrapper stb-page-wrapper stb-ph-8">
        <div className="tabing stb-h-100">
          <div className="reports stb-h-100 bg-white p-xl-4 radi">
            <div className="d-flex flex-col align-items-start gap-4">
              <div className="d-flex space-between w-100 flex-sm-row flex-column gap-2">
                <div>
                  <h3 className="m-0 page-title">All Transactions</h3>
                  <p>View your transactions</p>
                </div>
                <div>
                  <Button
                    onClick={handleExportExcel}
                    style={{ minHeight: "40px" }}
                    loading={exportLoading}
                    appearance="default"
                    className="btn load text-white d-flex flex-row gap-2"
                  >
                    <img
                      alt="export to excel"
                      style={{ width: "20px" }}
                      src={excelimg}
                    />
                    Export to Excel
                  </Button>
                </div>
              </div>
              <div className="w-100">
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#0b0523",
                    },
                  }}
                  value={tabValue}
                  onChange={handleTabChange}
                >
                  <Tab
                    value=""
                    style={{ color: `${tabValue === "" ? "#0b0523" : ""}` }}
                    label="All"
                  />
                  {/* <Tab
                    value="transfer"
                    style={{
                      color: `${tabValue === 'transfer' ? '#0b0523' : ''}`,
                    }}
                    label="Transfer"
                  /> */}
                  <Tab
                    value="receipt"
                    style={{
                      color: `${tabValue === "receipt" ? "#0b0523" : ""}`,
                    }}
                    label="Receipt"
                  />
                  <Tab
                    value="withdraw"
                    style={{
                      color: `${tabValue === "withdraw" ? "#0b0523" : ""}`,
                    }}
                    label="Withdraw"
                  />
                  <Tab
                    value="refund"
                    style={{
                      color: `${tabValue === "refund" ? "#0b0523" : ""}`,
                    }}
                    label="Refund"
                  />
                </Tabs>
              </div>
              <div className="w-100 d-flex align-items-start space-between gap-2 p-3 mb-3 filters">
                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {reportTimePeriodTitle || "Select Period"}
                      <img
                        src={dropDown}
                        style={{ marginLeft: "4px" }}
                        alt="drop-down"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {reportTimePeriods.map((period, i) => (
                        <Dropdown.Item
                          as="button"
                          value={period.name}
                          onClick={() => {
                            reportTimePeriodChangeHandler(
                              period.from,
                              period.to,
                              period.name
                            );
                          }}
                          key={i + "res"}
                        >
                          {period.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {storeTitle || "Select Store"}
                      <img
                        src={dropDown}
                        style={{ marginLeft: "4px" }}
                        alt="drop-down"
                        className="dropdown-icon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {storeOption.map((a, i) => (
                        <Dropdown.Item
                          as="button"
                          value={a.id}
                          onClick={() => {
                            handleStorechange(a.id);
                          }}
                          key={i + "res"}
                        >
                          {a.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                </div>

                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {counterTitle || "Select Counter"}
                      <img
                        src={dropDown}
                        style={{ marginLeft: "4px" }}
                        alt="drop-down"
                        className="dropdown-icon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {counterOption.map((a, i) => (
                        <Dropdown.Item
                          as="button"
                          value={a.id}
                          onClick={() => {
                            handleCounterChange(a.id);
                          }}
                          key={i + "res"}
                        >
                          {a.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                </div>
               

                <div className="filter">
                  <Form
                    onSubmit={(e) => e.preventDefault()}
                    className="w-100 search"
                  >
                    <Form.Group>
                      <InputGroup>
                        <InputGroup.Text>
                          <img src={searchIcon} alt="search" />
                        </InputGroup.Text>
                        <Form.Control
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                          type="text"
                          placeholder="Search"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form>
                </div>
                <div className="filter">
                  <DateRangePicker
                    cleanable
                    placeholder="Filter Date"
                    className="w-100 dateInput"
                    size="lg"
                    style={{ height: "40px" }}
                    onOk={handleDateFilter}
                    value={calenderValue}
                    placement="auto"
                    showOneCalendar={true}
                    ranges={[
                      {
                        label: "clear",
                        value: [1, 1],
                      },
                    ]}
                    onChange={(value) => {
                      if (value[0] === 1 && value[1] === 1) {
                        setCalenderValue([]);
                      } else {
                        setCalenderValue(value);
                      }
                    }}
                    onClean={() => {
                      reportTimePeriodChangeHandler(
                        reportTimePeriods[0].from,
                        reportTimePeriods[0].to,
                        reportTimePeriods[0].name
                      );
                    }}
                  />
                </div>

                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {statusTitle || "Select Status"}
                      <img
                        src={dropDown}
                        style={{ marginLeft: "4px" }}
                        alt="drop-down"
                        className="dropdown-icon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {statusOptions.map((a, i) => (
                        <Dropdown.Item
                          as="button"
                          value={a.value}
                          onClick={() => {
                            handleStatusChange(a.value);
                          }}
                          key={i + "res"}
                        >
                          {a.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                </div>

                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {coinTitle || "Select Coin"}
                      <img
                        src={dropDown}
                        style={{ marginLeft: "4px" }}
                        alt="drop-down"
                        className="dropdown-icon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {CoinOption.map((a, i) => (
                        <Dropdown.Item
                          as="button"
                          value={a.symbol}
                          onClick={() => {
                            handleCoinchange(a.symbol);
                          }}
                          key={i + "res"}
                        >
                          {a.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                </div>

             
                <div className="filter" style={{ flexGrow: "0" }}>
                  <Button
                    className=" w-xs-100  text-dark w-100"
                    style={{
                      background: "none",
                      border: "none",
                      width: "100%",
                      boxShadow: "none",
                    }}
                    onClick={handleClearFilters}
                  >
                    Reset Filters
                    <img
                      src={reload}
                      style={{ marginLeft: "7px" }}
                      alt="reset filter"
                    />
                  </Button>
                </div>
              </div>
            </div>
            <div className="totals-container mb-3">
              <div className="totals">
                <div className="total">
                  <p>Fund: {`  `}</p>
                  <h3>
                    {totals?.fund} {translist?.currency}
                  </h3>
                </div>
                <div className="total">
                  <p>Receipt:</p>
                  <h3>
                    {totals?.receipt} {translist?.currency}
                  </h3>
                </div>
              </div>
              <div className="totals">
                <div className="total">
                  <p>Refund:</p>
                  <h3>
                    {totals?.refund} {translist?.currency}
                  </h3>
                </div>
                <div className="total">
                  <p>Withdraw:</p>
                  <h3>
                    {totals?.withdraw} {translist?.currency}
                  </h3>
                </div>
              </div>
            </div>
            <div>
              <Col xs={12} xl={12} className="mb-4">
                <TransactiontableReports
                  translist={translist}
                  setpaginateTable={setpaginateTable}
                  paginateTable={paginateTable}
                  loading={loading}
                />
              </Col>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Transactions;
