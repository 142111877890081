import pbkdf2 from 'pbkdf2';
import sha256 from 'crypto-js/sha256'; // CryptoJS library for SHA-256
import encHex from 'crypto-js/enc-hex';
import wordList from './words.json';

const bytesToBinary = (bytes) => {
  return bytes.reduce(
    (prev, curr) => prev.concat(lpad(curr.toString(2), '0', 8)),
    '',
  );
};

const lpad = (str, padString, length) => {
  while (str.length < length) {
    str = padString + str;
  }
  return str;
};

const salt = (password) =>
  'mnemonic' + (password.normalize('NFKD') || '');

const checksumBits = async (entropyBuffer) => {
  const hash = sha256(entropyBuffer);
  const res = hash.toString(encHex);

  // Calculated constants from BIP39
  const ENT = entropyBuffer.length * 8;
  const CS = ENT / 32;

  return res.slice(0, CS);
};

const wordsToSeed = async (
  mnemonic,
  password,
) => {
  const mnemonicBuffer = Buffer.from(mnemonic, 'utf8');
  const saltBuffer = Buffer.from(salt(password), 'utf8');
  return new Promise((resolve, reject) => {
    pbkdf2.pbkdf2(mnemonicBuffer, saltBuffer, 2048, 64, 'sha512', (err, derivedKey) => {
      if (err) {
        reject(err);
      } else {
        resolve(derivedKey);
      }
    });
  });
};

const entropyToWords = async (
  entropy,
  wordlist = wordList,
) => {
  const entropyBuffer = Buffer.from(entropy, 'hex');
  const entropyBits = bytesToBinary([].slice.call(entropyBuffer));

  const checksum = await checksumBits(entropyBuffer);

  const bits = entropyBits + checksum;
  const chunks = bits.match(/(.{1,11})/g);

  return (chunks ?? []).map(binary => wordlist[parseInt(binary, 2)]);
};

export const wordsToSeedHex = async (
  mnemonic,
  password,
) => {
  const seed = await wordsToSeed(mnemonic, password);
  return seed.toString('hex');
};

export const generateWords = async (
  strength = 128,
  wordlist = wordList,
) => {
  const bytes = new Uint8Array(strength / 8);
  window.crypto.getRandomValues(bytes);
  const hexBuffer = Array.from(bytes).map(byte => byte.toString(16).padStart(2, '0')).join('');

  return entropyToWords(hexBuffer, wordlist);
};
