import * as actionTypes from '../actionTypes';
import { updateObject } from './utility';

const initialState = {
  loading: false,
  user: '',
  error: null,
  type: null,
};

const updateProfileFail = (action, state) => {
  return updateObject(state, {
    error: 'error',
  });
};
const updateStart = (action, state) => {
  return updateObject(state, {
    loading: true,
  });
};

const setUpdateprofile = (action, state) => {
  return updateObject(state, {
    user: action.payload,
    loading: false,
  });
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PROFILE:
      return updateStart(action, state);
    case actionTypes.SET_PROFILE:
      console.log(action);
      localStorage.setItem('user', JSON.stringify(action.payload));

      return setUpdateprofile(action, state);
    case actionTypes.GET_PROFILE_FAIL:
      return updateProfileFail(action, state);

    default:
      return state;
  }
};

export default profileReducer;
