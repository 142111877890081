import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import { Col, Row } from "@themesberg/react-bootstrap";
import * as yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import OtpInput from "react-otp-input";


function WithdrawOtpDialog({
  resendOTP,
  otpModal,
  onClose: handleOTPClose,
  title,
  loading = false,
  otpLoading = false,
  handleFinalSubmit,
}) {
  const [otp, setOtp] = useState("");
  const [otpTimer, setOtpTimer] = useState(15);

  const onSubmit = async () => {
    if(otp.length < 6) {
      toast.error("Please enter valid OTP");
      return
    }
    handleFinalSubmit({otp});
  };

  useEffect(() => {
    let timer;
    if (otpTimer !== 0) {
      timer = setInterval(() => {
        setOtpTimer(otpTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [otpTimer]);


  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    handleOTPClose();
  };

  return (
    <Dialog
      open={otpModal}
      maxWidth={"sm"}
      fullWidth
      disableEscapeKeyDown={true}
      onClose={handleCloseModal}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
          width: "480px",
          minHeight: "216px",
          maxHeight: "80vh",
        },
      }}
    >
      <DialogTitle className="text-center">
        <p className="dialog-title"> {title}</p>
      </DialogTitle>
      <hr className="hr" />

      <DialogContent className="mt-3">
        <Row>
            <OtpInput
              value={otp}
              onChange={setOtp}
              containerStyle={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              inputStyle={{ width: "50px", height: "50px" }}
              numInputs={6}
              renderSeparator={() => <span> - </span>}
              renderInput={(props) => <input {...props} />}
            />
        </Row>
      </DialogContent>
      <hr className="hr" />
      <DialogActions className="footer-content">
        <Button
          onClick={handleOTPClose}
          appearance="subtle"
          className="btn white-btn action-button basis-30"
        >
          Cancel
        </Button>
        <Button
          className={`btn action-button primary_color text-white basis-70 ${
            loading ? "disabled" : null
          }`}
          appearance="primary"
          onClick={onSubmit}
          loading={loading}
          disabled={loading}
        >
          {!loading ? "Submit" : "Loading..."}
        </Button>
      </DialogActions>
      <DialogActions className="resend-otp-timer">
        {otpTimer > 0 ? <p>Resend OTP in {otpTimer}s</p> : null}
        <button
          disabled={otpTimer > 0 || otpLoading}
          onClick={resendOTP}
          className="resend-otp-btn"
        >
          Resend OTP
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default WithdrawOtpDialog;
