import React, { useState, useEffect } from 'react';
import { WalletTable as WalletData } from '../WalletTable';
// import { UserData } from "../../../components/Tables";
import 'rsuite/dist/styles/rsuite-default.css';
import { Col, Button } from '@themesberg/react-bootstrap';
import { useLocation } from 'react-router-dom';
import * as API from '../../../ApiUrl';

import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import reload from '../../../assets/img/icons/reload.svg';

import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Form, InputGroup } from '@themesberg/react-bootstrap';

import searchIcon from '../../../assets/img/icons/searchIcon.svg';
import dropDown from '../../../assets/img/icons/dropDown.svg';

// import  Pagination  from './Pagination';
// import _ from "lodash";
// import Pagination from '@mui/material/Pagination';

const WalletMenu = () => {
  const [walletList, setWalletList] = useState({ count: 0, results: [] });
  const [coinTitle, setcoinTitle] = useState('Select Coin');
  const [storeTitle, setStoreTitle] = useState('Select Store');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [storeOption, setStoreOption] = React.useState([
    { name: 'Select Store', id: '' },
  ]);
  const [CoinOption, setCoinOption] = React.useState([
    { name: 'Select Coin', id: '' },
  ]);
  const [random, setRandom] = useState();

  // This function is to fetch updated wallet list, after the trustline is set, this function gets called
  // Which leads to useEffect bring called because of setRandom.
  const handleCallBack = () => {
    setRandom(Math.random());
  };

  const [pageChange, setPageChange] = useState({
    search: '',
    offset: 0,
    limit: 1000,
    currentPage: 0,
    userrole: '',
    pageSize: 5,
    coin: '',
    store: '',
  });

  const location = useLocation();
  const userStores = useSelector((state) => state.storeReducer.storelist);

  const isSingleWallet = useSelector(
    (state) => state.authReducer.isSingleWallet
  );

  const handleClearFilter = () => {
    setcoinTitle('Select Coin');
    setStoreTitle('Select Store');
    setSearchTerm('');

    setPageChange({
      ...pageChange,
      search: '',
      coin: '',
      store: '',
    });
  };

  const getWalletMenu = async () => {
    try {
      let token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      const response = await axios.get(
        `${API.GET_MERCHENT_WALLET_LIST}?limit=1000`,
        config
      );

      console.log(response);
      if (response.data) {
        setWalletList(response.data);
        setLoading(false);
      }
    } catch (error) {
      setWalletList({ count: 0, results: [] });
      setLoading(false);
      toast.error('Something Went Wrong');
    }
  };

  const getCoinType = async () => {
    try {
      const response = await axios.get(`${API.COIN}`);
      setCoinOption([...CoinOption, ...response.data.data]);
    } catch (error) {}
  };

  const getStoreList = () => {
    setStoreOption([...storeOption, ...userStores]);
  };

  useEffect(() => {
    getCoinType();
    getStoreList();
  }, []);

  useEffect(() => {
    if (location.state !== undefined && storeOption.length > 1) {
      const filteredStore = storeOption.filter(
        (item) => item.store_id === location.state.store_id
      );
      if (filteredStore.length > 0) {
        setPageChange({ ...pageChange, store: filteredStore[0].id });
        setStoreTitle(filteredStore[0].name);
        // setStoreValue(filteredStore[0].id);
      }
    }
  }, [location, storeOption]);

  useEffect(() => {
    getWalletMenu();
  }, [random]);

  const handlePageChange = (p) => {
    setPageChange({
      ...p,
      search: pageChange.search,
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e);
    setPageChange({
      ...pageChange,
      search: e,
      currentPage: 0,
    });
  };

  const handleCoinchange = (e) => {
    let l = CoinOption.filter((a) => a.symbol === e);
    setcoinTitle(l[0].name);
    // setCoinValue(l[0].id);
    setPageChange({
      ...pageChange,
      coin: l[0].id,
    });
  };

  const handleStorechange = (storeId) => {
    let store = storeOption.filter((store) => store.id === storeId);
    setStoreTitle(store[0].name);
    // setStoreValue(store[0].id);
    setPageChange({
      ...pageChange,
      store: store[0].id,
    });
  };

  return (
    <div className="page-wrapper stb-page-wrapper stb-ph-8">
      <div className="user stb-h-100">
        <div className="users stb-h-100 bg-white p-4 radi">
          <div className="page-header pb-4 space-between">
            <div className="col-lg-2">
              <h3 className="m-0 page-title">Wallets</h3>
              <p>View your wallets</p>
            </div>

            <div className="gap-4 tools">
              <div className="filter">
                <Form  onSubmit={(e) => e.preventDefault()} className="w-100 search">
                  <Form.Group>
                    <InputGroup>
                      <InputGroup.Text>
                        <img src={searchIcon} alt="search" />
                      </InputGroup.Text>
                      <Form.Control
                        value={searchTerm}
                        onChange={(e) => {
                          handleSearch(e.target.value);
                        }}
                        type="text"
                        placeholder="Search"
                      />
                    </InputGroup>
                  </Form.Group>
                </Form>
              </div>
              <div className="filter">
                <Dropdown as={ButtonGroup} className="w-100">
                  <Dropdown.Toggle id="dropdown-basic">
                    {coinTitle || 'Select Coin'}
                    <img
                      src={dropDown}
                      style={{ marginLeft: '4px' }}
                      alt="drop-down"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors">
                    {CoinOption.map((a, i) => (
                      <Dropdown.Item
                        as="button"
                        value={a.symbol}
                        onClick={() => {
                          handleCoinchange(a.symbol);
                        }}
                        key={i + 'res'}
                      >
                        {a.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>{' '}
              </div>

              {!isSingleWallet && (
                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {storeTitle || 'Select Store'}
                      <img
                        src={dropDown}
                        style={{ marginLeft: '4px' }}
                        alt="drop-down"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {storeOption.map((a, i) => (
                        <Dropdown.Item
                          as="button"
                          value={a.id}
                          onClick={() => {
                            handleStorechange(a.id);
                          }}
                          key={i + 'res'}
                        >
                          {a.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>{' '}
                </div>
              )}

              <div className="filter">
                <Button
                  className=" w-xs-100  text-dark w-100"
                  style={{
                    background: 'none',
                    border: 'none',
                    width: '100%',
                    boxShadow: 'none',
                  }}
                  onClick={handleClearFilter}
                >
                  Reset Filters
                  <img
                    src={reload}
                    style={{ marginLeft: '7px' }}
                    alt="reset filter"
                  />
                </Button>
              </div>
            </div>
          </div>

          <Col xs={12} xl={12} className="mb-4">
            <WalletData
              loading={loading}
              walletList={walletList}
              setpageChange={handlePageChange}
              paginationdetails={pageChange}
              handleCallBack={handleCallBack}
              isSingleWallet={isSingleWallet}
            />
          </Col>

          <div className="button d-flex align-items-center justify-content-center"></div>
        </div>
      </div>
    </div>
  );
};

export default WalletMenu;
