import React from 'react'
// import TextField from '@material-ui/core/TextField';
import { Button,  ButtonToolbar } from 'rsuite';
import { Col } from '@themesberg/react-bootstrap';

const AddCustomer = () => {
    
    return (
      
  
       <div>
       
        <div className="col-lg-12">
            <div class="form-floating mb-2 ">
                <input type="text" class="form-control radi light_bg " value="Wilferd Cortez"  placeholder="Name" />
                <label for="floatingUser">Customer Name</label>
            </div>
        </div>
        <div className="col-lg-12">
            <div class="form-floating mb-2 mt-4">
                <input type="email" class="form-control radi light_bg "  placeholder="Name" />
                <label for="floatingUser">Email</label>
            </div>
        </div>
        <div className="col-lg-12">
            <div class="form-floating mb-2 mt-4">
                <input type="text" class="form-control radi light_bg "   placeholder="Name" />
                <label for="floatingUser">Contact No</label>
            </div>
        </div>
        <div className="col-lg-12">
            <div class="form-floating mb-2 mt-4">
                <textarea class="form-control radi light_bg  "   placeholder="Address"  ></textarea>
                <label for="floatingUser">Address</label>
            </div>
        </div>
        <div className="col-lg-12">
            <div class="form-floating mb-2 mt-4">
                <input type="text" class="form-control radi light_bg "   placeholder="eg 22AAAAA0000A1Z5" />
                <label for="floatingUser">GSTIN</label>
            </div>
        </div>
        <Col xs={12} xl={12}>
        <ButtonToolbar className="d-flex justify-content-center mt-5">
    <Button appearance="default" className="rounded-pill w-50">Cancel</Button>
    <Button appearance="subtle" className="primary_color text-white rounded-pill w-50">Save</Button>
    </ButtonToolbar>
    </Col>
        </div>
    
    
    )
}

export default AddCustomer
