import React, { memo, useEffect, useState } from "react";
import { Card, Col } from "@themesberg/react-bootstrap";
import { Button } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faChartArea,
  faChartBar,
  faChartLine,
  faFlagUsa,
  faFolderOpen,
  faGlobeEurope,
  faPaperclip,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import Chartist from "react-chartist";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import moment from "moment-timezone";
import _ from "lodash";

import * as API from "../../ApiUrl";
import { merchantApi } from "../../redux/api/apiConfig";
import { nFormatter, numberCommaFormatter } from "../../utils/common";
import Preloader from "../../components/Preloader";

const SalesValueWidget = (props) => {
  const { title, value, percentage } = props;
  const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
  const percentageColor = percentage < 0 ? "text-danger" : "text-success";

  const [revenueGroupBy, setRevenueGroupBy] = useState("day");
  const [revenueData, setRevenueData] = useState(null);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    getChartData();
  }, [revenueGroupBy]);

  const getChartData = () => {
    merchantApi
      .get(API.CHART_DATA, {
        params: {
          group_by: revenueGroupBy,
        },
      })
      .then((response) => {
        const { data } = response;
        setRevenueData(data.data);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(false);
      });
  };

  const SalesValueChart = () => {
    const data = {
      labels: _.map(revenueData.group_by_result, "period"),
      series: [_.map(revenueData.group_by_result, "value")],
    };

    const options = {
      low: 0,
      showArea: true,
      fullWidth: true,
      axisX: {
        position: "end",
        showGrid: true,
      },
      axisY: {
        // On the y-axis start means left and end means right
        showGrid: false,
        showLabel: false,
        labelInterpolationFnc: (value) => `$${value / 1}k`,
      },
    };

    const plugins = [ChartistTooltip()];

    return (
      <Chartist
        data={data}
        options={{ ...options, plugins }}
        type="Line"
        className="ct-series-g ct-double-octave chart-align-left"
      />
    );
  };

  return (
    <>
      {revenueData ? (
        <>
          <Col xs={12} className="mb-4 d-none d-sm-block">
            <Card className="bg-white shadow-sm">
              <Card.Header className="d-flex flex-row align-items-center flex-0">
                <div className="d-block">
                  <h5 className="fw-normal mb-2">Total Revenue</h5>
                  <h3>
                    {revenueData.currency.symbol}
                    {numberCommaFormatter(revenueData.total_revenue)}
                  </h3>
                  {/* <small className="fw-bold mt-2">
                                <span className="me-2">Yesterday</span>
                                <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
                                <span className={percentageColor}>
                                    {percentage}%
                                </span>
                            </small> */}
                </div>
                <div className="d-flex ms-auto">
                  <Button
                    onClick={() => setRevenueGroupBy("day")}
                    disabled={revenueGroupBy === "day"}
                    variant="primary"
                    size="sm"
                    className={`me-3 ${
                      revenueGroupBy === "day" ? "group-by-active" : ""
                    }`}
                  >
                    Day
                  </Button>
                  <Button
                    onClick={() => setRevenueGroupBy("week")}
                    disabled={revenueGroupBy === "week"}
                    variant="secondary"
                    size="sm"
                    className={`me-3 ${
                      revenueGroupBy === "week" ? "group-by-active" : ""
                    }`}
                  >
                    Week
                  </Button>
                  <Button
                    onClick={() => setRevenueGroupBy("month")}
                    disabled={revenueGroupBy === "month"}
                    variant="secondary"
                    size="sm"
                    className={`me-3 ${
                      revenueGroupBy === "month" ? "group-by-active" : ""
                    }`}
                  >
                    Month
                  </Button>
                  <Button
                    onClick={() => setRevenueGroupBy("year")}
                    disabled={revenueGroupBy === "year"}
                    variant="secondary"
                    size="sm"
                    className={`me-3 ${
                      revenueGroupBy === "year" ? "group-by-active" : ""
                    }`}
                  >
                    Year
                  </Button>
                </div>
              </Card.Header>
              <Card.Body className="p-2">
                <SalesValueChart />
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} className="mb-4 d-sm-none">
            <Card className="bg-secondary-alt shadow-sm">
              <Card.Header className="d-md-flex flex-row align-items-center flex-0">
                <div className="d-block mb-3 mb-md-0">
                  <h5 className="fw-normal mb-2">Total Revenue</h5>
                  <h3>
                    {revenueData.currency.symbol}
                    {numberCommaFormatter(revenueData.total_revenue)}
                  </h3>
                  {/* <small className="fw-bold mt-2">
                                    <span className="me-2">Yesterday</span>
                                    <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
                                    <span className={percentageColor}>
                                        {percentage}%
                                    </span>
                                </small> */}
                </div>
                <div className="d-flex ms-auto">
                  <Button
                    onClick={() => setRevenueGroupBy("day")}
                    disabled={revenueGroupBy === "day"}
                    variant="primary"
                    size="sm"
                    className={`me-3 ${
                      revenueGroupBy === "day" ? "group-by-active" : ""
                    }`}
                  >
                    Day
                  </Button>
                  <Button
                    onClick={() => setRevenueGroupBy("week")}
                    disabled={revenueGroupBy === "week"}
                    variant="secondary"
                    size="sm"
                    className={`me-3 ${
                      revenueGroupBy === "week" ? "group-by-active" : ""
                    }`}
                  >
                    Week
                  </Button>
                  <Button
                    onClick={() => setRevenueGroupBy("month")}
                    disabled={revenueGroupBy === "month"}
                    variant="secondary"
                    size="sm"
                    className={`me-3 ${
                      revenueGroupBy === "month" ? "group-by-active" : ""
                    }`}
                  >
                    Month
                  </Button>
                  <Button
                    onClick={() => setRevenueGroupBy("year")}
                    disabled={revenueGroupBy === "year"}
                    variant="secondary"
                    size="sm"
                    className={`me-3 ${
                      revenueGroupBy === "year" ? "group-by-active" : ""
                    }`}
                  >
                    Year
                  </Button>
                </div>
              </Card.Header>
            </Card>
          </Col>
        </>
      ) : (
        <></>
      )}
      <Preloader show={isLoading} />
    </>
  );
};

export default memo(SalesValueWidget);
