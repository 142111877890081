import React, { useState, useEffect } from 'react';
import { Button } from 'rsuite';
import { Col, Row } from '@themesberg/react-bootstrap';
import Form from 'react-bootstrap/Form';
import { TextField, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import * as API from '../../../ApiUrl';
import { toast } from 'react-toastify';
import { textFieldLabelProps } from '../../../utils/common';

function WithdrawDialog({
  open,
  handleShowPassPhrase,
  onClose: handleClose,
  loading,
  userCurrency,
  // error,
  title,
  isSingleWallet,
}) {
  const [data, setdata] = useState({
    amountBTC: 0,
    amountCAD: 0,
    address: '',
  });
  const [walletList, setWalletList] = useState([]);
  const [Selectcoinid, setSelectcoinid] = useState([]);
  const [showWalletList, setShowWalletList] = useState([]);
  const [storeOption, setStoreOption] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedWallet, setSelectedWallet] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [showWallet, setShowWallet] = useState(isSingleWallet ? true : false);
  const [storeId, setStoreID] = useState('');
  const [xrpAddress, setXrpAddress] = useState('');
  // const [random, setRandom] = useState();
  const [coin, setCoin] = useState(null);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [storeFetchLoading, setStoreFetchLoading] = useState(true);

  const [validationSchema, setVaidationSchema] = useState(
    yup.object({
      amountCAD: yup
        .number('Enter your amount')
        .moreThan(
          0,
          `${selectedWallet?.currency?.symbol} value must be greater than 0`
        )
        .max(
          Number(selectedWallet?.balance_currency).toFixed(
            selectedWallet?.currency?.decimal_place
          ),
          'Cannot be more than currency balance'
        )

        .required('Amount is required'),

      address: yup
        .string('Enter your Remark')
        .min(5, 'Too Short')
        .max(300, 'Too Long ')
        .required('Remark is required'),

      amountBTC: yup
        .number('Enter Amount in BTC')
        .moreThan(
          0,
          `${selectedWallet?.coin?.symbol} value must be greater than 0`
        )
        .max(
          Number(selectedWallet?.balance).toFixed(
            selectedWallet?.coin?.decimal_place
          ),
          'Cannot be more than transaction value'
        )
        .required('Amount is required'),
    })
  );

  let token = localStorage.getItem('token');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  const coinChange = (e, selectedWallet) => {
    setCoin(e.target.value);
  };

  const onSubmit = async (values) => {
    const value = {
      ...values,
    };

    let verifiedAddress = null;

    try {
      setVerificationLoading(true);
      const response = await axios.post(
        `${API.TRANSACTION_VERIFY_WITHDRAW_ADDRESS}?limit=100`,
        {
          coin: Selectcoinid,
          address: values.address,
        },
        config
      );
      console.log(response);
      verifiedAddress = response.data.data.destination;
      setVerificationLoading(false);
      localStorage.setItem('receive_address', values.address);
      toast.success('Address verified!');
    } catch (err) {
      setVerificationLoading(false);
      toast.error(err?.response?.data?.message || 'Something went wrong');
      return;
    }

    // Verify 'to' address from API and send in finalData

    const finalData = {
      amount: values.amountBTC,
      coin: selectedWallet.coin.id,
      type: 'withdraw',
      store_id: storeId,
      receiving_address: verifiedAddress,
      method: formik.values.depositType,
      blockchain: formik.values.blockchain
    };
    console.log('asdadasdasd', finalData)
    const coinSymbol = selectedWallet.coin.symbol;
    const decimalPlace = selectedWallet.coin.decimal_place;
    handleShowPassPhrase({ finalData, xrpAddress, coinSymbol, decimalPlace });
  };

  const handleChange = (e) => {
    console.log('withdraw address:', e.target.name, e.target.value);
    setdata({ ...data, [e.target.name]: e.target.value });
  };
  const formik = useFormik({
    initialValues: data,
    validateOnBlur: true,
    onSubmit,
    handleChange,
    validationSchema: validationSchema,
    isInitialValid: false,
    validateOnMount: true,
  });

  const handleWalletchange = async (e) => {
    const wallet = walletList.filter(
      (item) => item.coin.name === e.target.value
    );
    console.log(wallet[0].coin);
    setSelectcoinid(wallet[0].coin.id);
    coinChange(e, wallet[0]);
    if (wallet.length > 0) {
      setVaidationSchema(
        yup.object({
          amountCAD: yup
            .number(`Enter your amount in ${userCurrency.symbol}`)
            .moreThan(
              0,
              `${wallet[0]?.currency?.symbol} value must be greater than 0`
            )
            .max(
              Number(wallet[0]?.balance_currency).toFixed(
                wallet[0]?.currency?.decimal_place
              ),
              'Cannot be more than currency balance'
            )

            .required(`Amount in ${userCurrency.symbol} is required`),

          address: yup
            .string('Enter your Remark')
            .min(5, 'Too Short')
            .max(300, 'Too Long ')
            .required('Remark is required'),

          amountBTC: yup
            .number('Enter Amount in BTC')
            .moreThan(
              0,
              `${wallet[0]?.coin?.symbol} value must be greater than 0`
            )
            .max(
              Number(wallet[0]?.balance).toFixed(
                wallet[0]?.coin?.decimal_place
              ),
              'Cannot be more than transaction value'
            )
            .required('Amount is required'),
        })
      );
      setSelectedWallet(wallet[0]);
      if (wallet[0]['balance'] === 0) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  };

  // TO DO, get list from backend and update coin list

  // const getCoinList = async () => {
  //   try {
  //     const response = await axios.get(API.COIN, config);
  //     setStoreFetchLoading(false);
  //     if (response.data) {
  //       console.log(response.data.data);
  //       let coinObj = response.data.data.reduce((prev, curr) => {
  //         return ({...prev, curr.name: curr.id})
  //       }, {});
  //       console.log(coinObj);
  //       //  setCoinIdMap()
  //     }
  //   } catch (error) {}
  // };

  const getWalletMenu = async () => {
    setStoreFetchLoading(true);
    try {
      const response = await axios.get(
        `${API.GET_MERCHENT_WALLET_LIST}?limit=100`,
        config
      );
      setStoreFetchLoading(false);
      if (response.data) {
        console.log(response.data);
        setWalletList(response.data.results);
        setShowWalletList(response.data.results);
      }
    } catch (error) {}
  };

  const getStoreList = async () => {
    try {
      let token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${API.TRANS_STORE}`, config);
      setStoreOption(response.data.data);
      // To delay until wallet list is fetched
      // setTimeout(() => {
      // }, 2500);
    } catch (error) {}
  };

  const handleStorechange = (e) => {
    // If user changes store after previously selecting a store and wallet, we need to reset the wallet and coin
    setSelectedWallet('');
    setCoin(null);
    setStoreID(e.target.value);
    setSelectedStore(e.target.value);
    console.log(e.target.value);
    console.log(showWalletList);
    const selectedStoreWallets = showWalletList.filter(
      (item) => item.store.store_id === e.target.value
    );

    console.log(selectedStoreWallets);

    if (selectedStoreWallets.length > 0) {
      setWalletList(selectedStoreWallets);
      const xrpWallet = selectedStoreWallets.filter(
        (item) => item.coin.symbol === 'XRP'
      );

      console.log(xrpWallet);

      setXrpAddress(xrpWallet[0].address);
    } else {
      setWalletList([]);
    }

    setShowWallet(true);
  };

  useEffect(() => {
    getWalletMenu();
    getStoreList();
    // getCoinList();
  }, []);

  useEffect(() => {
    if (isSingleWallet && showWalletList.length > 0) {
      const xrpWallet = showWalletList.filter(
        (item) => item.coin.symbol === 'XRP'
      );
      setXrpAddress(xrpWallet[0].address);
    }
  }, [showWalletList]);

  const coinValueChangeHandler = (e) => {
    const coinValue = e.target.value;
    console.log(e.target.value);
    formik.setValues({
      ...formik.values,
      amountBTC: coinValue,
      amountCAD: Number(coinValue * selectedWallet?.coin?.rate).toFixed(
        selectedWallet.currency.decimal_place
      ),
    });
    console.log(formik.values);
  };

  const currencyValueChangeHandler = (e) => {
    const currencyValue = e.target.value;
    console.log(e.target.value);
    formik.setValues({
      ...formik.values,
      amountBTC: Number(
        (currencyValue / selectedWallet?.coin?.rate).toFixed(
          selectedWallet.coin.decimal_place
        )
      ),
      amountCAD: currencyValue,
    });
    console.log(formik.values);
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth={'sm'}
        onClose={handleClose}
        BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: '8px',
            width: '560px',
            minHeight: '400px',
            maxHeight: '80vh',
          },
        }}
      >
        <DialogTitle className="text-center">
          <p className="dialog-title">{title}</p>
        </DialogTitle>

        <hr className="hr" />

        <form onSubmit={() => {}}>
          <DialogContent className="mt-3">
            <Row>
              {!isSingleWallet && (
                <Col xl={12} xs={12} className="mb-3">
                  <TextField
                    disabled={storeFetchLoading}
                    select
                    fullWidth
                    variant="outlined"
                    className=" bg-white"
                    id="store"
                    name="store"
                    InputLabelProps={textFieldLabelProps}
                    label="Store"
                    value={selectedStore}
                    onChange={(event) => {
                      handleStorechange(event);
                    }}
                    onBlur={(event) => {
                      formik.handleChange(event);
                    }}
                  >
                    {storeOption.map((a, i) => (
                      <MenuItem value={a.store_id}>{a.name}</MenuItem>
                    ))}
                  </TextField>
                </Col>
              )}
              {showWallet && (
                <Col xl={12} xs={12}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    className="mb-3 bg-white"
                    id="wallet"
                    name="wallet"
                    InputLabelProps={textFieldLabelProps}
                    label="Wallet"
                    value={coin}
                    onChange={(event) => {
                      handleWalletchange(event);
                    }}
                    onBlur={(event) => {
                      formik.handleChange(event);
                    }}
                  >
                    {walletList.map((a, i) => (
                      <MenuItem value={a.coin.name}>{a.coin.name}</MenuItem>
                    ))}
                  </TextField>
                  <br />
                </Col>
              )}

              {selectedWallet !== '' && (
                <Col xl={12} xs={12}>
                  <div className="merchant-wallet mt-2 mb-4">
                    <div className="instructions flex-row justify-start">
                      <span
                        className="font-600 font-fam "
                        style={{ marginRight: '20%' }}
                      >
                        Balance
                        <br />
                        {Number(selectedWallet.balance).toFixed(
                          selectedWallet.coin.decimal_place
                        )}{' '}
                        {selectedWallet.coin.symbol}
                      </span>
                      <span className="font-400 font-fam">
                        Balance In Currency
                        <br />{' '}
                        {Number(selectedWallet.balance_currency).toFixed(
                          selectedWallet.currency.decimal_place
                        )}{' '}
                        {selectedWallet.currency.code}
                      </span>
                    </div>
                  </div>
                </Col>
              )}
               {selectedWallet !=='' && (
                <Col xl={12} xs={12}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    className="mb-3 bg-white"
                    id="depositType"
                    name="depositType"
                    InputLabelProps={textFieldLabelProps}
                    label="Deposit Type"
                    // value={coin}
                    onChange={(event) => {
                      formik.handleChange(event)
                      // handleWalletchange(event);
                    }}
                    onBlur={(event) => {
                      // formik.handleChange(event);
                    }}
                  >
                    {selectedWallet?.coin?.deposit_types?.map((a, i) => (
                      <MenuItem value={a.key}>{a.name}</MenuItem>
                    ))}
                  </TextField>
                  <br />
                </Col>
              )}
                {selectedWallet !=='' ? (
                <Col xl={12} xs={12}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    className="mb-3 bg-white"
                    id="blockchain"
                    name="blockchain"
                    InputLabelProps={textFieldLabelProps}
                    label="Blockchain"
                    // value={coin}
                    onChange={(event) => {
                      formik.handleChange(event)
                      // handleWalletchange(event);
                    }}
                    onBlur={(event) => {
                      // formik.handleChange(event);
                    }}
                  >
                    {selectedWallet?.coin?.supported_deposit_blockchains?.map((a, i) => (
                      <MenuItem value={a.id}>{a.name}</MenuItem>
                    ))}
                  </TextField>
                  <br />
                </Col>
              ):''}
              <>
                <Col xl={12} xs={12}>
                  <TextField
                    className="w-100 mb-3"
                    variant="outlined"
                    InputLabelProps={textFieldLabelProps}
                    name="amountCAD"
                    id="amountCAD"
                    label={`Amount ${
                      selectedWallet !== ''
                        ? 'in ' + selectedWallet.currency.code
                        : ''
                    }`}
                    type="text"
                    value={formik.values.amountCAD}
                    onChange={currencyValueChangeHandler}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.amountCAD &&
                      Boolean(formik.errors.amountCAD)
                    }
                    helperText={
                      formik.touched.amountCAD && formik.errors.amountCAD
                    }
                  />
                </Col>
                <Col xl={12} xs={12}>
                  <TextField
                    className="w-100 mb-3"
                    variant="outlined"
                    InputLabelProps={textFieldLabelProps}
                    name="amountBTC"
                    id="amountBTC"
                    label={`Amount in ${
                      selectedWallet !== ''
                        ? selectedWallet.coin.symbol
                        : 'Coin'
                    }`}
                    type="text"
                    value={formik.values.amountBTC}
                    onChange={coinValueChangeHandler}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.amountBTC &&
                      Boolean(formik.errors.amountBTC)
                    }
                    helperText={
                      formik.touched.amountBTC && formik.errors.amountBTC
                    }
                  />
                </Col>
                <Col xl={12} xs={12}>
                  <TextField
                    className="w-100 mb-3"
                    variant="outlined"
                    InputLabelProps={textFieldLabelProps}
                    name="address"
                    id="address"
                    label="Address"
                    type="text"
                    value={formik.values.address}
                    onChange={(e) => {
                      formik.handleChange(e);
                      // setAddress(e.target.value);
                      // callVerifyAddress(e.target.value, coin, selectedWallet);
                    }}
                    disabled={!coin}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Col>
              </>
            </Row>
          </DialogContent>
          <hr className="hr" />
          {!isDisabled ? (
            <DialogActions className="footer-content">
              <Button
                className="btn white-btn action-button basis-30"
                onClick={handleClose}
                appearance="subtle"
              >
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white basis-70 ${
                  loading ? 'disabled' : null
                }`}
                appearance="primary"
                onClick={formik.handleSubmit}
                loading={loading || verificationLoading}
                disabled={loading || !formik.isValid}
              >
                {!loading ? 'Next' : 'Loading...'}
              </Button>
            </DialogActions>
          ) : (
            <>
              <br />
              <p className="font-500 font-fam  text-center mb-3">
                Can not withdraw, insufficient balance.
              </p>
            </>
          )}
        </form>
      </Dialog>
    </>
  );
}

export default WithdrawDialog;
