import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../redux/actions/modalActions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ButtonSecondary from "../ButtonSecondary";
import ButtonPrimary from "../ButtonPrimary";
import { Routes } from "../../routes";
import { FaCheckCircle } from "react-icons/fa";

const SuccessfullKyb = ({ isModalOpen }) => {
  const history = useHistory();

  const handleCancel = () => {
    return history.replace("/");
  };

  const handleAddNewStore = () => {
    return history.push(Routes.StoreMenu.path);
  };

  return (
    <>
      <Dialog
        open={isModalOpen}
        fullWidth
        maxWidth={"xs"}
        // onClose={handleCancelNavigation}
        scroll={"paper"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            display: "flex",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: '600'
          }}
        >
          <FaCheckCircle color="green" />
          KYB Successfully Submitted!
        </DialogTitle>
        <DialogContent
          sx={{ paddingTop: "5px !important", justifyContent: "center" }}
        >
          <Typography variant="subtitle2">
            Your KYB has been successfully submitted and is currently in
            progress. As the next step, please proceed to add a new
            store in your portal.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            paddingX: "24px",
            paddingBottom: "15px",
            gap: 1,
          }}
        >
          <ButtonPrimary onClick={handleAddNewStore}>
            Add New Store
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SuccessfullKyb;