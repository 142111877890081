import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import {
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  emailRegex,
  removeCodeFromPhone,
  textFieldLabelProps,
} from "../../../../utils/common";
import Iconify from "../../../../components/Iconify";
import ButtonSecondary from "../../../../components/ButtonSecondary";
import ButtonPrimary from "../../../../components/ButtonPrimary";
import Colors from "../../../../assets/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  getPersonalDocs,
  getShareholdersList,
  updateShareHoldersList,
} from "../../../../redux/actions/kybActions";
import CustomRadio from "../../../../components/CustomRadio";
import _ from "lodash";
import { getProfileInfo } from "../../../../redux/actions/profileActions";
import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import { DatePicker } from "@mui/x-date-pickers";
import { isBefore, subYears, format, parseISO } from "date-fns";

const ShareholderList = ({
  onBackClick,
  handleNext,
  updateCurrentStepHandler,
}) => {
  const user = useSelector((state) => state?.profileReducer.user);
  const {
    email: userEmail,
    shareholder: { ownership },
  } = user;

  const [forms, setForms] = useState([
    {
      first_name: "",
      last_name: "",
      dob: null,
      phone_number: "",
      email: "",
      ownership: "",
      dial_code: "1",
      country_code: "ca",
    },
  ]);
  const [errors, setErrors] = useState([]);
  const [currentUserOwnership, setCurrentUserOwnership] = useState(ownership);
  const [value, setValue] = useState("yes");
  const [loading, setLoading] = useState(false);
  const [userKyc, setUserKyc] = useState(null);

  const countryList = JSON.parse(localStorage.getItem("country-list")) || [];

  const handleChange = (event) => {
    if (event.target.value === "yes") {
      setCurrentUserOwnership(100);
    }
    setValue(event.target.value);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const data = await response.json();
        // setForms(data.forms);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    dispatch(
      getShareholdersList(
        (res) => {
          console.log("cacasc", res);
          if (res.length < 2) {
            setForms([
              {
                first_name: "",
                last_name: "",
                dob: null,
                phone_number: "",
                email: "",
                ownership: "",
                dial_code: "1",
                country_code: "ca",
              },
            ]);
            setValue("yes");
          } else {
            const listWithoutCurrentUser = res.filter(
              (item) => item.email !== userEmail
            );

            const dateConvertedList = listWithoutCurrentUser.map((item) => {
              return {
                ...item,
                dob: new Date(item.dob),
                phone_number: `${item.dial_code}${item.phone_number}`,
              };
            });
            setForms(dateConvertedList);
            setValue("no");
          }
        },
        (err) => {
          console.log("cweec32dc", err);
        }
      )
    );

    dispatch(
      getPersonalDocs(
        (res) => {
          console.log("user kyc", res);
          setUserKyc(res[0]?.status)
        },
        (err) => {
          console.log("pmcopwec", err);
        }
      )
    );

    fetchData();
  }, []);

  const handleInputChange = (index, field, value) => {
    const newForms = [...forms];
    newForms[index][field] = value;
    setForms(newForms);
  };

  const handleAddForm = () => {
    setForms([
      ...forms,
      {
        first_name: "",
        last_name: "",
        dob: null,
        phone_number: "",
        email: "",
        ownership: "",
        dial_code: "1",
        country_code: "ca",
      },
    ]);
    setErrors([...errors, {}]);
  };

  const handleRemoveForm = (index) => {
    const newForms = [...forms];
    newForms.splice(index, 1);
    setForms(newForms);

    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };

  const handleFormSubmit = () => {
    setLoading(true);

    // Set errors and prevent submission if any field is empty only if user is not sole owner
    console.log("facasc", forms);
    if (value === "no") {
      const newErrors = forms.map((form) => {
        const isEmailValid = emailRegex.test(form.email);

        const currentDate = new Date();
        const userDOB = new Date(form.dob);

        // Check if the user is at least 16 years old
        const isUnderAge = !isBefore(userDOB, subYears(currentDate, 16));
        console.log(
          form.dob,
          "asxsxasxc23",
          isUnderAge,
          userDOB,
          currentDate,
          subYears(currentDate, 16)
        );
        return {
          first_name: form?.first_name?.trim() === "",
          last_name: form?.last_name?.trim() === "",
          dob: form?.dob === null,
          phone_number:
            removeCodeFromPhone(form?.phone_number, form?.dial_code).length <
              10 || form?.phone_number === null,
          email: form?.email?.trim() === "" || !isEmailValid,
          ownership: form?.ownership?.trim() === "",
          isUnderAge,
        };
      });
      if (
        newErrors.some((error) => Object.values(error).some((field) => field))
      ) {
        setErrors(newErrors);
        setLoading(false);
        return;
      }
    }

    console.log("Submitting forms:", forms);
    let formsToSubmit = [];
    if (value === "no") {
      formsToSubmit = _.cloneDeep(forms);
    }
    let dateFormattedForms = formsToSubmit.map((form) => {
      console.log(format(form.dob, "yyyy-MM-dd"));
      return {
        ...form,
        dob: format(form.dob, "yyyy-MM-dd"),
        phone_number: removeCodeFromPhone(form?.phone_number, form?.dial_code),
      };
    });
    dateFormattedForms.push({
      first_name: user.first_name,
      last_name: user.last_name,
      dob: format(parseISO(user.dob), "yyyy-MM-dd"),
      phone_number: removeCodeFromPhone(user.phone_number, user.dial_code),
      dial_code: user.dial_code,
      country_code: user.country_code,
      email: userEmail,
      ownership: currentUserOwnership,
    });
    console.log("csdcsdc", dateFormattedForms);
    dispatch(
      updateShareHoldersList(
        { shareholders: dateFormattedForms },
        (res) => {
          console.log("oknowc", res);
          dispatch(getProfileInfo());
          updateCurrentStepHandler(2);
          toast.success("Shareholders updated successfully");
          handleNext();
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          toast.error("Shareholders update failed, please try again");
          console.log("icbwcowencw", err);
        }
      )
    );
  };

  const clearError = (index, field) => {
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = { ...newErrors[index], [field]: false };
      return newErrors;
    });
  };

  console.log(errors, "sdcsc");

  return (
    <Stack width={"100%"}>
      <Stack sx={{ mb: 2 }}>
        <FormControl>
          <FormLabel
            id="ownership"
            sx={{ "&.Mui-focused": { color: "inherit" } }}
          >
            <Typography variant="body1" sx={{ color: "black" }}>
              Are you the only shareholder of the company?
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby="ownership"
            name="ownership_options"
            value={value}
            onChange={handleChange}
          >
            <CustomRadio value={"yes"} label={"Yes"} />
            <CustomRadio value={"no"} label={"No"} />
          </RadioGroup>
        </FormControl>
      </Stack>
      {value === "no" && (
        <>
          {
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              InputLabelProps={textFieldLabelProps}
              type="number"
              label="Enter your ownership %"
              value={currentUserOwnership}
              onChange={(e) => setCurrentUserOwnership(e.target.value)}
              // error={errors[index]?.ownership}
              // helperText={
              //   errors[index]?.ownership ? "Shareholding is required" : ""
              // }
            />
          }
          {forms.map((form, index) => (
            <Stack sx={{ mt: 8 }}>
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">{`Shareholder ${
                  index + 2
                }`}</Typography>
                <Button
                  onClick={() => handleRemoveForm(index)}
                  color="error"
                  startIcon={<Iconify icon="eva:trash-2-outline" />}
                >
                  Remove
                </Button>
              </Stack>
              <Stack key={index} alignItems={"flex-end"}>
                <TextField
                  fullWidth
                  variant="outlined"
                  className="mt-4 bg-white"
                  InputLabelProps={textFieldLabelProps}
                  label="First Name"
                  value={form.first_name}
                  onChange={(e) => {
                    clearError(index, "first_name");
                    handleInputChange(index, "first_name", e.target.value);
                  }}
                  error={errors[index]?.first_name}
                  helperText={
                    errors[index]?.first_name ? "First name is required" : ""
                  }
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  className="mt-4 bg-white"
                  InputLabelProps={textFieldLabelProps}
                  label="Last Name"
                  value={form.last_name}
                  onChange={(e) => {
                    clearError(index, "last_name");
                    handleInputChange(index, "last_name", e.target.value);
                  }}
                  error={errors[index]?.last_name}
                  helperText={
                    errors[index]?.last_name ? "Last name is required" : ""
                  }
                />
                <DatePicker
                  fullWidth
                  disableFuture
                  format={"dd/MM/yyyy"}
                  className="mt-4 bg-white"
                  id={"dob"}
                  name="dob"
                  value={form.dob}
                  onChange={(date) => {
                    clearError(index, "dob");
                    handleInputChange(index, "dob", date);
                  }}
                  slots={{
                    textField: TextField,
                  }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      fullWidth: true,
                      error: errors[index]?.dob || errors[index]?.isUnderAge,
                      helperText: errors[index]?.isUnderAge
                        ? "You must be at least 16 years old"
                        : errors[index]?.dob
                        ? "Date of birth is required"
                        : "",
                    },
                  }}
                />
                {/* <TextField
                  fullWidth
                  type={"date"}
                  variant="outlined"
                  className="mt-4 bg-white"
                  InputLabelProps={textFieldLabelProps}
                  label="Date of Birth"
                  value={form.dob}
                  onChange={(e) => {
                    clearError(index, "dob");
                    handleInputChange(index, "dob", e.target.value);
                  }}
                  error={errors[index]?.dob}
                  helperText={
                    errors[index]?.dob ? "Date of birth is required" : ""
                  }
                /> */}
                <Stack sx={{ width: "100%" }}>
                  <PhoneInput
                    autoFormat
                    onlyCountries={
                      countryList.length > 0
                        ? _.map(countryList, (country) => {
                            // console.log(country.alpha2code);
                            return country?.alpha2code?.toLowerCase();
                          })
                        : []
                    }
                    containerStyle={{ marginTop: "24px" }}
                    value={form.phone_number}
                    specialLabel={"Phone Number"}
                    country={form.country_code}
                    placeholder=""
                    inputStyle={{
                      height: 56,
                      borderRadius: 8,
                      border: "none",
                      width: "100%",
                      color: "rgba(0,0,0,0.87)",
                    }}
                    buttonStyle={{
                      height: 56,
                      border: "none",
                      background: "transparent",
                    }}
                    containerClass="phone-container"
                    enableSearch
                    disableSearchIcon
                    disableCountryGuess={true}
                    countryCodeEditable={false}
                    isValid={(value, country) => {
                      return true;
                    }}
                    className="bg-white"
                    onChange={(phone, data) => {
                      clearError(index, "phone_number");
                      handleInputChange(index, "phone_number", phone);
                      handleInputChange(
                        index,
                        "country_code",
                        data?.countryCode
                      );
                      handleInputChange(index, "dial_code", data?.dialCode);
                      console.log("dscscdsdc", data);
                    }}
                    error={errors[index]?.phone_number}
                    helperText={
                      errors[index]?.phone_number
                        ? "Enter a valid phone number"
                        : ""
                    }
                  />
                  {errors[index]?.phone_number && (
                    <Typography variant="caption" color={"error"}>
                      Enter a valid phone number
                    </Typography>
                  )}
                </Stack>
                <TextField
                  fullWidth
                  variant="outlined"
                  className="mt-4 bg-white"
                  InputLabelProps={textFieldLabelProps}
                  type="email"
                  label="Email"
                  value={form.email}
                  onChange={(e) => {
                    clearError(index, "email");
                    handleInputChange(index, "email", e.target.value);
                  }}
                  error={errors[index]?.email}
                  helperText={
                    errors[index]?.email
                      ? form.email === ""
                        ? "Email is required"
                        : "Enter a valid email"
                      : ""
                  }
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  className="mt-4 bg-white"
                  InputLabelProps={textFieldLabelProps}
                  type="number"
                  label="Shareholding Percentage"
                  value={form.ownership}
                  onChange={(e) => {
                    clearError(index, "ownership");
                    handleInputChange(index, "ownership", e.target.value);
                  }}
                  error={errors[index]?.ownership}
                  helperText={
                    errors[index]?.ownership ? "Shareholding is required" : ""
                  }
                />
              </Stack>
            </Stack>
          ))}
        </>
      )}
      {value === "no" && (
        <Stack
          sx={{
            justifyContent: "flex-start",
            mt: 8,
          }}
        >
          <Button
            sx={{ width: "fit-content", color: Colors.primary }}
            size="small"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleAddForm}
          >
            Add new shareholder
          </Button>
        </Stack>
      )}
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: "2rem",
          gap: 1,
        }}
      >
        <ButtonSecondary onClick={onBackClick}>Back</ButtonSecondary>

        <ButtonPrimary loading={loading} onClick={handleFormSubmit}>
          Update and Continue
        </ButtonPrimary>
      </Stack>
    </Stack>
  );
};

export default ShareholderList;