import React from 'react';
import { Button } from 'rsuite';

const ButtonPrimary = (props) => {
  return (
    <Button className='btn load text-white' style={{ width: 'fit-content', ...props.style }} loading={props.loading} type={props.type} onClick={props.onClick}>
      {props.children}
    </Button>
  );
};

export default ButtonPrimary;