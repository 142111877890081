import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { setSingleWallet, setWalletDetails } from "../../redux/actions/auth";
import { getBusinessDetails } from "../../redux/actions/businessApi";
import { colors, handleBackgroundClick } from "../../utils/common";
import ButtonPrimary from "../../components/ButtonPrimary";
import { Chip, Stack, Typography, Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import { merchantApi } from "../../redux/api/apiConfig";
import * as API from "../../ApiUrl";

const ShowWalletDetails = (props) => {
  console.log("props", props);
  const { open, handleClose, walletDetails, setSingleWallet } = props;

  const [showPhrase, setShowPhrase] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const onClose = (event, reason) => {
    handleBackgroundClick(reason, handleClose, false);
  };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(walletDetails?.privateKey);
    toast.success("PassPhrase copied successfully!");
  };

  const onContinueClicked = async () => {
    try {
      setIsLoading(true);
      await merchantApi.patch(API.CONFIG, {
        store_wise_wallet: false,
      });

      const res = await merchantApi.post(API.GET_WALLET, {
        address: walletDetails.address,
      });

      toast.success("Wallets created successfully");
      setIsLoading(false);
      setSingleWallet();
      getBusinessDetails();
      setWalletDetails(walletDetails)
      onClose();
    } catch (err) {
      toast.error("Wallet creation failed.");
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <>
        <DialogTitle>Private Key</DialogTitle>
        <DialogContent>
          <Stack sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ lineHeight: "1 !important" }}>
              Write down Private Key and save it in a place that you trust and
              only you can access.
            </Typography>
          </Stack>
          <Stack sx={{ justifyContent: "center" }}>
            <Chip
              label={
                showPhrase
                  ? walletDetails.privateKey
                  : "*********************************************************"
              }
            />
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Button
              component="label"
              sx={{ color: colors.primary, textTransform: "capitalize" }}
              startIcon={
                showPhrase ? <VisibilityOffIcon /> : <VisibilityIcon />
              }
              onClick={() => setShowPhrase(!showPhrase)}
            >
              {showPhrase ? "Hide Private Key" : "Show Private Key"}
            </Button>
            <Button
              component="label"
              sx={{ color: colors.primary, textTransform: "capitalize" }}
              startIcon={<ContentCopyIcon />}
              onClick={copyToClipboard}
            >
              Copy to clipboard
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            paddingX: "24px",
            paddingBottom: "15px",
            gap: 1,
          }}
        >
          <ButtonPrimary loading={isLoading} onClick={onContinueClicked}>Continue</ButtonPrimary>
        </DialogActions>
      </>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSingleWallet: () => dispatch(setSingleWallet()),
    getBusinessDetails: () => dispatch(getBusinessDetails()),
    setWalletDetails: (data) => dispatch(setWalletDetails(data)),
  };
};

export default connect(null, mapDispatchToProps)(ShowWalletDetails);

// export default ShowWalletDetails;
