import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Faq from '../../data/Faq';
import TicketList from './component/TicketList';
import { VscFiles } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import * as API from '../../ApiUrl';
import axios from 'axios';
import NewTicketModal from './component/NewTicketModal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { Form, InputGroup, Button } from '@themesberg/react-bootstrap';
import searchIcon from '../../assets/img/icons/searchIcon.svg';
import dropDown from '../../assets/img/icons/dropDown.svg';
import { capitalize } from 'lodash';

const Support = () => {
  const [tickets, setTickets] = useState({});
  const [ticketCategory, setTicketCategory] = useState('');
  const [showNewTicketModal, setShowNewTicketModal] = useState(false);
  const [cateOption, setCateOption] = useState([
    { name: 'Select Category', id: '' },
  ]);

  const [statusOptions, setStatusOptions] = React.useState([
    { name: 'Select Status', value: '' },
  ]);
  const [statusTitle, setStatusTitle] = React.useState('');

  const [pageChange, setPageChange] = useState({
    currentPage: 0,
    pageSize: 7,
    search: '',
    category: '',
    status: '',
  });

  const location = useLocation();

  const toggleNewTicketModal = () => {
    setShowNewTicketModal((prevState) => !prevState);
  };

  const getTickets = async () => {
    try {
      let token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      let query = `?limit=${pageChange.pageSize}&offset=${
        pageChange.currentPage * pageChange.pageSize
      }&search=${pageChange.search}&category=${pageChange.category}&status=${
        pageChange.status
      }`;

      const response = await axios.get(`${API.TICKET_LIST}${query}`, config);
      console.log(response.data);
      setTickets(response.data);

      console.log('respose=======', response.data);
    } catch (error) {
      console.log('my error is ', error);
    }
  };

  useEffect(() => {
    console.log(location.state);
    if (location?.state?.openTicketModal) {
      toggleNewTicketModal();
    }
    getTicketStatus();
    getStatusOption();
  }, []);

  const getTicketStatus = async () => {
    try {
      let token = localStorage.getItem('token');

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(`${API.SUPPORT_CATEGORY}`, config);
      // console.log("ticket status list", response.data);
      setCateOption([...cateOption, ...response.data]);
    } catch (error) {
      console.log('ticket status list error ', error);
    }
  };

  const getStatusOption = async () => {
    try {
      let token = localStorage.getItem('token');
      // console.log("user list token", token );
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${API.SUPPORT_STATUS}`, config);
      // console.log("status list show", response.data.data);
      setStatusOptions([...statusOptions, ...response.data.data]);
    } catch (error) {
      console.log('status list error ', error);
    }
  };

  useEffect(() => {
    getTickets();
  }, [
    pageChange.search,
    pageChange.offset,
    pageChange.pageSize,
    pageChange.category,
    pageChange.status,
    pageChange.currentPage,
  ]);

  const handlePageChange = (p) => {
    setPageChange({
      ...p,
      search: pageChange.search,
    });
  };
  const handlefilter = (id, name) => {
    setTicketCategory(name);
    setPageChange({
      ...pageChange,
      currentPage: 0,
      category: id,
    });
  };

  const handleStatusChange = (name, value) => {
    setStatusTitle(name);
    setPageChange({
      ...pageChange,
      currentPage: 0,
      status: value,
    });
  };

  const handleSearch = (e) => {
    // console.log("search", e);
    setPageChange({
      ...pageChange,
      search: e,
      currentPage: 0,
    });
  };

  return (
    <div className="page-wrapper stb-page-wrapper stb-ph-8">
      <div className="user stb-h-100">
        <div className="users stb-h-100 bg-white p-4 radi">
          <div className="page-header  pb-4 space-between">
            <div>
              <h3 className="m-0 page-title">Support</h3>
              <p>View your tickets</p>
            </div>
            <div className="gap-4 tools">
              <div className="filter">
                <Form
                  onSubmit={(e) => e.preventDefault()}
                  className="w-100 search"
                >
                  <Form.Group>
                    <InputGroup>
                      <InputGroup.Text>
                        <img src={searchIcon} alt="search" />
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) => {
                          handleSearch(e.target.value);
                        }}
                        type="text"
                        placeholder="Search"
                      />
                    </InputGroup>
                  </Form.Group>
                </Form>
              </div>

              <div className="filter">
                <Dropdown as={ButtonGroup} className="w-100">
                  <Dropdown.Toggle id="dropdown-basic">
                    {ticketCategory || 'Select Category'}
                    <img
                      src={dropDown}
                      className="dropdown-icon"
                      style={{ marginLeft: '4px' }}
                      alt="drop-down"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors">
                    {cateOption.map((a, i) => (
                      <Dropdown.Item
                        as="button"
                        value={a.name}
                        onClick={() => {
                          handlefilter(a.id, a.name);
                        }}
                        key={i + 'res'}
                      >
                        {a.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>{' '}
              </div>
              <div className="filter">
                <Dropdown as={ButtonGroup} className="w-100">
                  <Dropdown.Toggle id="dropdown-basic">
                    {capitalize(statusTitle) || 'Select Status'}
                    <img
                      src={dropDown}
                      className="dropdown-icon"
                      style={{ marginLeft: '4px' }}
                      alt="drop-down"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors">
                    {statusOptions.map((a, i) => (
                      <Dropdown.Item
                        as="button"
                        value={a.name}
                        onClick={() => {
                          handleStatusChange(a.name, a.value);
                        }}
                        key={i + 'res'}
                      >
                        {capitalize(a.name)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>{' '}
              </div>

              <div className="filter">
                <Button
                  onClick={toggleNewTicketModal}
                  style={{ minHeight: '40px' }}
                  appearance="default"
                  className="btn load text-white d-flex flex-row gap-2 "
                >
                  {/* <span>Add New User</span> */}
                  New Ticket
                  {/* <img src={add} alt="add" /> */}
                </Button>
              </div>
            </div>
          </div>

          <TicketList
            tickets={tickets}
            // setpageChange={handlePageChange}
            paginationdetails={pageChange}
            setPageChange={setPageChange}
          />

          {showNewTicketModal && (
            <NewTicketModal
              isOpen={showNewTicketModal}
              onClose={toggleNewTicketModal}
              getTickets={getTickets}
            />
          )}
          <Faq />
        </div>
      </div>
    </div>
  );
};

export default Support;
