import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSound from "use-sound";
import { formatDistance} from "date-fns";
import MessageModal from "./MessageModal";
import { notificationTypesPastTenses } from "../utils/common";
import {
  faCog,
  faSignOutAlt,
  faUserShield,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {
  Row,
  Col,
  Nav,
  Image,
  Card,
  Navbar as MyNavbar,
  Dropdown,
  Container,
  ListGroup,
} from "@themesberg/react-bootstrap";
import notif from "../assets/img/icons/notif.svg";
import { IconButton } from "rsuite";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import profile3 from "../assets/img/team/vanilla-profile3.png";
import { Routes } from "../routes";
import arrowDown from "../assets/img/icons/arrowDown.svg";
import { capitalize } from "lodash";
import * as API from "../ApiUrl";
import { toast } from "react-toastify";
import alert from "../assets/sound/notifAlert.wav";
import { merchantApi } from "../redux/api/apiConfig";
import * as actionTypes from "../redux/actionTypes";
import QRDialog from "./QRDialog";

const Navbar = ({ userRole, userPayString }) => {
  const [showQRDialog, setShowQRDialog] = useState(false);
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [newNotifications, setNewNotifications] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [userCurrency, setUserCurrency] = useState({
    symbol: "",
    decimalPlace: 2,
    symbolPrefix: false,
  });
  const [play] = useSound(alert);

  const history = useHistory();
  const dispatch = useDispatch();

  console.log(recentTransactions)

  let token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const merchantDetail = useSelector(
    (state) => state.businessReducer.businessDetails
  );

  useEffect(() => {
    let timer = setInterval(() => {
      // getRecentTransactions();
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [recentTransactions]);

  useEffect(() => {
    getRecentTransactions();
    // getMerchantDetail();
    getCurrency();
  }, []);

  const handleQRDialog = () => {
    setShowQRDialog(!showQRDialog);
  };

  const toggleMessageModal = () => {
    setShowMessageModal((prevState) => !prevState);
  };

  const getCurrency = async () => {
    try {
      const response = await merchantApi.get(API.GET_BALANCE, config);

      setUserCurrency({
        symbol: response.data.data.currency.symbol,
        decimalPlace: response.data.data.currency.decimal_place,
        symbolPrefix: response.data.data.currency.symbol_prefix,
      });
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
    }
  };

  const getRecentTransactions = async () => {
    try {
      const response = await merchantApi.get(`${API.TRANSACTION_LIST}?limit=5`);

      if (
        (recentTransactions.length === 0 && response.data.results.length > 0) ||
        recentTransactions[0]?.id !== response.data.results[0]?.id
      ) {
        if (recentTransactions.length !== 0) {
          play();
        }
        setNewNotifications(true);
        setRecentTransactions(response.data.results);
      }
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      toast.error(error.response.data.message || "Something went wrong");
    }
  };

  let user = JSON.parse(localStorage.getItem("user-info"));

  function logOut() {
    localStorage.clear();
    dispatch({ type: actionTypes.CLEAR_STORE_DATA });
    window.location.href = "/";
  }

  const Notification = (props) => {
    const {
      sender,
      image = profile3,
      type,
      modified_date,
      sender_coin,
      value_in_currency,
      currency,
    } = props;


    return (
      <ListGroup.Item action className="border-bottom border-light ">
        <Row className="align-items-center flex-nowrap">
          <Col className="col-auto">
            <Image
              src={image}
              className="user-avatar lg-avatar rounded-circle"
            />
          </Col>
          <Col className="ps-0 ms--2">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ maxWidth: "80%" }}
            >
              <div style={{ flex: "1", minWidth: "0" }}>
                <h4
                  className="h6 mb-0 text-small"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {sender?.name}
                </h4>
              </div>
              <div className="text-end">
                <small className="" style={{ whiteSpace: "nowrap" }}>
                  {formatDistance(new Date(modified_date), new Date(), {
                    addSuffix: true,
                  })}
                </small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{`${
              notificationTypesPastTenses[`${type}`]
            } ${Number(value_in_currency).toFixed(
              sender_coin?.decimal_place
            )} ${currency?.code}`}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <MyNavbar variant="dark" expanded className=" bg-white nav-container">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-end w-100">
          <Nav className="align-items-center gap-2">
            {userPayString && (
              <IconButton
                circle
                onClick={handleQRDialog}
                color="black"
                icon={<FontAwesomeIcon icon={faQrcode} />}
              />
            )}
            <Dropdown
              as={Nav.Item}
              onToggle={() => {
                setNewNotifications(false);
              }}
            >
              <Dropdown.Toggle
                as={Nav.Link}
                className="text-dark icon-notifications me-lg-3"
              >
                <span className="icon icon-sm">
                  {/* <FontAwesomeIcon icon={faBell}  className="bell-shake" /> */}
                  <img src={notif} alt="bell" srcset="" />
                  {newNotifications ? (
                    <span className="icon-badge rounded-circle unread-notifications" />
                  ) : null}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0"
                style={{ maxWidth: "26rem" }}
              >
                <ListGroup className="list-group-flush">
                  <Nav.Link
                    href="#"
                    className="text-center text-primary fw-bold border-bottom border-light py-3"
                  >
                    Notifications
                  </Nav.Link>

                  {recentTransactions.map((n) => (
                    <Notification key={`notification-${n.id}`} {...n} />
                  ))}

                  <Dropdown.Item
                    onClick={() => {
                      history.push("/reports/transaction");
                    }}
                    className="text-center text-primary fw-bold py-3"
                  >
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center gap-2">
                  <Image
                    src={profile3}
                    className="user-avatar md-avatar rounded-circle"
                  />
                  <div className="media-body ms-2 text-dark d-lg-flex flex-column align-items-left d-none">
                    <span className="mb-0 font-small fw-bold font-fam">
                      {user?.first_name} {user?.last_name}
                    </span>
                    <span className="mb-0 font-small fw-light font-fam">
                      {capitalize(user?.user_role)}
                    </span>
                  </div>
                  <img src={arrowDown} alt="arrow down" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item
                  className="fw-bold"
                  onClick={() => history.push(Routes.Settings.path)}
                >
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My
                  Profile
                </Dropdown.Item>
                <Dropdown.Item
                  className="fw-bold"
                  onClick={() => history.push(Routes.Settings.path)}
                >
                  <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                </Dropdown.Item>
                <Dropdown.Item
                  className="fw-bold"
                  onClick={() => history.push(Routes.Support.path)}
                >
                  <FontAwesomeIcon icon={faUserShield} className="me-2" />
                  Support
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item onClick={logOut} className="fw-bold">
                  <Card.Link>
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className="text-danger me-2"
                    />
                    Logout
                  </Card.Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
      {showMessageModal ? (
        <MessageModal open={showMessageModal} onClose={toggleMessageModal} />
      ) : null}

<QRDialog open={showQRDialog} handleClose={handleQRDialog} />
    </MyNavbar>
  );
};

function mapStateToProps({ authReducer }) {
  const { userRole, userPayString } = authReducer;
  return {
    userRole,
    userPayString,
  };
}
export default connect(mapStateToProps, null)(Navbar);
