import {useEffect, useState} from 'react';

import * as mnemonic from '../utils/mnemonics';

export const useRecoveryWords = () => {
  const [randomWords, setRandomWords] = useState([]);

  const generateWords = async () => {
    const words = await mnemonic.generateWords(128);
    console.log('wordsss', words)
    setRandomWords(words);
  };

  const generateSeed = async (password, words = randomWords) =>
    mnemonic.wordsToSeedHex(words.join(' '), password);

  useEffect(() => {
    // generate words after the mount
    console.log('heeee')
    generateWords();
  }, []);

  return {
    randomWords,
    generateWords,
    generateSeed,
  };
};
