import React from 'react';
// import {Link} from 'react-router-dom'


const Statement = () => {
    return (
        


        <div className="page-wrapper mt-4 mb-4">
        <div className="overview">
            <h5 className="font-700 mb-3">Overview</h5>
            <div className="row ">
                <div className="col-lg-4 col-md-12 d-flex align-items-stretch mb-3">
                    <div className="cards w-100 pb-5 ">
                        <div className="titles font-700 font-14 ms-4 pt-3 font-fam">Bank Details</div>
                        <hr />
                        <div className="pt-3">
                            <div className="details">
                                <div className="container">
                                    <div className="row pb-2">
                                        <div className="col-6"> <span className="labl">Bank ID</span>
                                            <h3 className="type font-18 font-700 pt-1  font-fam">8196175</h3>
                                        </div>
                                        <div className="col-6"> <span className="labl ">Country</span>
                                            <h3 className="type font-18 font-700 pt-1  font-fam">GB</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6"> <span className="labl">Bank Name</span>
                                            <h3 className="type font-18 font-700 pt-1  font-fam">GB_Bank</h3>
                                        </div>
                                        <div className="col-6"> <span className="labl">Bank Currency</span>
                                            <h3 className="type font-18 font-700 pt-1  font-fam">GBP</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 d-flex align-items-stretch mb-3">
                    <div className="cards w-100 pb-5 ">
                        <div className="titles font-700 font-14 ms-4 pt-3 font-fam">Account Holder Information</div>
                        <hr />
                      
                        <div className="pt-3">
                            <div className="details">
                                <div className="container">
                                    <div className="row pb-2">
                                        <div className="col-6"> <span className="labl">Account Holder Name</span>
                                            <h3 className="type font-18 font-700 pt-1  font-fam">Tom Jones</h3>
                                        </div>
                                        <div className="col-6"> <span className="labl">Short Code</span>
                                            <h3 className="type font-18 font-700 pt-1  font-fam">22-5342</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6"> <span className="labl">Account Number</span>
                                            <h3 className="type font-18 font-700 pt-1  font-fam">9866-42443</h3>
                                        </div>
                                        <div className="col-6"> <span className="labl">SWIFT</span>
                                            <h3 className="type font-18 font-700 pt-1  font-fam">ABBLBDDH201</h3>
                                        </div>
                                        <div className="col-6"> <span className="labl">BIC</span>
                                            <h3 className="type font-18 font-700 pt-1  font-fam">BA-3224</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* benificary bank details end */} 
                
                {/* benificary identy details */}
                <div className="col-lg-4 col-md-12 d-flex align-items-stretch mb-3">
                    <div className="cards w-100 pb-5">
                         <div className="titles font-700 font-14 ms-4 pt-3 font-fam">Benificary Bank Details</div>
                        <hr />
                        <div className="pt-3">
                            <div className="details">
                                <div className="container">
                                    <div className="row pb-2">
                                        <div className="col-6"> <span className="labl">First Name</span>
                                            <h3 className="type font-18 font-700 pt-1 font-fam">Tom </h3>
                                        </div>
                                        <div className="col-6"> <span className="labl">Last Name</span>
                                            <h3 className="type font-18 font-700 pt-1 font-fam">Jones</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6"> <span className="labl">Age</span>
                                            <h3 className="type font-18 font-700 pt-1 font-fam">29</h3>
                                        </div>
                                        <div className="col-6"> <span className="labl">Location</span>
                                            <h3 className="type font-18 font-700 pt-1 font-fam">Canada</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

            
       
    )
    
}

export default Statement
