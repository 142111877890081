import axios from "axios";
import * as API from "../../ApiUrl";
import { GET_STORE_LIST, SET_STORE_LIST } from "../actionTypes";

const getStorelist = () => async (dispatch) => {
  dispatch({ type: GET_STORE_LIST });

  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await axios.get(`${API.STORE_LIST}`, config);
    console.log("response of the auth", res);
    dispatch({
      type: SET_STORE_LIST,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err, "errr");
    // dispatch({
    //   type: GET_PROFILE_FAIL,
    // });
  }
};

export { getStorelist };
