import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import moment from "moment-timezone";
import { connect, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Form, InputGroup, Col, Button } from "@themesberg/react-bootstrap";
import { DateRangePicker, Loader } from "rsuite";
import { toast } from "react-toastify";
import "rsuite/dist/styles/rsuite-default.css";

import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from "recharts";

import * as API from "../../ApiUrl";
import axios from "axios";
import Transactiontable from "./ReportTransactions/Transactiontable";
import { merchantApi } from "../../redux/api/apiConfig";
import RefundDialog from "./ReportTransactions/components/RefundData/RefundDialog";
import WithdrawPassphraseDialog from "../merchantprofile/Components/WithdrawPassphraseDialog";
import WithdrawOtpDialog from "../merchantprofile/Components/WithdrawOtpDialog";
import { send } from "../../utils/xrpl_functions";

import sync from "../../assets/img/icons/sync.svg";
import reload from "../../assets/img/icons/reload.svg";
import searchIcon from "../../assets/img/icons/searchIcon.svg";
import dropDown from "../../assets/img/icons/dropDown.svg";
import {
  format,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from "date-fns";

import { formatCurrency } from "../../utils/common";

import CustomTooltip from "../../components/CustomTooltip";
import { refresh } from "less";
import { values } from "lodash";
import { handleTransfer } from "../../utils/flare";

const { afterToday } = DateRangePicker;

const reportTimePeriods = [
  {
    name: "Time Period",
    from: "",
    to: "",
  },
  {
    name: "This Week",
    from: format(startOfWeek(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
  {
    name: "This Month",
    from: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
  {
    name: "This Quarter",
    from: format(startOfQuarter(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
  {
    name: "This Year",
    from: format(startOfYear(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
];

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

function ReportTransactions({ userRole, isSingleWallet }) {
  const [statusOptions, setStatusOptions] = React.useState([
    { name: "Select Status", value: "" },
  ]);
  const [typeOption, setTypeOption] = React.useState([
    { name: "All", value: "" },
  ]);
  const [storeOption, setStoreOption] = React.useState([
    { name: "Select Store", id: "" },
  ]);
  const [CoinOption, setCoinOption] = React.useState([
    { name: "Select Coin", id: "" },
  ]);
  const [counterOption, setCounterOption] = React.useState([
    { name: "Select Counter", id: "" },
  ]);
  const [statusTitle, setStatusTitle] = React.useState("Select Status");

  // const [value, setValue] = React.useState(0);
  // const handleChange = (event, newValue, v) => {
  //   setValue(newValue);
  //   setpaginateTable({
  //     ...paginateTable,
  //     currentPage: 0,
  //     type: v,
  //   });
  // };

  const [translist, setTranslist] = useState([]);
  const [modal, setmodal] = useState(false);
  const [loading, setloading] = useState(false);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(true);
  const [passPhraseModal, setPassphraseModal] = useState(false);
  const [coinTitle, setcoinTitle] = useState("Select Coin");
  const [storeTitle, setStoreTitle] = useState("Select Store");
  const [counterTitle, setCounterTitle] = useState("Select Counter");
  const [transactionId, setTransactionId] = useState("");
  const [transactionData, setTransactionData] = useState("");
  const [otpModal, setOtpModal] = useState(false);
  const [passPhraseData, setPassPhraseData] = useState("");
  const [resetData, setResetData] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [userCoinBalances, setUserCoinBalances] = useState([]);
  const [userCurrency, setUserCurrency] = useState({
    symbol: "",
    decimalPlace: 2,
    symbolPrefix: false,
  });
  const [tabValue, setTabValue] = useState("");
  const [receiptData, setReceiptData] = useState([]);
  const [graphTransactionData, setGraphTransactionData] = useState([]);
  const [blobData, setBlobData] = useState();
  const [balanceGraphData, setBalanceGraphData] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [calenderValue, setCalenderValue] = useState([]);
  const [reLoading, setReLoading] = useState({
    transactionGraph: false,
    balance: false,
    revenue: false,
    balanceGraph: false,
  });
  const [otpLoading, setOtpLoading] = useState(false);

  const [reportTimePeriodTitle, setReportTimePeriodTitle] =
    useState("Time Period");


  const {wallet_address} = useSelector(state => state.profileReducer.user)


  const refreshTimestamp = localStorage.getItem("refreshTimestamp");
  let token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  // const handlemodal = () => {
  //   setmodal(!modal);
  // };

  const handleTabChange = (e, value) => {
    setTabValue(value);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      type: value,
    });
  };

  const handleClearFilters = () => {
    setStatusTitle("Status");
    setStoreTitle("Store");
    setcoinTitle("Coin");
    setCounterTitle("Select Counter");
    setpaginateTable({
      currentPage: 0,
      pageSize: 7,
      search: "",
      type: "",
      coin: "",
      store: "",
      status: "",
      fromDate: "",
      toDate: "",
      counter: "",
    });
    setCalenderValue([]);
    setReportTimePeriodTitle("Time Period");
  };

  const handleCallback = (childData) => {
    setTransactionData(childData);
    setmodal(true);
  };

  const handleSendRefund = async (data) => {
    // if (userRole === "administrator") {
    setmodal(false);
    setPassphraseModal(true);
    setBlobData(data);
    // }
    // else {
    //   let transactionApiData = {
    //     amount: data.amount,
    //     coin: data.coin,
    //     type: "refund",
    //     blob: null,
    //     store_id: data.store_id,
    //     transaction: data.transaction,
    //   };
    //   callTransaction(transactionApiData);
    // }
  };

  const callTransaction = async (transactionApiData) => {
    try {
      const response = await axios.post(
        `${API.TRANSACTION_LIST}`,
        transactionApiData,
        config
      );
      setTransactionId(response.data.data.id);
      toast.success(response.data.message);
      setloading(false);
      setmodal(false);
      handleOtpModal();
    } catch (error) {
      setloading(false);
      toast.error(error.response.data.message);
    }
  };

  const resendOTP = async () => {
    try {
      setOtpLoading(true);
      const response = await axios.get(
        `${API.RESEND_REFUND_OTP}${transactionId}`,
        config
      );
      toast.success(response.data.message);
      setOtpLoading(false);
    } catch (error) {
      setOtpLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const handlePassphraseSubmit = async (passphraseKey) => {
    setloading(true);
    setPassPhraseData(passphraseKey);

    handleTransfer(
      wallet_address,
      passphraseKey,
      blobData.to,
      blobData.amount,
      blobData.issuer,
      (response) => {
        const requestData = {
          // amount: blobData.amount,
          sender_amount: blobData.amount,
          receiver_amount: blobData.amount,
          sender_coin: blobData.coin,
          receiver_coin: blobData.coin,
          // coin: blobData.coin,
          type: "refund",
          blob: response,
          store_id: blobData.store_id,
          transaction: blobData.transaction,
        };
        console.log("req_data", requestData);
        callTransaction(requestData);
        setloading(false);
        setPassphraseModal(false);
      },
      (error) => {
        setloading(false);
        toast.error(
          error?.message ?? "Request failed, try again later"
        );
      }
    );

    // let transactionApiData = {
    //   // amount: blobData.amount,
    //   sender_amount: blobData.amount,
    //   receiver_amount: blobData.amount,
    //   sender_coin:blobData.coin,
    //   receiver_coin: blobData.coin,
    //   // coin: blobData.coin,
    //   type: "refund",
    //   blob,
    //   store_id: blobData.store_id,
    //   transaction: blobData.transaction,
    // };

    // callTransaction(transactionApiData);
    // setloading(false);
    //  setPassphraseModal(false);
    // } catch (err) {
    //   console.log(err.message);
    //   toast.error(err.message || "Something went wrong");
    //   // toast.error(err.response.data.message || 'Something went wrong');
    //   setloading(false);
    // }
  };

  const [paginateTable, setpaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: "",
    type: "",
    coin: "",
    store: "",
    status: "",
    fromDate: "",
    toDate: "",
    counter: "",
  });

  const getTrans = async () => {
    setFetchTranslistLoading(true);
    // const query = `?offset=${
    //   paginateTable.currentPage * paginateTable.pageSize
    // }&limit=${paginateTable.pageSize}&search=${paginateTable.search}&type=${
    //   paginateTable.type
    // }&coin=${paginateTable.coin}&store=${paginateTable.store}&status=${
    //   paginateTable.status
    // }&modified_date_before=${paginateTable.toDate}&modified_date_after=${
    //   paginateTable.fromDate
    // }`;
    merchantApi
      .get(
        `${API.TRANSACTION_LIST}`,
        {
          params: {
            offset: paginateTable.currentPage * paginateTable.pageSize,
            limit: paginateTable.pageSize,
            search: paginateTable.search,
            type: paginateTable.type,
            coin: paginateTable.coin,
            store: paginateTable.store,
            status: paginateTable.status,
            modified_date_after: paginateTable.fromDate,
            modified_date_before: paginateTable.toDate,
            counter: paginateTable.counter,
          },
        },
        config
      )
      .then((response) => {
        setFetchTranslistLoading(false);
        if (response.data) {
          setTranslist(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message || "Something went wrong");
        setFetchTranslistLoading(false);
      });
  };

  const getTransType = async () => {
    try {
      const response = await axios.get(`${API.TRANSACTION_TYPE}`, config);
      setTypeOption([...typeOption, ...response.data.data]);
    } catch (error) {}
  };

  const getCoinType = async () => {
    try {
      const response = await axios.get(`${API.COIN}`);
      setCoinOption([...CoinOption, ...response.data.data]);
    } catch (error) {}
  };

  const getCounterType = async () => {
    try {
      const response = await axios.get(`${API.COUNTER}`, config);
      setCounterOption([...counterOption, ...response.data.results]);
    } catch (error) {}
  };

  const getStoreList = async () => {
    try {
      const response = await axios.get(`${API.TRANS_STORE}`, config);
      setStoreOption([...storeOption, ...response.data.data]);
    } catch (error) {}
  };

  const getStatusOption = async () => {
    try {
      const response = await axios.get(`${API.TRANS_STATUS}`, config);
      setStatusOptions([...statusOptions, ...response.data.data]);
    } catch (error) {}
  };

  const getBalance = (coinId = null) => {
    setloading(true);
    setReLoading((prevState) => ({ ...prevState, balance: true }));

    let apiUrl = `${API.GET_BALANCE}`;
    if (coinId) {
      apiUrl += `?coin=${coinId}`;
    }
    merchantApi
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-REFRESH": String(refreshTimestamp),
        },
      })
      .then((response) => {
        if (response && response.data) {
          if (response.data?.data) {
            setUserCoinBalances(response.data.data.balance_in_coins);
            setUserBalance(response.data.data.total_balance);
            setUserCurrency({
              symbol: response.data.data.currency.symbol,
              decimalPlace: response.data.data.currency.decimal_place,
              symbolPrefix: response.data.data.currency.symbol_prefix,
            });
          } else {
            toast.error(response.data.message);
          }
        }
        setReLoading((prevState) => ({ ...prevState, balance: false }));
        setloading(false);
      })
      .catch((error) => {
        setReLoading((prevState) => ({ ...prevState, balance: false }));
        toast.error(
          "There was an error getting your balance information. Please try again!"
        );
      });
  };

  const getRevenue = async () => {
    setReLoading((prevState) => ({ ...prevState, revenue: true }));
    try {
      const response = await axios.get(`${API.GET_REVENUE}/?group_by=day`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-REFRESH": String(refreshTimestamp),
        },
      });
      setReLoading((prevState) => ({ ...prevState, revenue: false }));
      setReceiptData(response.data.data.group_by_result[0].receipt);
    } catch (err) {
      setReLoading((prevState) => ({ ...prevState, revenue: false }));
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  const getBalanceGraphData = async () => {
    setReLoading((prevState) => ({ ...prevState, balanceGraph: true }));
    try {
      const response = await axios.get(API.GET_BALANCE_GRAPH_DATA, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-REFRESH": String(refreshTimestamp),
        },
      });
      setReLoading((prevState) => ({ ...prevState, balanceGraph: false }));
      setBalanceGraphData(response.data.data);
    } catch (err) {
      setReLoading((prevState) => ({ ...prevState, balanceGraph: false }));
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  const getTransactionGraphData = async () => {
    setReLoading((prevState) => ({ ...prevState, transactionGraph: true }));
    try {
      const response = await axios.get(
        `${API.TRANSACTION_GRAPH_DATA}?type=receipt`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-REFRESH": String(refreshTimestamp),
          },
        }
      );
      console.log(response.data.data);
      setGraphTransactionData(response.data.data);
      setReLoading((prevState) => ({ ...prevState, transactionGraph: false }));
    } catch (err) {
      setReLoading((prevState) => ({ ...prevState, transactionGraph: false }));
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    getBalance();
    getTransType();
    getCoinType();
    getCounterType();
    getStoreList();
    getStatusOption();
  }, []);

  useEffect(() => {
    getTransactionGraphData();
    getRevenue();
    getBalanceGraphData();
    getBalance();
  }, [refresh]);

  useEffect(() => {
    getTrans();
  }, [
    paginateTable.currentPage,
    paginateTable.pageSize,
    paginateTable.search,
    paginateTable.type,
    paginateTable.coin,
    paginateTable.store,
    paginateTable.status,
    paginateTable.fromDate,
    paginateTable.toDate,
    paginateTable?.counter,
    resetData,
  ]);

  const handleCoinchange = (e) => {
    let l = CoinOption.filter((a) => a.symbol === e);
    setcoinTitle(l[0].name);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      coin: l[0].id,
    });
  };

  const handleStorechange = (e) => {
    let l = storeOption.filter((a) => a.id === e);
    setStoreTitle(l[0].name);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      store: l[0].id,
    });
  };

  const handleCounterChange = (e) => {
    let l = counterOption.filter((a) => a.id === e);
    setCounterTitle(l[0].name);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      counter: l[0].id,
    });
  };

  const handleStatusChange = (value) => {
    let l = statusOptions.filter((a) => a.value === value);

    setStatusTitle(l[0].name);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      status: value,
    });
  };

  const handleOtpModal = () => {
    setOtpModal(!otpModal);
  };

  const handlePassphraseClose = () => {
    setPassphraseModal(!passPhraseModal);
  };

  const handleSearch = (value) => {
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      search: value,
    });
  };

  const handleFinalSubmit = async (otpData) => {
    const data = {
      otp: otpData.otp,
      passphrase: passPhraseData,
    };

    setloading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${API.VERIFY_OTP}${transactionId}/`,
        data,
        config
      );
      toast.success(response.data.message);
      setOtpModal(!otpModal);
      setResetData(Math.random());
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setloading(false);
  };

  const reportTimePeriodChangeHandler = (from, to, name) => {
    // const periodData = timePeriodOptions.filter(
    //   (option) => option.value === period
    // );
    console.log(from, to);
    // console.log(new Date(from))
    setCalenderValue(
      name === "Time Period" ? [] : [new Date(from), new Date(to)]
    );
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      fromDate: from,
      toDate: to,
    });
    setReportTimePeriodTitle(name);
  };

  const handleDateFilter = (value) => {
    if (value.length > 1) {
      console.log(value);
      setpaginateTable({
        ...paginateTable,
        currentPage: 0,
        fromDate: format(value[0], "yyyy-MM-dd"),
        toDate: format(value[1], "yyyy-MM-dd"),
      });
    } else {
      setpaginateTable({
        ...paginateTable,
        currentPage: 0,
        fromDate: "",
        toDate: "",
      });
    }
  };

  const getChartData = (data) => {
    return data.length > 0
      ? data.map((item) => ({
          name: item.period.split(" ").slice(1).join(" "),
          value: item.value < 0 ? 0 : item.value,
        }))
      : [];
  };

  const receiptChartData = getChartData(receiptData.slice(4));

  const transactionChartData = getChartData(graphTransactionData);

  const balanceChartData = getChartData(balanceGraphData);

  console.log(receiptChartData);

  return (
    <>
      {/* {loading && <Loader backdrop content=" " vertical />} */}
      <div className="page-wrapper stb-page-wrapper stb-ph-8">
        <div className="stb-h-100">
          <div className="reports stb-h-100 bg-white p-xl-4 radi">
            <div className="d-flex flex-col align-items-start gap-4">
              <div className="d-flex space-between w-100">
                <div>
                  <h3 className="m-0 page-title">
                    Overview
                    <span
                      className="sync"
                      onClick={() => {
                        localStorage.setItem(
                          "refreshTimestamp",
                          new Date().getTime()
                        );

                        setRefresh((prevState) => prevState + 1);
                      }}
                    >
                      {" "}
                      <img
                        className={`${
                          reLoading.balance ||
                          reLoading.balanceGraph ||
                          reLoading.revenue ||
                          reLoading.transactionGraph
                            ? "reload"
                            : null
                        }`}
                        src={sync}
                        alt="sync"
                      />
                    </span>
                  </h3>
                  {/* <p>View your transactions</p> */}
                </div>
              </div>
              <div className="balance-cards d-flex align-items-center space-between w-100 gap-4">
                <div className="trans-card light-green">
                  <div className="card-content">
                    <p>Today's Collection</p>
                    <span>
                      {formatCurrency(
                        receiptChartData[receiptChartData.length - 1]?.value ||
                          0,
                        userCurrency.decimalPlace,
                        userCurrency.symbol,
                        userCurrency.symbolPrefix
                      )}
                    </span>
                  </div>
                  <div style={{ height: "50%", width: "100%" }}>
                    {receiptChartData.length > 0 ? (
                      <ResponsiveContainer height="95%" width="100%">
                        <AreaChart
                          data={receiptChartData}
                          margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: -30,
                          }}
                        >
                          <XAxis dataKey="name" tick={false} />
                          <Tooltip
                            content={<CustomTooltip valueLabel="Value" />}
                          />
                          <Area
                            type="monotone"
                            dataKey="value"
                            strokeWidth={4}
                            stroke="#00AB55"
                            fill="#89E1A7"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    ) : null}
                  </div>
                </div>
                <div className="trans-card light-blue">
                  <div className="card-content">
                    <p>Total Balance</p>
                    <span>
                      {formatCurrency(
                        userBalance,
                        userCurrency.decimalPlace,
                        userCurrency.symbol,
                        userCurrency.symbolPrefix
                      )}
                    </span>
                  </div>
                  <div style={{ height: "50%", width: "100%" }}>
                    <ResponsiveContainer height="95%" width="100%">
                      <AreaChart
                        data={balanceChartData}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: -30,
                        }}
                      >
                        <XAxis dataKey="name" tick={false} />
                        <Tooltip
                          content={<CustomTooltip valueLabel="Value" />}
                        />
                        <Area
                          type="monotone"
                          dataKey="value"
                          strokeWidth={4}
                          stroke="#003bbbdc"
                          fill="#2D99FF"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className="trans-card light-yellow">
                  <div className="card-content">
                    <p>Today's Transactions</p>
                    <span>
                      {transactionChartData[transactionChartData.length - 1]
                        ?.value || 0}
                    </span>
                  </div>
                  <div style={{ height: "50%", width: "100%" }}>
                    {transactionChartData.length > 0 ? (
                      <ResponsiveContainer height="95%" width="100%">
                        <AreaChart
                          data={transactionChartData}
                          margin={{
                            top: 4,
                            right: 0,
                            left: 0,
                            bottom: -30,
                          }}
                        >
                          <XAxis dataKey="name" tick={false} />
                          <Tooltip
                            content={<CustomTooltip valueLabel="Count" />}
                          />
                          <Area
                            type="monotone"
                            dataKey="value"
                            strokeWidth={4}
                            stroke="#FFC107"
                            fill="#FFE68F"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="w-100">
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#0b0523",
                    },
                  }}
                  value={tabValue}
                  onChange={handleTabChange}
                >
                  <Tab
                    value=""
                    style={{ color: `${tabValue === "" ? "#0b0523" : ""}` }}
                    label="All"
                  />
                  <Tab
                    value="receipt"
                    style={{
                      color: `${tabValue === "receipt" ? "#0b0523" : ""}`,
                    }}
                    label="Receipt"
                  />
                  <Tab
                    value="withdraw"
                    style={{
                      color: `${tabValue === "withdraw" ? "#0b0523" : ""}`,
                    }}
                    label="Withdraw"
                  />
                  <Tab
                    value="refund"
                    style={{
                      color: `${tabValue === "refund" ? "#0b0523" : ""}`,
                    }}
                    label="Refund"
                  />
                </Tabs>
              </div>
              <div className="w-100 d-flex align-items-start space-between gap-2 p-3 mb-5 filters">
                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {reportTimePeriodTitle || "Select Period"}
                      <img
                        src={dropDown}
                        style={{ marginLeft: "4px" }}
                        alt="drop-down"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {reportTimePeriods.map((period, i) => (
                        <Dropdown.Item
                          as="button"
                          value={period.name}
                          onClick={() => {
                            reportTimePeriodChangeHandler(
                              period.from,
                              period.to,
                              period.name
                            );
                          }}
                          key={i + "res"}
                        >
                          {period.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="filter">
                  <DateRangePicker
                    cleanable
                    placeholder="Filter Date"
                    className="w-100 dateInput"
                    size="lg"
                    style={{ height: "40px" }}
                    onOk={handleDateFilter}
                    value={calenderValue}
                    placement="auto"
                    showOneCalendar={true}
                    ranges={[
                      {
                        label: "clear",
                        value: [1, 1],
                      },
                    ]}
                    onChange={(value) => {
                      if (value[0] === 1 && value[1] === 1) {
                        setCalenderValue([]);
                      } else {
                        setCalenderValue(value);
                      }
                    }}
                    onClean={() => {
                      reportTimePeriodChangeHandler(
                        reportTimePeriods[0].from,
                        reportTimePeriods[0].to,
                        reportTimePeriods[0].name
                      );
                    }}
                  />
                </div>

                <div className="filter">
                  <Form
                    onSubmit={(e) => e.preventDefault()}
                    className="w-100 search"
                  >
                    <Form.Group>
                      <InputGroup>
                        <InputGroup.Text>
                          <img src={searchIcon} alt="search" />
                        </InputGroup.Text>
                        <Form.Control
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                          type="text"
                          placeholder="Search"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form>
                </div>

                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {statusTitle || "Select Status"}
                      <img
                        src={dropDown}
                        style={{ marginLeft: "4px" }}
                        alt="drop-down"
                        className="dropdown-icon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {statusOptions.map((a, i) => (
                        <Dropdown.Item
                          as="button"
                          value={a.value}
                          onClick={() => {
                            handleStatusChange(a.value);
                          }}
                          key={i + "res"}
                        >
                          {a.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                </div>

                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {coinTitle || "Select Coin"}
                      <img
                        src={dropDown}
                        style={{ marginLeft: "4px" }}
                        alt="drop-down"
                        className="dropdown-icon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {CoinOption.map((a, i) => (
                        <Dropdown.Item
                          as="button"
                          value={a.symbol}
                          onClick={() => {
                            handleCoinchange(a.symbol);
                          }}
                          key={i + "res"}
                        >
                          {a.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                </div>

                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {storeTitle || "Select Store"}
                      <img
                        src={dropDown}
                        style={{ marginLeft: "4px" }}
                        alt="drop-down"
                        className="dropdown-icon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {storeOption.map((a, i) => (
                        <Dropdown.Item
                          as="button"
                          value={a.id}
                          onClick={() => {
                            handleStorechange(a.id);
                          }}
                          key={i + "res"}
                        >
                          {a.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                </div>
                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {counterTitle || "Select Counter"}
                      <img
                        src={dropDown}
                        style={{ marginLeft: "4px" }}
                        alt="drop-down"
                        className="dropdown-icon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {counterOption.map((a, i) => (
                        <Dropdown.Item
                          as="button"
                          value={a.id}
                          onClick={() => {
                            handleCounterChange(a.id);
                          }}
                          key={i + "res"}
                        >
                          {a.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                </div>
                <div className="filter" style={{ flexGrow: "0" }}>
                  <Button
                    className=" w-xs-100  text-dark w-100"
                    style={{
                      background: "none",
                      border: "none",
                      width: "100%",
                      boxShadow: "none",
                    }}
                    onClick={handleClearFilters}
                  >
                    Reset Filters
                    <img
                      src={reload}
                      style={{ marginLeft: "7px" }}
                      alt="reset filter"
                    />
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <Col xs={12} xl={12} className="mb-4">
                <Transactiontable
                  translist={translist}
                  fetchTranslistLoading={fetchTranslistLoading}
                  setpaginateTable={setpaginateTable}
                  paginateTable={paginateTable}
                  handleCallback={handleCallback}
                  userRole={userRole}
                  isSingleWallet={isSingleWallet}
                  resetTransactions={setResetData}
                />
              </Col>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <RefundDialog
          transactionData={transactionData}
          loading={loading}
          open={modal}
          onClose={() => {
            setmodal(false);
          }}
          userCurrency={userCurrency}
          handleSendRefund={handleSendRefund}
          error={""}
          title={"Initiate Refund"}
          userRole={userRole}
        />
      )}
      {otpModal && (
        <WithdrawOtpDialog
          otpModal={otpModal}
          onClose={handleOtpModal}
          title="Enter OTP"
          loading={loading}
          otpLoading={otpLoading}
          resendOTP={resendOTP}
          handleFinalSubmit={handleFinalSubmit}
        />
      )}
      {passPhraseModal && (
        <WithdrawPassphraseDialog
          passPhraseModal={passPhraseModal}
          onClose={handlePassphraseClose}
          title="Enter Passphrase"
          handlePassphraseSubmit={handlePassphraseSubmit}
          loading={loading}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ authReducer }) => {
  const { userRole, isSingleWallet } = authReducer;
  return { userRole, isSingleWallet };
};

export default connect(mapStateToProps, null)(ReportTransactions);
