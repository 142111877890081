import React from 'react'
import { Col} from  '@themesberg/react-bootstrap'
import AccordionComponent from "../components/AccordionComponent";

const Faq = () => {
    return (

        <>

        <Col className="faq bg-white mt-2">
        <h4 className="mb-2">Frequently Asked Questions</h4>
        <AccordionComponent
  defaultKey="panel-1"
  data={[
    {
      id: 1,
      eventKey: "panel-1",
      title: "What is the purpose of a FAQ?",
      description: "At Spend The Bits, our mission has always been focused on bringing openness and transparency to the design process. We've always believed that by providing a space where designers can share ongoing work not only empowers them to make better products, it also helps them grow. We're proud to be a part of creating a more open culture and to continue building a product that supports this vision."
    },
    {
      id: 2,
      eventKey: "panel-2",
      title: "What is a FAQ document?",
      description: "At Spend The Bits, our mission has always been focused on bringing openness and transparency to the design process. We've always believed that by providing a space where designers can share ongoing work not only empowers them to make better products, it also helps them grow. We're proud to be a part of creating a more open culture and to continue building a product that supports this vision."
    },
    {
      id: 3,
      eventKey: "panel-3",
      title: "What are the top 10 interview questions?",
      description: "At Spend The Bits, our mission has always been focused on bringing openness and transparency to the design process. We've always believed that by providing a space where designers can share ongoing work not only empowers them to make better products, it also helps them grow. We're proud to be a part of creating a more open culture and to continue building a product that supports this vision."
    }
  ]} />
        </Col>

        </>
        
    )
}

export default Faq
