

import * as API from '../../ApiUrl';
import { SET_PROFILE } from '../actionTypes';
import { merchantApi } from '../api/apiConfig';
import { toast } from "react-toastify";

  const getProfileInfo = (onSuccess, onError) =>  async dispatch => {
    try {
      const response = await merchantApi.get(`${API.MERCHANT_PROFILE}profile/`);
      const { data } = response;
      dispatch({ type: SET_PROFILE, payload: data.data });
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };


export {getProfileInfo };
