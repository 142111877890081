import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs,
  Tab,
  CircularProgress,
  Box,
  AppBar,
  Typography,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';
import { Button, Dropdown } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { merchantApi } from '../redux/api/apiConfig';
import { ADD_MERCHANT_API, RESET_NEW_PASSWORD } from '../ApiUrl';
import MerchantWalletAddress from '../components/model/MerchantWalletAddress';
import WalletTrustLineSet from '../components/model/WalletTrustLineSet';
import axios from 'axios';
import * as API from '../ApiUrl';
import PaymentModal from '../components/PaymentModal';
import Caution from '../assets/img/caution.svg';
import MessageModal from '../components/MessageModal';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const history = useHistory();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
const validationSchema = yup.object({
  old_password: yup
    .string('Enter your old password')
    .matches(
      passwordRegex,
      'Please enter mix of uppercase, lowercase, special character & number'
    )
    .min(8, 'Old Password should be of minimum 8 characters length')
    .required('Old password is required'),
  new_password: yup
    .string('Enter your new password')
    .matches(
      passwordRegex,
      'Please enter mix of uppercase, lowercase, special character & number'
    )
    .min(8, 'New Password should be of minimum 8 characters length')
    .required('New password is required'),

  confirmpassword: yup
    .string('Enter your confirm password')
    .oneOf([yup.ref('new_password')], 'Password does not match')
    .required('Confirm Password is Required'),
});

export const GeneralInfo = ({ userProfileData }) => {
  const [duration, setDuration] = React.useState('');

  const [registration, setRegistrationdate] = React.useState('');
  const [merchantDetail, setMerchantDetail] = useState(null);
  const [error, setError] = useState(null);
  const [value, setValue] = React.useState(
    userProfileData.user_role === 'Cashier' ? 1 : 0
  );
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [isMerchantWalletAddressModel, setisMerchantWalletAddressModel] =
    useState(false);
  const [isWalletTrustlineModel, setisWalletTrustlineModel] = useState(false);
  // const [isFunded, setIsFunded] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [userCurrency, setUserCurrency] = useState({
    symbol: '',
    decimalPlace: 2,
    symbolPrefix: false,
  });

  const history = useHistory();

  let token = localStorage.getItem('token');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  const countryList = JSON.parse(localStorage.getItem('country-list'));

  const country = countryList?.filter(
    (country) => country.id === merchantDetail?.country
  );

  useEffect(() => {
    getMerchantDetail();
    // getWalletDetails();
    getCurrency();
  }, []);

  const getCurrency = async () => {
    const response = await axios.get(API.GET_BALANCE, config);
    setUserCurrency({
      symbol: response.data.data.currency.symbol,
      decimalPlace: response.data.data.currency.decimal_place,
      symbolPrefix: response.data.data.currency.symbol_prefix,
    });
  };

  const getMerchantDetail = () => {
    merchantApi
      .get(ADD_MERCHANT_API)
      .then((response) => {
        setMerchantDetail(response.data);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  // const getWalletDetails = async () => {
  //   try {
  //     const response = await axios.get(API.GET_MERCHENT_WALLET_LIST, config);
  //     const isFunded = response.data.results.filter(
  //       (coin) => coin.coin.symbol === 'XRP'
  //     )[0].is_funded;
  //     setIsFunded(isFunded);
  //   } catch (err) {
  //   }
  // };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSubmit = (data) => {
    setloading(true);
    merchantApi
      .post(RESET_NEW_PASSWORD, data)
      .then((response) => {
        const { data } = response;
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: 'Password Change successfully',
        });
        history.push('/signin');
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
        setError(error.data.data.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirmpassword: '',
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  const setTrustLineClicked = () => {
    history.push('/merchantprofile/wallets');
  };

  const fundWalletClicked = () => {
    togglePayModal();
  };

  const toggleMessageModal = () => {
    setShowMessageModal((prevState) => !prevState);
  };

  const togglePayModal = () => {
    setShowPaymentModal((prevState) => !prevState);
  };

  return (
    <>
      <div className="page-wrapper mt-2">
        <div className="tabing">
          <div className="reports  bg-white p-xl-4 p-0 radi">
            <div>
              <AppBar position="static" className="bg-white text-dark">
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: '#0b0523',
                    },
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs"
                  centered
                >
                  <Tab
                    label="Profile"
                    {...a11yProps(0)}
                    disabled={userProfileData.user_role === 'Cashier'}
                  />
                  <Tab label="Password" {...a11yProps(1)} />
                  <Tab
                    label="Payment"
                    {...a11yProps(2)}
                    disabled={userProfileData.user_role !== 'Administrator'}
                  />
                  <Tab
                    label="Security"
                    {...a11yProps(3)}
                    disabled={userProfileData.user_role !== 'Administrator'}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <h5>Merchant Details</h5>
                {merchantDetail && (
                  <Row className=" mt-4 gap-2 space-between">
                    <Col xl={3}>
                      <h5>Business</h5>
                      <Row className="mt-4">
                        <TextField
                          disabled
                          className="w-100 mb-4"
                          variant="outlined"
                          name="remarks"
                          id="remarks"
                          multiline
                          label="Owner Name"
                          type="text"
                          value={merchantDetail.name}
                        />
                        {/* <div class="form-floating mb-2 ">
                          <input
                            type="text"
                            class="form-control radi light_bg font-fam "
                            value={merchantDetail.name}
                            disabled
                          />
                          <label for="notes">Contact Name</label>
                        </div> */}
                      </Row>

                      <Row>
                        <TextField
                          disabled
                          className="w-100 mb-4"
                          variant="outlined"
                          name="remarks"
                          id="remarks"
                          multiline
                          label="Business"
                          type="text"
                          value={merchantDetail.business}
                        />
                      </Row>
                      <Row>
                        <TextField
                          disabled
                          className="w-100 mb-4"
                          variant="outlined"
                          name="remarks"
                          id="remarks"
                          multiline
                          label="No Of Stores"
                          type="text"
                          value={merchantDetail.no_of_store}
                        />
                      </Row>
                      <Row>
                        <TextField
                          disabled
                          className="w-100 mb-4"
                          variant="outlined"
                          name="remarks"
                          id="remarks"
                          multiline
                          label="Category"
                          type="text"
                          value={
                            merchantDetail.hasOwnProperty('category')
                              ? merchantDetail['category']['name']
                              : ''
                          }
                        />
                      </Row>
                      <Row>
                        <TextField
                          disabled
                          fullWidth
                          type={'date'}
                          variant="outlined"
                          className="bg-white"
                          id="dob"
                          name="dob"
                          label="Registration Date"
                          value={moment(merchantDetail?.created_date).format(
                            'YYYY-MM-DD'
                          )}
                        />
                      </Row>
                    </Col>
                    <Col xl={3}>
                      <h5>KYB</h5>
                      <Row className="mt-4">
                        <TextField
                          disabled
                          className="w-100 mb-4"
                          variant="outlined"
                          name="remarks"
                          id="remarks"
                          multiline
                          label="KYB Status"
                          type="text"
                          value={merchantDetail.kyc_status}
                        />
                      </Row>
                      <Row>
                        <TextField
                          disabled
                          className="w-100 mb-4"
                          variant="outlined"
                          name="remarks"
                          id="remarks"
                          multiline
                          label="Wallet Status"
                          type="text"
                          value={merchantDetail.status_text}
                        />
                        {merchantDetail.status === 'unfunded' ? (
                          <Button
                            className="red-btn  basis-70"
                            onClick={() => {
                              setisMerchantWalletAddressModel(true)
                            }}
                            style={{ marginTop: '10px' }}
                          >
                            Fund your wallet
                          </Button>
                        ) : merchantDetail.status === 'trust_line_not_set' ? (
                          <Button
                            className="red-btn basis-70"
                            onClick={setTrustLineClicked}
                            style={{ marginTop: '10px' }}
                          >
                            Activate wallets
                          </Button>
                        ) : null}
                      </Row>
                    </Col>
                    <Col xl={3}>
                      <h5>Address</h5>
                      <Row className=" mt-4">
                        <TextField
                          disabled
                          className="w-100 mb-4"
                          variant="outlined"
                          name="remarks"
                          id="remarks"
                          multiline
                          label="Country"
                          type="text"
                          value={country[0]?.name || 'Country'}
                        />
                      </Row>
                      <Row>
                        <TextField
                          disabled
                          className="w-100 mb-4"
                          variant="outlined"
                          name="remarks"
                          id="remarks"
                          multiline
                          label="City"
                          type="text"
                          value={merchantDetail.city}
                        />
                      </Row>
                    </Col>
                  </Row>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Row className=" mt-4">
                  <Col xl={12}>
                    <form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col xl={4} sm={4} xs={12}>
                          <div class="form-floating mb-2 ">
                            <TextField
                              fullWidth
                              variant="outlined"
                              className="mt-4 bg-white"
                              id="old_password"
                              name="old_password"
                              label="Old Password"
                              type="password"
                              value={formik.values.old_password}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.old_password &&
                                Boolean(formik.errors.old_password)
                              }
                              helperText={
                                formik.touched.old_password &&
                                formik.errors.old_password
                              }
                            />
                          </div>
                        </Col>
                        <Col xl={4} sm={4} xs={12}>
                          <div class="form-floating mb-2 ">
                            <TextField
                              fullWidth
                              variant="outlined"
                              className="mt-4 bg-white"
                              id="new_password"
                              name="new_password"
                              label="New Password"
                              type="password"
                              value={formik.values.new_password}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.new_password &&
                                Boolean(formik.errors.new_password)
                              }
                              helperText={
                                formik.touched.new_password &&
                                formik.errors.new_password
                              }
                            />
                          </div>
                        </Col>

                        <Col xl={4} sm={4} xs={12}>
                          <div class="form-floating mb-2 ">
                            <TextField
                              fullWidth
                              variant="outlined"
                              className="mt-4 bg-white"
                              id="confirmpassword"
                              name="confirmpassword"
                              label="Confirm Password"
                              type="password"
                              value={formik.values.confirmpassword}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.confirmpassword &&
                                Boolean(formik.errors.confirmpassword)
                              }
                              helperText={
                                formik.touched.confirmpassword &&
                                formik.errors.confirmpassword
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      {error && (
                        <div className=" mb-2  text-danger">
                          <span className="fw-normal">{error}</span>
                        </div>
                      )}
                      <Col xl={12}>
                        <Button
                          disabled={!formik.isValid || loading}
                          appearance="primary"
                          type="submit"
                          className="mt-3 primary_color font-fam font-12 ms-2 pe-4 ps-4"
                        >
                          {loading && <CircularProgress size={14} />}
                          {!loading && 'Update'}
                        </Button>
                      </Col>
                    </form>
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel value={value} index={2}>
                Payment Method
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Col xl={4}>
                  <Card.Body className="d-flex mt-xl-4 mt-0 align-items-start">
                    <Card.Img
                      src={Caution}
                      alt="Caution Sign"
                      className="w-50px me-2"
                    />
                    <Card.Text className="font-fam font-13">
                      We recommend you to turn on 2FA for security reasons, use
                      Google Authenticator app to scan code.
                    </Card.Text>
                  </Card.Body>
                  <Button
                    appearance="primary"
                    className="centerbtn primary_color font-fam"
                  >
                    {' '}
                    Enable 2FA Security
                  </Button>
                </Col>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
      {isWalletTrustlineModel && (
        <WalletTrustLineSet
          open={isWalletTrustlineModel}
          handleClose={() => {
            getMerchantDetail();
            setisWalletTrustlineModel(!isWalletTrustlineModel);
          }}
        />
      )}
      {showPaymentModal && (
        <PaymentModal
          userCurrency={userCurrency}
          open={showPaymentModal}
          togglePayModal={togglePayModal}
          goBack={() => {
            togglePayModal();
            setisMerchantWalletAddressModel(!isMerchantWalletAddressModel);
          }}
        />
      )}
       {isMerchantWalletAddressModel && (
        <MerchantWalletAddress
          userCurrency={userCurrency}
          open={isMerchantWalletAddressModel}
          handleClose={() =>
            setisMerchantWalletAddressModel(!isMerchantWalletAddressModel)
          }
          togglePayModal={togglePayModal}
          toggleMessageModal={toggleMessageModal}
        />
      )}
       {showMessageModal ? (
        <MessageModal open={showMessageModal} onClose={toggleMessageModal} />
      ) : null}
    </>
  );
};
