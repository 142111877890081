import React from 'react';
import { Button,  ButtonToolbar } from 'rsuite';
import { useHistory } from 'react-router-dom';
import {Row, Col, Container,} from '@themesberg/react-bootstrap';
import { IoArrowBackCircleSharp } from "react-icons/io5";


const CustomerDetails = () => {
  const history = useHistory();
    return (
     
        
        <div className="page-wrapper mt-3 font-fam bg-white">
           <Col>
            <Button appearance="subtle" onClick={() => history.goBack()} 
            className="font-fam align-items-center"><IoArrowBackCircleSharp size="2em" /> </Button>
            </Col>
           
           <div className="container-fluid d-flex align-items-center flex-column">
           <h2 className="text-center m-0 font-fam pb-3">Customer Details</h2>
           <Col xl={6} className="pt-3">
        <Row>
           <Col xl={12} >
           <div class="form-floating mb-4">
        <input type="text" class="form-control  h55 font-fam font-100" id="floatingUser"  value="CJ-39002" placeholder="Name " />
        <label for="floatingUser">Customer Id</label>
        </div>
        </Col>
        <Col xl={12} >
        <div class="form-floating mb-4">
        <input type="text" class="form-control  h55 font-fam font-100" id="floatingUser"  value="Nicole Austin" placeholder="Name " />
        <label for="floatingUser">Name</label>
        </div>
        </Col>
        <Col xl={12} >
        <div class="form-floating mb-4 ">
        <input type="email" class="form-control  h55 font-fam font-100" id="floatingUser" value="nichola.aus@email.com" placeholder="Email " />
        <label for="floatingUser">Email</label>
        </div>
        </Col>
        <Col xl={12} >
        <div class="form-floating mb-4">
        <input type="text" class="form-control  h55 font-fam font-100" id="floatingUser" value="718-519-7291" placeholder="Phone " />
        <label for="floatingUser">Phone</label>
        </div>
        </Col>
        <Col xl={12} >
        <div class="form-floating mb-4 ">
        <select className="form-control  h55 font-fam font-fam" style={{paddingTop: '7px'}}>
        <option>Active</option>
        <option>Suspended</option>
        </select>
        </div>
        </Col>
        </Row>
        </Col>
        
       
        <Col xl={6} xs={12} >
        <ButtonToolbar className="d-flex pb-5 justify-content-center">
          {/* <Button appearance="default" className="font-fam me-2 w-50">Cancel</Button> */}
        <Button appearance="primary" className="font-fam primary_color w-50">Update</Button>
          </ButtonToolbar>
          </Col>

       
          

        </div>
        </div>
     
        
    )
}

export default CustomerDetails
