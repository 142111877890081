import React, { useEffect, useState } from 'react';
// import Button from "@mui/material/Button";
import { Button } from 'rsuite';
import { Col, Row } from '@themesberg/react-bootstrap';
// import TextField from "@mui/material/TextField";
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import { PAYSTRING_DOMAIN } from '../../../../ApiUrl';
import * as yup from 'yup';
// import * as API from "../../ApiUrl";
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { COUNTER } from '../../../../ApiUrl';
import { set } from 'lodash';
import { textFieldLabelProps } from '../../../../utils/common';

const validationSchema = yup.object({
  name: yup
    .string('Enter your couter name')
    .min(2, 'Please enter valid counter name')
    .required('counter name is required!'),

  paystring: yup
    .string('Enter your paystring')
    .min(2, 'Please enter valid paystring')
    .required('Counter paystring is required!')
    .matches(
      /^\s*([0-9a-z]*)\s*$/,
      'Paystring input only alphanumeric and small character'
    ),

  remarks: yup.string('Enter your remarks').required('remarks is required'),
});



function AddNewCounter({
  // data,
  open,
  id,
  handleaddCounter,
  onClose: handleClose,
  // loading,
}) {
  const [data, setdata] = useState({});
  const [loading, setloading] = useState(false);
  const [payDomain, setPayDomain] = useState('')

  let token = localStorage.getItem('token');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  const onSubmit = async (values) => {
    setloading(true);
    const data = {
      ...values,
      paystring: values.paystring,
      store: id,
    };

    try {
      const response = await axios.post(`${COUNTER}`, data, config);
      console.log('trans list show ', response);
      toast.success('Counter successfully Added');
      setloading(false);
      handleaddCounter();
    } catch (error) {
      setloading(false);
       toast.error(error.response.data.message || 'Something went wrong');
    }
    console.log(data);
  };

  const formik = useFormik({
    initialValues: data,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

const getPaystringDomain =async () => {
  try {
    const response = await axios.get(PAYSTRING_DOMAIN, config)
    setPayDomain(response.data.data.payid_domain)
  } catch(err) {
    toast.error(err?.response?.data?.message|| 'Something went wrong');
  }
}

useEffect(() => {
  getPaystringDomain();
})


  return (
    <Dialog
      open={open}
      //   fullWidth={"true"}
      maxWidth={'sm'}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          width: '480px',
          height: 'fit-content',
          maxHeight: '80vh',
        },
      }}
    >
      <DialogTitle className=" text-center">
        <p className="dialog-title">Add New POS</p>
      </DialogTitle>
      <hr className="hr" />

      <DialogContent className="flex-col">
        {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
        <form onSubmit={() => {}}>
          <Row>
            <Col xl={12} xs={12}>
              <TextField
                // onchange={(e) => handleChange(e)}
                // autoFocus
                className="w-100 mb-4"
                variant="outlined"
                // value={data.name}
                name="name"
                id="name"
                label="Counter Name"
                InputLabelProps={textFieldLabelProps}
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Col>
            <Col xl={12} xs={12}>
              <TextField
                // onChange={(e) => handleChange(e)}
                className="w-100 mb-4"
                variant="outlined"
                name="paystring"
                id="paystring"
                // placeholder="paystring"
                InputLabelProps={textFieldLabelProps}
                label="Paystring"
                type="text"
                prefix="$spendthebits.com"
                value={formik.values.paystring}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.paystring && Boolean(formik.errors.paystring)
                }
                helperText={`${
                  formik.values.paystring ? formik.values.paystring : ''
                }$${payDomain}`}
              />
            </Col>

            <Col xl={12} xs={12}>
              <TextField
                className="w-100 mb-4"
                variant="outlined"
                multiline={true}
                rows={5}
                // rows={15}
                // rows
                // onChange={(e) => handleChange(e)}
                name="remarks"
                id="remarks"
                margin="dense"
                // value={data.address}
                InputLabelProps={textFieldLabelProps}
                label="Remarks"
                type="text"
                value={formik.values.remarks}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                helperText={formik.touched.remarks && formik.errors.remarks}
              />
            </Col>
          </Row>
        </form>
      </DialogContent>
      <hr className="hr" />
      <DialogActions className="footer-content ">
        <Button
          className="btn white-btn action-button basis-30"
          onClick={handleClose}
          appearance="subtle"
        >
          Cancel
        </Button>
        <Button
          className={`btn action-button primary_color text-white basis-70 ${
            !formik.isValid ? 'disabled' : null
          }`}
          appearance="primary"
          loading={loading}
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || loading}
        >
          {!loading ? 'Add Counter' : 'loading...'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddNewCounter;
