import React, { useState, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";
import { Col, Row } from "@themesberg/react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import {
  getPersonalDocs,
  getPersonalInfo,
  getStatus,
  updatePersonalInfo,
} from "../../../../redux/actions/kybActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";
import { Stack, Typography } from "@mui/material";
import { TextField, MenuItem } from "@material-ui/core";
import {
  numricRegex,
  removeCodeFromPhone,
  textFieldLabelProps,
} from "../../../../utils/common";

import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import ButtonPrimary from "../../../../components/ButtonPrimary";
import ButtonSecondary from "../../../../components/ButtonSecondary";
import { DatePicker } from "@mui/x-date-pickers";
import { format, isBefore, subYears } from "date-fns";
import Iconify from "../../../../components/Iconify";
import { MERCHANT_IDENTOMAT_KYC } from "../../../../ApiUrl";
import { merchantApi } from "../../../../redux/api/apiConfig";

const UpdatePersonalInfo = ({ handleNext, onBackClick }) => {
  const dispatch = useDispatch();

  const [provinces, setProvinces] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errors, setErrors] = useState([]);
  const [phoneCountry, setPhoneCountry] = useState({
    countryCode: "ca",
    dialCode: "1",
  });

  const kybValidation = yup.object({
    first_name: yup
      .string("Enter your first name")
      .required("First name is required"),

    last_name: yup
      .string("Please enter your legal last name")
      .required("Last name is required"),

    email: yup.string("Enter your email").email("Enter a valid email"),

    pep: yup.boolean("Enter your pep").required("PEP is required"),

    dob: yup
      .date("Enter a valid date")
      .max(new Date(), "Date of birth cannot be in the future")
      .required("Date of birth is required")
      .nullable()
      .test("age", "Must be at least 16 years old", function (value) {
        // 'value' is the parsed date object
        const currentDate = new Date();
        const userDOB = new Date(value);

        // Check if the user is at least 16 years old
        return isBefore(userDOB, subYears(currentDate, 16));
      })
      .nullable(),

    phone_number: yup
      .string("Enter your phone number")
      .matches(numricRegex, "Only Number Required")
      .test("len", "Enter Valid Number", (phone) => {
        console.log(
          "xwexwexasx",
          phone,
          phoneCountry,
          removeCodeFromPhone(phone, phoneCountry.dialCode)
        );
        return removeCodeFromPhone(phone, phoneCountry.dialCode)?.length === 10;
      })
      .required("Phone Number is required"),

    passport_number: yup
      .string("Please enter your passport number")
      .required("Passport number is required"),

    // country: yup.object("Select country").required("Country is required"),
    province: yup.string("Select province").required("Province is required"),
    address: yup.string("Enter address").required("Address is required"),
    city: yup.string("Enter city").required("City is required"),
    postal_code: yup
      .string(" Enter postal code")
      .required("Postal code is required"),
  });

  const user = useSelector((state) => state.profileReducer.user);

  useEffect(() => {
    dispatch(
      getPersonalInfo(
        user?.shareholder.id,
        (res) => {
          setPhoneCountry({
            countryCode: res?.country_code,
            dialCode: res?.dial_code,
          });

          if (!_.isEmpty(res?.country)) {
            const selectedCountryProvinces =
              countryList.find(
                (country) =>
                  country?.alpha2code?.toLowerCase() ===
                  res?.country?.alpha2code?.toLowerCase()
              )?.province || [];
            setProvinces(selectedCountryProvinces);
          }
          personalInfoFormik.setValues({
            first_name: res.first_name || "",
            last_name: res.last_name || "",
            email: res.email || "",
            pep: res.pep || false,
            dob: new Date(res.dob) || null,
            phone_number: `${res.dial_code}${res.phone_number}` || "",
            passport_number: res.passport_number || "",
            country: res.country || null,
            province: res.province?.id || "",
            address: res.address || "",
            city: res.city || "",
            postal_code: res.postal_code || "",
          });
        },
        (err) => console.log("xwex23x23x", err)
      )
    );

    console.log("user", personalInfoFormik);

    dispatch(
      getPersonalDocs(
        (res) => {
          console.log("csdcsoop", res);
          setErrors(res?.[0]?.error_suggestion?.error);
        },
        (err) => {
          console.log("pmcopwec", err);
        }
      )
    );
  }, []);

  const onSubmit = () => {
    setIsUpdating(true);
    const data = {
      first_name: personalInfoFormik.values.first_name,
      last_name: personalInfoFormik.values.last_name,
      email: personalInfoFormik.values.email,
      pep: personalInfoFormik.values.pep,
      dob: format(personalInfoFormik.values.dob, "yyyy-MM-dd"),
      phone_number: removeCodeFromPhone(
        personalInfoFormik.values.phone_number,
        phoneCountry.dialCode
      ),
      dial_code: phoneCountry.dialCode,
      country_code: phoneCountry.countryCode,
      passport_number: personalInfoFormik.values.passport_number,
      country: personalInfoFormik.values.country.id,
      province: personalInfoFormik.values.province,
      city: personalInfoFormik.values.city,
      postal_code: personalInfoFormik.values.postal_code,
      address: personalInfoFormik.values.address,
    };
    dispatch(
      updatePersonalInfo(
        user?.shareholder?.id,
        data,
        (res) => {
          setIsUpdating(false);
          console.log(res, res.status === 200, "cwoeniojc");
          if (res.status === 200) {
            toast.success("Personal info updated successfully");
            handleNext();
          }
        },
        (err) => {
          setIsUpdating(false);
          toast.error(
            err?.data?.data?.message || "Update failed, please try again."
          );
        }
      )
    );
  };

  let personalInfoFormik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      pep: false,
      dob: null,
      phone_number: "",
      passport_number: "",
      country: "",
      province: "",
      address: "",
      city: "",
      postal_code: "",
    },
    onSubmit: onSubmit,
    validationSchema: kybValidation,
    isInitialValid: true,
    validateOnMount: true,
    validateOnChange: true,
  });

  const countryList = JSON.parse(localStorage.getItem("country-list")) || [];

  const countryCodes = countryList.reduce((acc, cur) => {
    acc.push(cur.alpha2code);
    return acc;
  }, []);

  console.log("asxaxwex3422", personalInfoFormik);

  // const getSessionKey = () => {
  //   merchantApi
  //     .post(MERCHANT_IDENTOMAT_KYC)
  //     .then((response) => {
  //       // console.log("session key", response);
  //       setSessionKey(response?.data?.data?.session);
  //     })
  //     .catch((error) => {
  //       toast.error("Error fetching identomat session key");
  //     });
  // };

  // useEffect(() => {
  //   getSessionKey();
  // }, []);

  return (
    <Stack sx={{ width: "100%" }}>
      {/* {errors.length > 0 && (
        <Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Iconify
              icon="fluent:error-circle-24-filled"
              color={"red"}
              width={24}
            />
            <Typography variant="h6" color={"error"}>
              Your KYC has failed.
            </Typography>
          </Stack>
          <Stack direction={"column"} ml={4}>
            {errors.map((error, index) => (
              <Typography variant="caption" color={"error"}>{`${
                index + 1
              })  ${error}`}</Typography>
            ))}
          </Stack>
        </Stack>
      )} */}
      <form onSubmit={personalInfoFormik.handleSubmit}>
        <Stack sx={{ gap: 2 }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="first_name"
              name="first_name"
              InputLabelProps={textFieldLabelProps}
              label="First Name*"
              value={personalInfoFormik.values.first_name}
              onChange={personalInfoFormik.handleChange}
              onBlur={personalInfoFormik.handleBlur}
              error={
                personalInfoFormik.touched.first_name &&
                Boolean(personalInfoFormik.errors.first_name)
              }
              helperText={
                personalInfoFormik.touched.first_name &&
                personalInfoFormik.errors.first_name
              }
            />
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="last_name"
              name="last_name"
              InputLabelProps={textFieldLabelProps}
              label="Last Name*"
              value={personalInfoFormik.values.last_name}
              onChange={personalInfoFormik.handleChange}
              onBlur={personalInfoFormik.handleBlur}
              error={
                personalInfoFormik.touched.last_name &&
                Boolean(personalInfoFormik.errors.last_name)
              }
              helperText={
                personalInfoFormik.touched.last_name &&
                personalInfoFormik.errors.last_name
              }
            />
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <TextField
              fullWidth
              disabled
              variant="outlined"
              className="mt-4 bg-white"
              id="email"
              name="email"
              InputLabelProps={textFieldLabelProps}
              label="Email*"
              value={personalInfoFormik.values.email}
              onChange={personalInfoFormik.handleChange}
              onBlur={personalInfoFormik.handleBlur}
              error={
                personalInfoFormik.touched.email &&
                Boolean(personalInfoFormik.errors.email)
              }
              helperText={
                personalInfoFormik.touched.email &&
                personalInfoFormik.errors.email
              }
            />
            <TextField
              select
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="pep"
              name="pep"
              InputLabelProps={textFieldLabelProps}
              label="Politically Exposed Person (PEP)*"
              value={personalInfoFormik.values.pep}
              onChange={personalInfoFormik.handleChange}
              onBlur={personalInfoFormik.handleBlur}
              error={
                personalInfoFormik.touched.pep &&
                Boolean(personalInfoFormik.errors.pep)
              }
              helperText={
                personalInfoFormik.touched.pep && personalInfoFormik.errors.pep
              }
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </TextField>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <DatePicker
              fullWidth
              disableFuture
              format={"dd/MM/yyyy"}
              className="mt-4 bg-white"
              id={"dob"}
              name="dob"
              value={personalInfoFormik.values.dob}
              onChange={(date) => {
                // in date pickers it has to be manually set
                personalInfoFormik.setFieldTouched("dob", true);
                personalInfoFormik.setFieldValue("dob", date);
              }}
              slots={{
                textField: TextField,
              }}
              slotProps={{
                textField: {
                  variant: "outlined",
                  fullWidth: true,
                  error:
                    personalInfoFormik.touched.dob &&
                    Boolean(personalInfoFormik.errors.dob),
                  helperText:
                    personalInfoFormik.touched.dob &&
                    personalInfoFormik.errors.dob,
                },
              }}
            />
            <PhoneNumberInput
              formik={personalInfoFormik}
              label={"Phone Number*"}
              name={"phone_number"}
              setPhoneCountry={setPhoneCountry}
              style={{ marginTop: "24px" }}
              onlyCountries={
                countryList.length > 0
                  ? _.map(countryList, (country) => {
                      return country?.alpha2code?.toLowerCase();
                    })
                  : []
              }
              country={phoneCountry?.countryCode}
            />
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <TextField
              fullWidth
              style={{ flexBasis: "50%" }}
              variant="outlined"
              className="mt-4 bg-white"
              id="passport_number_number"
              name="passport_number"
              InputLabelProps={textFieldLabelProps}
              label="Passport Number*"
              value={personalInfoFormik.values.passport_number}
              onChange={personalInfoFormik.handleChange}
              onBlur={personalInfoFormik.handleBlur}
              error={
                personalInfoFormik.touched.passport_number &&
                Boolean(personalInfoFormik.errors.passport_number)
              }
              helperText={
                personalInfoFormik.touched.passport_number &&
                personalInfoFormik.errors.passport_number
              }
            />
            <Stack sx={{ width: { xs: "100%", md: "50%" } }}>
              <ReactFlagsSelect
                countries={countryCodes}
                className="mt-4 bg-white material-inp"
                style={{ lineHeight: "2rem" }}
                selected={personalInfoFormik?.values?.country?.alpha2code}
                onSelect={(code) => {
                  let country = _.find(countryList, {
                    alpha2code: code,
                  });

                  let e = {
                    target: {
                      name: "country",
                      value: country,
                    },
                  };
                  personalInfoFormik.handleChange(e);
                  const selectedCountryProvinces = countryList.filter(
                    (country) => country.alpha2code === code
                  )[0].province;
                  setProvinces(selectedCountryProvinces);
                }}
              />
            </Stack>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <TextField
              select
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="province"
              name="province"
              InputLabelProps={textFieldLabelProps}
              label="Province*"
              value={personalInfoFormik.values.province}
              onChange={(event) => {
                // console.log(event);

                personalInfoFormik.handleChange(event);
              }}
              onBlur={(event) => {
                personalInfoFormik.handleChange(event);
              }}
              error={
                personalInfoFormik.touched.province &&
                Boolean(personalInfoFormik.errors.province)
              }
              helperText={
                personalInfoFormik.touched.province &&
                personalInfoFormik.errors.province
              }
            >
              {provinces.map((province) => (
                <MenuItem key={province.id} value={province.id}>
                  {province.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="city"
              name="city"
              InputLabelProps={textFieldLabelProps}
              label="City*"
              value={personalInfoFormik.values.city}
              onChange={personalInfoFormik.handleChange}
              onBlur={personalInfoFormik.handleBlur}
              error={
                personalInfoFormik.touched.city &&
                Boolean(personalInfoFormik.errors.city)
              }
              helperText={
                personalInfoFormik.touched.city &&
                personalInfoFormik.errors.city
              }
            />
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="postal_code"
              name="postal_code"
              InputLabelProps={textFieldLabelProps}
              label="Postal Code"
              value={personalInfoFormik.values.postal_code}
              onChange={personalInfoFormik.handleChange}
              onBlur={personalInfoFormik.handleBlur}
              error={
                personalInfoFormik.touched.postal_code &&
                Boolean(personalInfoFormik.errors.postal_code)
              }
              helperText={
                personalInfoFormik.touched.postal_code &&
                personalInfoFormik.errors.postal_code
              }
            />
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="address"
              name="address"
              InputLabelProps={textFieldLabelProps}
              label="Residential Address*"
              value={personalInfoFormik.values.address}
              onChange={personalInfoFormik.handleChange}
              onBlur={personalInfoFormik.handleBlur}
              error={
                personalInfoFormik.touched.address &&
                Boolean(personalInfoFormik.errors.address)
              }
              helperText={
                personalInfoFormik.touched.address &&
                personalInfoFormik.errors.address
              }
            />
          </Stack>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "2rem",
            gap: 1,
          }}
        >
          <ButtonSecondary onClick={onBackClick}>Back</ButtonSecondary>

          <ButtonPrimary type={"submit"} loading={isUpdating}>
            Update and continue
          </ButtonPrimary>
        </Stack>
      </form>
    </Stack>
  );
};

function mapStateToProps({ kybReducer }) {
  const { status } = kybReducer;
  return {
    data: status.data,
  };
}

const actionCreators = {
  getStatus,
};

export default connect(mapStateToProps, actionCreators)(UpdatePersonalInfo);