import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'rsuite';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { setSingleWallet } from '../../redux/actions/auth';

import { merchantApi } from '../../redux/api/apiConfig';
import * as API from '../../ApiUrl';
import { create_account } from '../../utils/xrpl_functions';
import user from '../../assets/img/icons/user.svg';
import store from '../../assets/img/icons/store.svg';
import { toast } from 'react-toastify';

const WalletConfiguration = (props) => {
  const { open, handleClose, selectedValue, setSingleWallet, setSelectedWalletType } = props;
  const [loading, setLoading] = useState(false)

  const handleCallBack = (accountFormXrp) => {
      localStorage.removeItem('accountDetail');
      localStorage.setItem(
        'accountDetail',
        JSON.stringify(accountFormXrp)
      );
      setLoading(false)
  };

  const submitWalletConfig = async (walletType) => {
    setSelectedWalletType(walletType)
    handleCloseModal();
    selectedValue(walletType);
  //  await merchantApi
  //     .patch(API.CONFIG, {
  //       store_wise_wallet: walletType === 'franchisee',
  //     })
  //     .then((response) => {
  //       const { data } = response;
        // if (walletType !== 'franchisee') {
        //   create_account(handleCallBack);         
        // } else {
        //   handleCloseModal();
        //   setFranchiseeLoading(false)
        // }
       
      // })
      // .catch((error) => {
      // setMerchantLoading(false)
      // setFranchiseeLoading(false)
      // });
     
      // Update isSingleWallet in redux
      // await setSingleWallet()
  };

 

  useEffect(() => {
  (async () => {
    try {
      setLoading(true)
      await create_account(handleCallBack);
    }catch(err) {
      toast.error('Account creation failed.')
      setLoading(false)
    }
    })()
  }, []);

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  return (
    
    <Dialog
    open={open}
    disableEscapeKeyDown={true}
    onClose={handleCloseModal}
    // onBackdropClick="false"
    className="wallet-configure"
    BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
    PaperProps={{
      style: {
        boxShadow: 'none',
        borderRadius: '8px',
        width: '560px',
        // minHeight: '731px',
        maxHeight: '95vh',
      },
    }}
  >
    <DialogTitle className="text-center">
      <p className="dialog-title">INITIAL SETUP</p>
    </DialogTitle>

    <hr className="hr" />

    <DialogContent className="merchant-wallet">
      <p className="heading">
        Welcome to Spend The Bits!
        <p>
          Before you start your journey, you need to select the merchant type
          which suits you.
        </p>
      </p>
      <p className="instructions">
        <div className="d-flex space-between mb-4">
          <Button className="primary_color text-white">
            <img src={store} alt="store" />
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            onClick={() => submitWalletConfig('franchisee')}
            className="primary_color text-white fw-bold"
          >
            Per Store Wallet
          </Button>
        </div>
        <div className="instruction flex-row align-start">
          <span className="circle">1</span>
          <span className="inst-content">
            <strong>Option1 : Per Store Wallet:</strong>
            <br />
            In this mode, every store connected to your merchant account will
            have its own seperate, independent blockchain wallet. All payments
            received by that store will be stored in that store wallet. <br />
            Merchant administrator will NOT have access to those funds unless
            explicitly transfered by the store. <br />
            This model is generally suited for: <br />
            <ol>
              <li>
                Franchise Stores where stores maintain their own funds, and
                then transfer franchise fee and commissions separately.
              </li>
            </ol>
          </span>
        </div>
      </p>
      <p className="instructions">
        <div className="d-flex space-between mb-4">
          <Button className="primary_color text-white">
            <img src={user} alt="store" />
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            onClick={() => submitWalletConfig('merchant')}
            className="primary_color text-white fw-bold"
          >
            Single Wallet
          </Button>
        </div>
        <div className="instruction flex-row align-start">
          <span className="circle">2</span>
          <span className="inst-content">
            <strong>Option2 : Single Wallet:</strong>
            <br />
            In this model, merchant has a single wallet to receive user funds,
            irrespective of how many stores you create. All funds lie with the
            merchant account. <br />
            This model is generally suited when merchant wants to controll all
            funds and store has no access to the receive funds.
          </span>
        </div>
        <br />
      </p>
    </DialogContent>
    <hr className="hr" />
    <p className="text-center p-4" style={{ color: 'red' }}>
      Please select the mode of operation to continue. Please not that this is
      a one time selection and cannot be modified later.
    </p>
  </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSingleWallet: () => dispatch(setSingleWallet()),
  };
};

export default connect(null, mapDispatchToProps)(WalletConfiguration);

// export default WalletConfiguration;
